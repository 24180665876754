import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

import { Add, FilterList, Inventory, Print, ViewColumn } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";

import { useHistory } from "react-router";
import SearchTable, {
  SEARCH,
} from "../ShipmentSearch/SearchTable";
import FiltersForm from "../../Hooks/shipmentListFilters";
import useWidth from "../../Hooks/useWidth";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ExportShipments from "./ExportShipments";
import CreateShipmentInDialog from "./CreateShipmentInDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useForm } from "react-hook-form";
import { RECEIVE_SHIPMENTS_IN_WAREHOUSE } from "./Graphql";
import { gql, useMutation } from "@apollo/client";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { LIST_WAREHOUSE_DROPDOWN } from "../../GlobalsQuery/ListDropdown/ListDropdown";
import { useSnackbar } from "notistack";
import { Globals } from "../HOC/Classes/Globals";
import PrintComponent from "./PrintComponent";
import { printLink } from "../../helpers/defaultPrintList";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ShipmentColumView from "../ShipmentSearch/shipmentColumView";
import config from "../../config.json";
import ProductList from "./ProductsList/ProductList";
import { defaultQueryFields, initialData, shipmentFieldsFun } from "../ShipmentSearch/ListShipmentTableCells";

// const PREFIX = "Shipments";

const Root = styled("div")(({ theme }) => ({
}));

//*********Table Function*********
const Shipments = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const { t } = useTranslation();

  const [keys, setKeys] = useState(localStorage.getItem("shipments") ? JSON.parse(localStorage.getItem("shipments")) : initialData.shipments)

  const user = Globals.user;
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );

  const [shipmentViewColum, setOpenShipmentViewColum] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [checkedShipments, setCheckedShipments] = useState([]);

  const [tableLoad, setTableLoad] = useState(false);
  const [filterLoad, setFilterLoad] = useState(true);
  const screenWidth = useWidth();
  const { enqueueSnackbar } = useSnackbar();

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const [queryVariables, setQueryVariables] = useState({
    refetch: false,
    page: -1,
  });
  // const [dateValue, setDateValue] = useState();
  const resetPage = () => setPage(0);

  // const { refetch, ...excelFilters } = queryVariables;
  const { refetch, page: pageNum, ...excelFilters } = queryVariables;

  const history = useHistory();

  const pushUrlSearch = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: "/admin/shipments",
      search: "?" + queryString,
    });
    windowUrl(url);
    //this will not effect on history.location.search
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  //////////Table Function////////////
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };

  const clearCheckBox = () => setCheckedIds([]);

  const [openShipmentDialog, setOpenShipmentDialog] = useState(false);
  const openShipmentDialogHandler = () => {
    setOpenShipmentDialog(true);
  };
  const closeShipmentDialogHandler = () => {
    setOpenShipmentDialog(false);
  };
  const [autocompleteValues, setAutocompleteValues] = useState({
    section: null,
  });
  const { handleSubmit, control, formState, setValue } = useForm();
  const { errors } = formState;

  const [dialogState, setDialogState] = useState(false);

  // const [openPrintDialog, setOpenPrintDialog] = React.useState(false);

  const selectDefaultValue = (data) => {
    return data;
  };

  const [
    receiveShipmentsInWarehouse,
    { loading: receiveShipmentsInWarehouseLoading, client },
  ] = useMutation(
    gql`
      ${RECEIVE_SHIPMENTS_IN_WAREHOUSE.query}
    `
  );

  const handelShipmentInWarehouse = () => {
    setDialogState(true);
  };

  const handleDialogClose = () => {
    setDialogState(false);
    setAutocompleteValues({
      section: null,
    });
    setValue("sectionId", "");
  };

  const afterSaveFun = () => {
    handleDialogClose();
    client.refetchQueries({
      include: [
        gql`
          ${SEARCH(defaultQueryFields(keys, adminNotesPermission)).query}
        `,
      ],
    });
    setCheckedIds([]);
  };

  const [disableInWarehousePrint, setReceiveInWarehousePrint] = useState(true);
  const [prepareProduct, setPrepareProduct] = useState(true);
  const [receiveInWarehouse, setReceiveInWarehouse] = useState(false)
  const [branchId, setBranchId] = useState()
  const shipmentsSelected = checkedShipments.filter((i) =>
    checkedIds.includes(i.id)
  );

  useEffect(() => {
    const canReceive =
      shipmentsSelected.every((e) => ["PKR", "PRP", "PRPD"].includes(e.status.code) && !e.cancelled) &&
      shipmentsSelected.length > 0;
    const canPrepare =
      shipmentsSelected.every((e) => ["PKR", "PRP"].includes(e.status.code) && e.hasProducts && !e.cancelled) &&
      shipmentsSelected.length > 0;
    setReceiveInWarehousePrint(!canReceive);
    setPrepareProduct(!canPrepare);

    setBranchId(shipmentsSelected[0]?.branch?.id)
    const validReceiveInWarehouse = shipmentsSelected.every(
      ({ branch }) => branch.id === shipmentsSelected[0]?.branch?.id
    );
    setReceiveInWarehouse(validReceiveInWarehouse)
  }, [shipmentsSelected]);

  const submitData = (data) => {
    receiveShipmentsInWarehouse({
      variables: {
        input: {
          id: checkedIds,
          ...(data.sectionId && { sectionId: data.sectionId }),
        },
      },
    })
      .then((res) => {
        openPrintDialog("R&P")
        // setOpenPrintDialog(true)
        handleDialogClose()
        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        const shipmentsSelect = checkedShipments.filter((i) =>
          checkedIds.includes(i.id)
        );
        const shipmentsErrorId = Object.entries(
          graphQLErrors[0].extensions.validation
        ).map((shipment) => parseInt(shipment[0].split(".")[2]));
        const shipmentsError = shipmentsSelect.filter((i, index) =>
          shipmentsErrorId.includes(index)
        );
        const Errors = Object.entries(
          graphQLErrors[0].extensions.validation
        ).map((shipment) => shipment[1]);
        const errorMsg = (
          <List
            sx={{
              width: "100%",
              "& ul": { padding: 0 },
            }}
            subheader={<li />}
          >
            {shipmentsError.slice(0, 3).map((shipment, index) => (
              <li
                key={shipment.id}
                style={{
                  marginBottom: index === shipmentsError.length - 1 ? 0 : 16,
                }}
              >
                <ul>
                  <ListItem sx={{ p: 0, fontSize: 15 }}>
                    {`${t("shipment")} ${shipment.code}`}
                  </ListItem>
                  {Errors[index].map((item, index) => (
                    <ListItem sx={{ p: 0, fontSize: 12 }} key={index}>
                      <ListItemAvatar sx={{ minWidth: "20px" }}>
                        -
                      </ListItemAvatar>
                      <ListItemText primary={`${item}`} />
                    </ListItem>
                  ))}
                </ul>
              </li>
            ))}
            {shipmentsError.length > 3 && (
              <ListItem sx={{ p: 0, fontSize: 15 }}>
                {t("moreShipmentErrors")}
              </ListItem>
            )}
          </List>
        );
        setCheckedIds([]);

        handleDialogClose();
        enqueueSnackbar(errorMsg, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };


  const defaultPrint = Globals.settings.waybillCode;

  const [openPrint, setOpenPrint] = useState(false);
  const [printType, setPrintType] = useState('');
  const [openExport, setOpenExport] = useState(false);
  const openPrintDialog = (type) => {
    defaultPrint ?
      printLink(defaultPrint, false, checkedIds, afterSaveFun) :
      setOpenPrint(true)
    setPrintType(type)
  };
  const openExportDialog = () => {
    setOpenExport(true)
  };
  const openColumView = () => {
    setOpenShipmentViewColum(true)
  };


  const searchParams = (param) => {
    console.log(param);
    const queryParams = [];

    for (const i in param) {
      if (i === "date") {
        for (const y in param[i]) {
          queryParams.push(encodeURIComponent(y) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      if (i === "lastTransactionDate") {
        for (const y in param[i]) {
          const lastTransaction = (y === "fromDate" ? "lastTransactionFromDate" : "lastTransactionToDate")
          queryParams.push(encodeURIComponent(lastTransaction) + "=" + encodeURIComponent(param[i][y]))
        }
      }
      i !== "page" && i !== "refetch" && i !== "date" && i !== "lastTransactionDate" && encodeURIComponent(param[i]) &&
      queryParams.push(
        encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
      );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const onPrint = () => {
    const params = searchParams(queryVariables);
    const domain = `${window.location.origin}`;

    window.open(`${domain}/shipment-list/print?listType=shipments&${params}`);
    // window.open(`${domain}/shipment-list/print`);
  };
  const [openProductDialog, setOpenProductDialog] = useState(false)
  const [printListDisabled, setPrintListDisabled] = useState(true)

  const onClosePrepareDialog = () => {
    setOpenProductDialog(false)
    client.refetchQueries({
      include: [
        gql`
          ${SEARCH(defaultQueryFields(keys, adminNotesPermission)).query}
        `,
      ],
    });
    setCheckedIds([]);
  }

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "add",
      title: "createNew",
      action: openShipmentDialogHandler,
      icon: Add,
      permission: "shipping.shipment.create",
    },
    {
      id: "export",
      title: "export",
      action: openExportDialog,
      icon: SimCardDownloadOutlinedIcon,
      disabled: checkedIds.length > 0
    },
    {
      id: "print",
      title: "printWaybill",
      action: () => openPrintDialog("p"),
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: checkedIds.length === 0
    },
    {
      id: "prepare",
      title: "prepare",
      action: () => setOpenProductDialog(true),
      icon: Inventory,
      permission: "shipping.shipment.update_status",
      disabled: prepareProduct
    },
    {
      id: "printList",
      title: "printList",
      action: onPrint,
      icon: Print,
      permission: "shipping.shipment.print",
      disabled: printListDisabled
    },
    {
      id: "receiveAndPrint",
      title: "receiveAndPrint",
      action: handelShipmentInWarehouse,
      permission: "shipping.shipment.receive_in_warehouse",
      icon: Print,
      disabled: disableInWarehousePrint
    },
    {
      id: "ViewColumn",
      title: "viewColums",
      action: openColumView,
      icon: ViewColumn,
    },
  ]

  return (
    <Root>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openProductDialog}
        onClose={() => onClosePrepareDialog()}
      // className={classes.dialog}
      >
        <DialogContent style={{ overflow: "hidden", padding: 0 }}>
          <ProductList
            onClosePrepareDialog={onClosePrepareDialog}
            shipmentId={checkedIds}
            prepareShipment={true}
            type="PRE"
          />
        </DialogContent>
      </Dialog>
      <PrintComponent shipmentsId={checkedIds} openPrint={openPrint} setOpenPrint={setOpenPrint} afterSaveFun={afterSaveFun} printType={printType} />
      <ExportShipments
        filters={excelFilters}
        openExport={openExport}
        setOpenExport={setOpenExport}
      />
      <ShipmentColumView
        openViewColum={shipmentViewColum}
        setOpenViewColum={setOpenShipmentViewColum}
        localStorageName={"shipments"}
        initialData={keys}
        setKeys={setKeys}
        shipmentFields={shipmentFieldsFun(adminNotesPermission)}
      />
      <Dialog
        open={dialogState}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <form onSubmit={handleSubmit(submitData)}>
          <DialogTitle id="alert-dialog-title" color={"text.primary"}>
            {t("receiveShipmentInWarehouse")}
          </DialogTitle>
          <DialogContent>
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"sectionId"}
              skip={!receiveInWarehouse}
              disabled={!receiveInWarehouse}
              label={t("wareHouse")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_WAREHOUSE_DROPDOWN.query}
              defaultValue={autocompleteValues.section}
              variables={{
                input: {
                  branchId: branchId,
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              autoFocus
              disabled={receiveShipmentsInWarehouseLoading}
              type="submit"
            >
              {receiveShipmentsInWarehouseLoading ? (
                <ButtonLoading />
              ) : (
                t("confirmAndPrint")
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CreateShipmentInDialog
        open={openShipmentDialog}
        onClose={closeShipmentDialogHandler}

      />
      {queryVariables && (
        <SearchTable
          {...props}
          onPageChange={handleChangePage}
          onChangeRows={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          queryVariables={queryVariables}
          loading={(param) => setTableLoad(param)}
          setCheckedIds={(ids) => setCheckedIds(ids)}
          setCheckedShipments={(shipments) => setCheckedShipments(shipments)}
          checkedIds={checkedIds}
          queryFields={defaultQueryFields(keys, adminNotesPermission)}
          keys={keys}
          withCheckAll
          setPrintListDisabled={setPrintListDisabled}
          filterLoading={setFilterLoad}
          icons={icons}
          path={props.match.path}
          drawerState={drawerState[filterAnchor]}
          filters={
            <FiltersForm
              {...{ onSubmitFunc, resetPage, rowsPerPage, loading: tableLoad, filterLoad: filterLoad }}
              queryVariables={(variables) => setQueryVariables(variables)}
              pathname="shipments"
              clearCheckBox={clearCheckBox}
              filtersAllowed={[
                "date",
                "dlvAtemp",
                "status",
                "packageType",
                "new",
                "branch",
                "originBranch",
                "customerType",
                "custm",
                "dlvAgent",
                "createdBy",
                "zoneSender",
                "subZoneSender",
                "zone",
                "subZone",
                "refNumber",
                "service",
                "deliveryType",
                "returnType",
                "paymentType",
                "collection",
                "paid",
                "cancelled",
                "hasProducts",
              ]}
            />
          }
        />
      )}
    </Root>
  );
};
export default Shipments;
