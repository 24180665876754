import React, { Fragment, useEffect } from "react";
import { styled } from '@mui/material/styles';
import clsx from "clsx";
import { Paper, Collapse, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { SAVE_BRANCH, BRANCH } from "./Graphql";
import { useMutation, gql, useQuery } from "@apollo/client";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { useState } from "react";
import FormButton from "../CustomComponents/Buttons/FormButton";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import {
  LIST_AREAS_DROPDOWN,
  LIST_CITIES_DROPDOWN,
  LIST_COUNTRIES_DROPDOWN,
  LIST_STATES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import NotFound from "../../Error/NotFound";
import getMobileData, { getFormatNumber, validNumber } from "../../helpers/asYouType";
import MuiPhone from "../HOC/MUI/MUIphoneNumber";
import { Globals } from "../HOC/Classes/Globals";
const PREFIX = 'BranchForm';

const classes = {
  spacing: `${PREFIX}-spacing`,
  mainGrid: `${PREFIX}-mainGrid`,
  main: `${PREFIX}-main`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.spacing}`]: {
    width: "100%",
    margin: theme.spacing(2, 0, 0, 0),
  },

  [`& .${classes.mainGrid}`]: {
    width: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(2),
  }
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));


const BranchForm = (props) => {
  const [autocompleteValues, setAutocompleteValues] = useState({
    country: null,
    state: null,
    city: null,
    area: null,
  });


  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, formState, watch, setValue, setError } =
    useForm();
  const { errors } = formState;
  const branchId = parseInt(props.match.params.id);

  useEffect(() => {
    if (!branchId) {
      setValue("active", true);
    }

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectDefaultValue = (data) => {
    return data;
  };
  const countryCode = Globals.settings.countryCode
  const { data: updateBranch, loading: updateLoading } = useQuery(
    gql`
      ${BRANCH.query}
    `,
    {
      skip: !branchId,
      notifyOnNetworkStatusChange: true,
      variables: { id: branchId },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const branchData = data.branch;
        const branchParams = [
          "id",
          "code",
          "name",
          "address",
          // "phone",
          "fax",
          "main",
        ];
        // number quey conditions
        const numbersObj = [
          {
            name: "phone",
            codeName: "phoneCode",
            value: getMobileData(branchData.phone, countryCode)
          },
        ]
        numbersObj.forEach((i) => {
          if (i.value !== null) {
            setValue(i.codeName, i.value.country.toLowerCase());
            setValue(i.name, i.value.nationalNumber);
          }
        });
        branchParams.forEach((i) => {
          branchData[i] && setValue(i, branchData[i]);
        });
        setValue("active", branchData["active"]);

        setAutocompleteValues({
          country: branchData?.country,
          state: branchData?.state,
          city: branchData?.city,
          area: branchData?.area,
        });
      },
    }
  );

  const [saveBranch, { loading: saveBranchLoading }] = useMutation(
    gql`
      ${SAVE_BRANCH.query}
    `
  );

  const onSubmit = (data) => {

    const checkPhone = validNumber(data.phone, data.phoneCode,"notRequired")
    let names = [
      {
        name: "phone",
        validate: checkPhone.valid,
        message: checkPhone.message,
      },
    ];
    if (!checkPhone.valid) {
      names.map((e) => !e.validate && setError(e.name, { type: 'custom', message: t(e.message) }))
      return
    }

    data.phone =data.phone&&getFormatNumber(data.phone, data.phoneCode)
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (key === "phoneCode") {
        delete data[key];
      }
    }
    saveBranch({
      variables: {
        input: {
          ...data,
        },
      },
    })
      .then((data) => {
        pushUrl(props, `/admin/branches/${data?.data?.saveBranch?.id}`);

        enqueueSnackbar(t("saveSuccessful"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  const body = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        justifyContent="flex-start"
        aligns="center"
        className={clsx(classes.mainGrid)}
        spacing={2}
      >
        <Paper container component={Grid} className={clsx(classes.spacing)}>
          <Grid container justifyContent="end" xs={12}>
            {/* <Typography variant="h6">{t("branch")}</Typography> */}
            <Box>
              <MuiSwitch
                edge="end"
                name="active"
                label={t("active")}
                control={control}
              />
              <MuiSwitch
                edge="end"
                name="main"
                label={t("main")}
                control={control}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"code"}
              label={t("code")}
            />
          </Grid>

          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"name"}
              label={t("name")}
              rules={{ required: t("fieldIsRequired") }}
            />
          </Grid>

          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              name={"countryId"}
              label={t("country")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_COUNTRIES_DROPDOWN.query}
              onChangeValue={(e) => {
                setValue("stateId", "");
                setValue("cityId", "");
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.country}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("countryId")}
              skip={!watch("countryId")}
              name={"stateId"}
              label={t("state")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_STATES_DROPDOWN.query}
              variables={{
                countryId: watch("countryId"),
              }}
              onChangeValue={(e) => {
                setValue("cityId", "");
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.state}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("stateId")}
              skip={!watch("stateId")}
              name={"cityId"}
              label={t("city")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_CITIES_DROPDOWN.query}
              variables={{
                stateId: watch("stateId"),
              }}
              onChangeValue={(e) => {
                setValue("areaId", "");
              }}
              defaultValue={autocompleteValues.city}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <CustomAutocomplete
              control={control}
              errors={errors}
              disabled={!watch("cityId")}
              skip={!watch("cityId")}
              name={"areaId"}
              label={t("area")}
              parseData={(data) => selectDefaultValue(data)}
              query={LIST_AREAS_DROPDOWN.query}
              variables={{
                cityId: watch("cityId"),
              }}
              defaultValue={autocompleteValues.area}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <MuiPhone
              control={control}
              errors={errors}
              name={"phone"}
              selectName={"phoneCode"}
              label={t("phone")}
            />
          </Grid>

          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"address"}
              label={t("address")}
            />
          </Grid>
          <Grid xs={12} sm={4} aligns="flex-start">
            <ControlMUItextField
              control={control}
              errors={errors}
              name={"fax"}
              label={t("fax")}
            />
          </Grid>
        </Paper>
        <Grid container justifyContent="flex-end" className={classes.spacing}>
          <FormButton disabled={saveBranchLoading}>
            {saveBranchLoading ? <ButtonLoading /> : t("save")}
          </FormButton>
        </Grid>
      </Grid>
    </form>
  );
  // let DOM;

  // if (branchId) {
  //   DOM = updateBranch ? body : <FullScreenLoading minHeight="10%" />;
  // } else {
  //   DOM = body;
  // }

  return updateLoading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : !updateBranch && branchId ?
      <NotFound />
      : <Root>
        <>
          <TitleAppBar path={props.match.path} />
          {body}
        </>
      </Root>;
};

export default BranchForm;
