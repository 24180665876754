import React, { memo } from "react";
import { styled } from "@mui/material/styles";
import { Chip } from "@mui/material";
import clsx from "clsx";
import * as colors from "@mui/material/colors";
import { FixedTableCell } from "./FixedTableCell";
import { useTranslation } from "react-i18next";
import { ShipmentStatusCode } from "../../Shipments/ShipmentStatus";
const StyledFixedTableCell = styled(Chip)(({ theme, colorcode }) => ({
  color: theme.palette.getContrastText(colorcode),
  backgroundColor: colorcode + "!important",
}));

export const TableCellColor = memo((props) => {
  const { t } = useTranslation();
  const color = {
    PKR: colors["blueGrey"]["500"],
    PKM: colors["lightBlue"]["500"],
    PKD: colors["blue"]["500"],
    RJCT: colors["red"]["500"],
    RITS: colors["grey"]["500"],
    RTRN: colors["brown"]["500"],
    OTD: colors["teal"]["500"],
    OTR: colors["purple"]["500"],
    DTR: colors["green"]["500"],
    DEX: colors["orange"]["500"],
    HTR: colors["amber"]["500"],
    PKH: colors["amber"]["500"],
    RTS: colors["pink"]["500"],
    BMT: colors["indigo"]["500"],
    BMR: colors["deepPurple"]["500"],
    PRP: colors["grey"]["500"],
    PRPD: colors["cyan"]["500"],
    STD: colors["teal"]["500"],
  };
  const statusCode=props?.shipment?.status?.code ?? props?.shipment?.shipmentStatus?.code;
  const statusName=props?.shipment?.status?.name ?? props?.shipment?.shipmentStatus?.name;
  return (
    <FixedTableCell sx={props.style} className={clsx(props.className)}>
      <StyledFixedTableCell
        colorcode={color.hasOwnProperty(statusCode) ? color[statusCode] : colors["brown"]["500"]}
        size="small"
        label={t(ShipmentStatusCode(
          statusCode,
          props?.shipment?.collected,
          props?.shipment?.paidToCustomer,
          statusName,
          props?.shipment?.inWarehouse,
      ))}
      />
    </FixedTableCell>
  );
});
