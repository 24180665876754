/* eslint-disable no-useless-concat */
import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { Paper, IconButton, Icon } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { gql, useQuery } from "@apollo/client";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import { SAFE_ID } from "./Graphql";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import TreasurersTable from "./TreasurersTable";
import { Globals } from "../HOC/Classes/Globals";
import Grid from "@mui/material/Unstable_Grid2";
import TitleAppBar from "../../Layout/TitleAppBar";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import LongMenu from "../../Layout/MenuAppBar";
import NotFound from "../../Error/NotFound";
const PREFIX = "SafeView";

const classes = {
  paper: `${PREFIX}-paper`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
}));

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const SafeView = (props) => {
  const { t } = useTranslation();
  const safeId = props.match.params.id?.trim();
  const { loading, data } = useQuery(
    gql`
      ${SAFE_ID.query}
    `,
    {
      fetchPolicy: "network-only",
      variables: { id: parseInt(safeId) },
    }
  );
  const user = Globals.user;
  const branchId = data?.safe?.branch?.id;
  const canAccessBranch = branchId ? user.canAccessBranch(branchId) : true;

  const canEdit = user.hasPermission("cash.safe.update")
    ? canAccessBranch
    : false;
  const paymentTypes = data?.safe?.paymentTypes
    ?.map((i) => i.name)
    .toString()
    .replace(/,/g, " , ");

  const icons = [
    {
      id: "edit",
      title: "edit",
      action: () => pushUrl(props, `/admin/safes/${safeId}/edit`),
      icon: Edit,
      permission: canEdit,
    },
  ]

  return loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading >
    : !data?.safe ? (
      <NotFound />
    ) : (
      <StyledGrid
        container
        justifyContent="center"
        className={classes.mainTracking}
        sx={{ margin: "0", width: "100%" }}
      >
        <Fragment>
          <TitleAppBar path={props.match.path}>
            <LongMenu icons={icons} />
          </TitleAppBar>
        </Fragment>

        <Grid
          container
          justifyContent="center"
          sx={{ width: "100%", p: 2, gap: 2 }}
        >
          <Paper container className={classes.paper} component={Grid}>
            <KeyValuePair title={t("code")} value={data?.safe?.code} />
            <KeyValuePair title={t("name")} value={data?.safe?.name} />
            <KeyValuePair
              title={t("active")}
              value={
                data?.safe?.active ? (
                  <Icon className={classes.iconColor}>
                    check_circle_outline
                  </Icon>
                ) : (
                  <Icon color="error">highlight_off</Icon>
                )
              }
            />
            <KeyValuePair
              title={t("branch")}
              value={
                data?.safe?.branch ? (
                  <SpanLink
                    pathname={`/admin/branches/${data?.safe?.branch?.id}`}
                  >
                    {data?.safe?.branch?.name}
                  </SpanLink>
                ) : (
                  ""
                )
              }
            />
            <KeyValuePair
              title={t("glAccount")}
              value={data?.safe?.glAccount?.name}
            />
            <KeyValuePair title={t("paymentTypes")} value={paymentTypes} />
          </Paper>
        </Grid>
        {
          data?.safe?.treasurers && (
            <TreasurersTable treasurers={data.safe?.treasurers} />
          )
        }
      </StyledGrid >
    );
};

export default SafeView;
