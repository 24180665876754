import {
  Checkbox,
  Paper,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { Fragment, useEffect, useMemo } from "react";

import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import PropTypes from "prop-types";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import { getShipmentsSumQuery, getShipmentsTableHeader } from "./ListShipmentTableCells";
import GenerateShipmentCell from "./GenerateShipmentCell";
import { Globals } from "../HOC/Classes/Globals";
import GenerateShipmentSumCell from "./GenerateShipmentSumCell";

const PREFIX = "SearchTable";

const classes = {
  table: `${PREFIX}-table`,
  checkbox: `${PREFIX}-checkbox`,
  shipmentTable_code: `shipmentTable_code`,
  shipmentTable_status: `shipmentTable_status`,
  bodyCheckbox: `bodyCheckbox`,
  shipmentTable_bodyCode: `shipmentTable_bodyCode`,
  shipmentTable_bodyStatus: `shipmentTable_bodyStatus`,
  tableBodyRow: `${PREFIX}-tableBodyRow`,
  message: `${PREFIX}-message`,
  tablePaper: `${PREFIX}-tablePaper`,
  pagination: `${PREFIX}-pagination`,
  tableHead: `${PREFIX}-tableHead`,
  mainTableClass: `${PREFIX}-mainTableClass`,
  tableHeadFixed: `${PREFIX}-tableHeadFixed`,
  update: `${PREFIX}-update`,
  iconColor: `${PREFIX}-iconColor`,
  shipmentTable_description: `${PREFIX}-shipmentTable_description`,
  barcode: `${PREFIX}-barcode`,

};

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  [`& .${classes.table}`]: {
    display: "grid",
    minHeight: "250px",
  },

  [`& .${classes.barcode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    fontSize: 32,
  },
  [`& .${classes.checkbox}`]: {
    minWidth: 60,
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      left: "0",
    },
  },
  [`& .${classes.update}`]: {
    // minWidth: 60,
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      right: "0",
    },
  },

  [`& .${classes.shipmentTable_status}`]: {
    zIndex: "102",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      left: "141px",
    },
  },

  [`& .${classes.shipmentTable_code}`]: {
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: "0",
    [theme.breakpoints.up("sm")]: {
      left: "60px",
    },
  },

  [`& .${classes.bodyCheckbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    minWidth: 60,
    [theme.breakpoints.up("sm")]: {
      left: 0,
    },
  },

  [`& .${classes.shipmentTable_bodyCode}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    [theme.breakpoints.up("sm")]: {
      left: "60px",
    },
  },

  [`& .${classes.shipmentTable_bodyStatus}`]: {
    zIndex: "101",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    [theme.breakpoints.up("sm")]: {
      left: "141px",
    },
  },

  [`& .${classes.tableBodyRow}`]: {
    "&:hover": {
      "& .MuiTableCell-root": {
        backgroundColor: theme.palette.background.hover,
      },
    },
  },

  [`& .${classes.message}`]: {
    display: "inline-block",
    maxWidth: 150,
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },

  [`&.${classes.tablePaper}`]: {
    position: "relative",
    borderRadius: 0,
    width: "100%",
  },

  [`& .${classes.pagination}`]: {
    flexShrink: 0,
  },

  [`& .${classes.tableHead}`]: {
    "& .MuiTableCell-head": { fontWeight: 600 },
    "& th": { background: theme.palette.background.paper },
  },

  [`& .${classes.mainTableClass}`]: {
    "& td": {
      maxWidth: 250,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },

  [`& .${classes.tableHeadFixed}`]: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.shipmentTable_description}`]: {
    [`& div`]: {
      position: "relative",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
  },

}));
export const SUM_SHIPMENT = (dataFields) => {
  return gqlb.query({
    operation: "sumShipments",
    fields: dataFields ?? [
      "price",
      "allDueFees",
      "deliveredAmount",
      "collectedFees",
      "pendingCollectionAmount",
      "collectedAmount",
      "amount",
      "totalAmount",
      "customerDueCredit",
      "customerDue",
      "customerDueDebit",
      "returningDueFees",
    ],
    variables: {
      input: {
        type: "ListShipmentsFilterInput",
      },
    },
  });
};

export const SEARCH = (dataFields) => {
  return gqlb.query({
    operation: "listShipments",
    fields: [
      {
        operation: "data",
        fields: dataFields,
        variables: {},
      },
      {
        operation: "paginatorInfo",
        fields: ["total"],
        variables: {},
      },
    ],
    variables: {
      input: {
        type: "ListShipmentsFilterInput",
      },
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  });
};

const SearchTable = (props) => {
  const {
    brife,
    sum,
    queryVariables,
    onPageChange,
    onChangeRows,
    loading,
    page,
    rowsPerPage,
    tableBody,
    tableHeadCell,
    className,
    notifyOnNetworkStatusChange,
    queryFields,
    withCheckAll,
    setCheckedIds,
    setCheckedShipments,
    updateDeliveryBody,
    checkedIds = [],
    drawerState,
    filters,
    icons,
    height,
    setPrintListDisabled,
    filterLoading,
    path,
    keys,
    notSumShipments,
    customFilters,
    addCustomFilter,
    custmFilterData,
    storageFilter,
  } = props;

  const { refetch, page: pageNum, idleSearch, ...restVariables } = queryVariables;
  const mergedClasses = Object.assign(classes, { ...className });

  for (const key in restVariables) {
    if (restVariables[key] === "" || restVariables[key] === null) {
      delete restVariables[key];
    }
  }
  const input = {
    ...restVariables,
    ...(queryVariables?.toDate && { toDate: queryVariables?.toDate }),
    ...(queryVariables?.inWarehouse !== null &&
      queryVariables?.inWarehouse !== undefined && {
      inWarehouse: queryVariables?.inWarehouse,
    }),
    ...(custmFilterData && custmFilterData)
  };

  const { data, loading: listLoading } = useQuery(
    gql`
      ${SEARCH(queryFields).query}
    `,
    {
      skip: pageNum === -1 || storageFilter,
      partialRefetch: refetch,
      notifyOnNetworkStatusChange: notifyOnNetworkStatusChange ?? true,
      variables: {
        input,
        first: rowsPerPage,
        page: page + 1,
      },

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        const shipments = data.listShipments.data;
        if (shipments.length === 0 && setPrintListDisabled) {
          setPrintListDisabled(true)
        } else {
          setPrintListDisabled && setPrintListDisabled(false)
        }
        setCheckedShipments && setCheckedShipments(shipments);
        // const checkbox = shipments.reduce((previous, current) => {
        //   if (!checkedIds.includes(current.id)) {
        //     previous.push(current.id);
        //   }
        //   return previous;
        // }, []);
        // setCheckedIds((prev) => [...prev, ...checkbox]);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const shipmentsSumQuery = keys && getShipmentsSumQuery(keys)
  const sumShipmentsSkip = keys ? shipmentsSumQuery.queryFields.length === 0 : notSumShipments

  const { data: sumShipments } = useQuery(
    gql`
      ${SUM_SHIPMENT(shipmentsSumQuery?.queryFields).query}
    `,
    {
      skip: brife || !data || sumShipmentsSkip,
      partialRefetch: refetch,
      notifyOnNetworkStatusChange: true,
      variables: {
        input,
      },

      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );
  useEffect(() => {
    !brife && loading(listLoading);
    if (listLoading || pageNum === -1) {
      filterLoading && filterLoading(true)
    } else {
      filterLoading && filterLoading(false)
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brife, listLoading]);

  const listShipments = data?.listShipments?.data;
  const dataEmpty = listShipments?.length === 0;

  /////////////////////////////////// * CheckBox Function * ///////////////////////////////////
  const isChecked = (id) => {
    return checkedIds.includes(id);
  };
  const currentShipmentIds = listShipments?.map((i) => i.id);
  const shipmentsCount = currentShipmentIds?.length;

  const fieldSelected = currentShipmentIds?.filter((i) =>
    checkedIds.includes(i)
  )?.length;

  const indeterminate = () =>
    fieldSelected > 0 && fieldSelected < shipmentsCount;
  const selectAllChecked = () =>
    shipmentsCount > 0 && fieldSelected === shipmentsCount;

  const onCheckAll = (e) => {
    let ids = [];
    if (e.target.checked) {
      const checkedAll = new Set([...checkedIds, ...currentShipmentIds]);
      ids = [...checkedAll];
    } else {
      ids = checkedIds.filter((i) => !currentShipmentIds.includes(i));
    }
    setCheckedIds(ids);
  };
  const toggleCheck = (e, id) => {
    const checked = e.target.checked;
    let updateCheckedIds = [...checkedIds];
    if (checked) {
      updateCheckedIds.push(id);
    } else {
      updateCheckedIds = updateCheckedIds.filter((i) => i !== id);
    }
    setCheckedIds(updateCheckedIds);
  };

  const checkAll = (
    <FixedTableCell className={classes.checkbox}>
      <Checkbox
        edge="start"
        id="check-all-shipment"
        indeterminate={indeterminate()}
        checked={selectAllChecked()}
        inputProps={{ "aria-label": "select all desserts" }}
        tabIndex={-1}
        onChange={(e) => onCheckAll(e)}
        disableRipple
      />
    </FixedTableCell>
  );
  const user = Globals.user;
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );
  /////////////////////////////////// * End CheckBox Function * /////////////////////////////////// 
  const ifCode = keys?.find((e) => e === "code")
  const ifStatus = keys?.find((e) => e === "status")
  const defaultTableBody = tableBody
    ? (shipment, index) => tableBody(shipment, index, isChecked, toggleCheck)
    : (shipment, index) => (
      <TableRow key={index} className={classes.tableBodyRow}>
        <FixedTableCell key={`${index}-che`} className={classes.bodyCheckbox}>
          <Checkbox
            edge="start"
            checked={isChecked(shipment?.id)}
            onChange={(e) => toggleCheck(e, shipment?.id)}
            disableRipple
          />
        </FixedTableCell>
        {keys.map((ele, i) => {
          return <Fragment key={i}>
            <GenerateShipmentCell shipment={shipment} elementKey={ele} classes={classes} ifCode={ifCode} adminNotesPermission={adminNotesPermission} />
          </Fragment>
        })}
        {updateDeliveryBody && updateDeliveryBody(shipment, index)}
      </TableRow>
    );

  const memorizedTableBody = useMemo(
    () => defaultTableBody,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked, toggleCheck]
  );

  const sumRow =
    sum ?
      sum(sumShipments?.sumShipments)
      : (
        <TableRow>
          <FixedTableCell className={classes.bodyCheckbox}> </FixedTableCell>
          {ifCode && <FixedTableCell className={classes.shipmentTable_bodyCode}> </FixedTableCell>}
          {ifStatus && <FixedTableCell className={!ifCode ? classes.shipmentTable_bodyCode : classes.shipmentTable_bodyStatus}> </FixedTableCell>}
          {
            sumShipments?.sumShipments && shipmentsSumQuery.selected.map((i, index) =>
              <Fragment key={index}>
                <GenerateShipmentSumCell
                  shipmentSum={sumShipments?.sumShipments}
                  elementKey={i}
                />
              </Fragment>
            )
          }
        </TableRow >
      );

  return (
    <StyledPaper className={classes.tablePaper}>
      <ListWrapper
        drawerState={drawerState ?? false}
        icons={icons ?? false}
        path={path ?? false}
        empty={dataEmpty || !listShipments}
        loading={listLoading || pageNum === -1}
        height={height}
        customFilters={customFilters}
        addCustomFilter={addCustomFilter}
        filters={
          filters ?? false
        }
        tableHeaders={
          <ShipmentsTable
            stickyHeader
            className={classes.mainTableClass}
            classes={mergedClasses}
            data={data?.listShipments?.data ?? []}
            headCells={tableHeadCell ?? getShipmentsTableHeader(keys, adminNotesPermission, Boolean(updateDeliveryBody))}
            parseBodyCell={memorizedTableBody}
            sumRow={!notSumShipments && shipmentsSumQuery.queryFields.length !== 0 && sumRow}
            checkAll={withCheckAll ? checkAll : null}
            getHeader={true}
          />
        }
        tableBody={
          <ShipmentsTable
            stickyHeader
            className={classes.mainTableClass}
            classes={mergedClasses}
            data={data?.listShipments?.data ?? []}
            parseBodyCell={memorizedTableBody}
            sumRow={!notSumShipments && shipmentsSumQuery.queryFields.length !== 0 && sumRow}
            checkAll={withCheckAll ? checkAll : null}
            getBody={true}
          />

        }
        pagination={
          <MUITablePagination
            classeName={classes.pagination}
            count={data?.listShipments?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.listShipments ? 0 : page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onChangeRows}
            rowsPerPageOptions={brife && !onChangeRows ? [20] : null}
          />
        }
      />
    </StyledPaper>

  );
};
SearchTable.propTypes = {
  skipListShipmentsQuery: PropTypes.bool,
  brife: PropTypes.bool,
  // sum: PropTypes.bool,
  queryVariables: PropTypes.object,
  onPageChange: PropTypes.func,
  onChangeRows: PropTypes.func,
  loading: PropTypes.func,
  setCheckedIds: PropTypes.func,
};

export default SearchTable;
