import { useTranslation } from "react-i18next";
import { Chip, Paper, Typography } from "@mui/material";
import CustomTab from "../../HOC/FunctionComponents/CustomTab";
import { ProductTableForm } from "./formTablesView";


function FormTabsView(props) {
    const {
        classes,
        trackingData,
        inProducts,
        outProducts
    } = props
    const { t } = useTranslation()
    let shipments = (
        <ProductTableForm
            classes={classes}
            trackingData={trackingData}
            productList={outProducts}
        />
    )

    const tapsArray = [
        {
            tabHead:
                <Typography

                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {t("deliveryProduct")}
                    <Chip color="primary" size="small" label={outProducts.length} sx={{ mx: 1 }} />
                </Typography>,
            panel:
                <Paper

                >
                    {shipments}
                </Paper>
            ,
        },
    ];
    if (trackingData?.type?.code === "PTP") {

        let product = (
            <ProductTableForm
                classes={classes}
                trackingData={trackingData}
                productList={inProducts}
                inProduct={true}
            />

        )

        tapsArray.push(
            {
                tabHead:
                    <Typography

                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {t("returnProduct")}
                        <Chip color="primary" size="small" label={inProducts.length} sx={{ mx: 1 }} />
                    </Typography>,
                panel:
                    <Paper

                    >
                        {product}
                    </Paper>
                ,
            },
        );
    }
    return (
        <CustomTab tapDetails={tapsArray} />
    )

}
export default FormTabsView