/* eslint-disable no-useless-concat */
import React, { useState, Fragment, useEffect } from "react";

import { styled } from "@mui/material/styles";

import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Collapse,
  Box,
  Tooltip,
  DialogContent,
  DialogActions,
  Icon,
} from "@mui/material";
import useWidth, { isWidthDown } from "../../Hooks/useWidth";
import { useForm } from "react-hook-form";
import {
  Add,
  BackspaceOutlined,
  Delete,
  Edit,
  Image,
  Print,
  RadioButtonUnchecked,
  VisibilityOff,
  Restore,
  HighlightOff
} from "@mui/icons-material";
import { gql, useQuery, useMutation, useSubscription } from "@apollo/client";
import moment from "moment";
import CustomizedSteppers from "../HOC/FunctionComponents/Stepper";
import { Alert } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { Can, SecuredNavLink } from "../HOC/CustomComponents/Secured";
import { pushUrl } from "../HOC/CustomFunctions/pushUrl";
import { Globals } from "../HOC/Classes/Globals";
import { KeyValuePair } from "../HOC/CustomComponents/KeyValuePair";
import SpanLink from "../HOC/CustomComponents/SpanLink";
import CellLink from "../HOC/CustomComponents/CellLink";
import * as gqlb from "gql-query-builder";
import {
  CANCEL_SHIPMENT,
  DELETE_LAST_SHIPMENT_ACTION,
  DELETE_MESSAGE_MUTATION,
  DELETE_SHIPMENT,
  MESSAGE_CREATED_SUBSCRIPTION,
  SETTINGS,
  SHIPMENT_MESSAGE,
  // SHIPMENT_MESSAGES,
} from "./Graphql";
import { TableCellColor } from "../HOC/CustomComponents/TableCellColor";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import config from "../../config.json";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import UploadFile from "../HOC/MUI/UploadFile";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import Grid from "@mui/material/Unstable_Grid2";
import MutationDialogWithTextField from "../HOC/CustomComponents/MutationDialogWithTextField";
import ShipmentHistory from "./ShipmentHistory";
import { dateFormatLL } from "../../helpers/dateFunctions";
import {
  GetCollectionPermissionSlug,
  GetPermissionSlug,
  GetManifestPermissionSlug,
  GetPaymentPermissionSlug,
} from "../../helpers/getPermissionSlug";
import PrintComponent from "./PrintComponent";
import formatMoney from "../../helpers/numbersDot";
import LongMenu from "../../Layout/MenuAppBar";
import TitleAppBar from "../../Layout/TitleAppBar";
import { printLink } from "../../helpers/defaultPrintList";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import MutationWithDialog from "../HOC/CustomComponents/MutationWithDialog";
import { ShipmentStatusCode } from "./ShipmentStatus";
import FormTabsView from "./ListProduct/FormTapsView"
import { SHIPMENT_CAllS, SHIPMENT_DESCRIPTION, SHIPMENT_HISTORY, SHIPMENT_TRANSACTIONS, SHIPMENT_MESSAGES, commonShipmentFields } from "./GraphqlView";
import CustomTabShipment from "../HOC/FunctionComponents/CustomTabShipment";

const PREFIX = "ShipmentsView";

const classes = {
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  tabpanel: `${PREFIX}-tabpanel`,
  alert: `${PREFIX}-alert`,
  alertDisabled: `${PREFIX}-alertDisabled`,
  input: `${PREFIX}-input`,
  iconButton: `${PREFIX}-iconButton`,
  searchBar: `${PREFIX}-searchBar`,
  toolbar: `${PREFIX}-toolbar`,
  codeSection: `${PREFIX}-codeSection`,
  codeTitle: `${PREFIX}-codeTitle`,
  toolbarIcons: `${PREFIX}-toolbarIcons`,
  alertHover: `${PREFIX}-alertHover`,
  link: `${PREFIX}-link`,
  delivered: `${PREFIX}-delivered`,
  returned: `${PREFIX}-returned`,
  iconColor: `${PREFIX}-iconColor`,
  main: `${PREFIX}-main`,
  cellHidden: `${PREFIX}-cellHidden`,
  loading: `${PREFIX}-loading`,
};

const StyledLoading = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (40px + 56px))",
    },
  },
}));

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: 0,
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    width: "100%",
    margin: 0,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
  },

  [`& .${classes.tabpanel}`]: {
    padding: theme.spacing(2, 0),
  },
  [`& .${classes.loading}`]: {
    paddingTop: theme.spacing(8),
  },

  [`& .${classes.alert}`]: {
    color: "rgb(94 183 97)",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "64px",
    boxShadow: theme.shadows[1],
    border: "none",
  },

  [`& .${classes.alertDisabled}`]: {
    color: "rgb(181 181 181)",
  },

  [`& .${classes.input}`]: {
    margin: theme.spacing(0, 2, 0, 2),
    flex: 1,
  },

  [`& .${classes.iconButton}`]: {
    borderRadius: "0 4px 4px 0 ",
    boxShadow: "none",
    height: 45,
  },

  [`& .${classes.searchBar}`]: {
    width: 260,
    [theme.breakpoints.down("sm")]: {
      width: 170,
    },
  },

  [`& .${classes.toolbar}`]: {
    borderBottom: "1px #ccd1d6 solid",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },

  [`& .${classes.codeSection}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    margin: theme.spacing(1),
  },

  [`& .${classes.codeTitle}`]: {
    float: "left",
    marginRight: theme.spacing(1),
  },

  [`& .${classes.toolbarIcons}`]: {
    flex: "1 1 100%",
    textAlign: "end",
  },

  [`& .${classes.alertHover}`]: {
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  [`& .${classes.link}`]: {
    textDecoration: "none",
    backgroundColor: theme.palette.action.hover,
  },
  [`& .${classes.delivered}`]: {
    color: "rgb(94 183 97)",
  },
  [`& .${classes.returned}`]: {
    color: "#f44336",
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },
  [`& .${classes.cellHidden}`]: {
    background: theme.palette.mode === "dark" ? "#651e1c !important" : "#ffebee !important",
  },
}));

//*********Table Function*********

export const convertArNumToEn = (str) => {
  if (str !== null && str !== undefined) {
    const english = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const arabic = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];

    for (let i = 0; i < english.length; i++) {
      while (str.includes(arabic[i])) str = str.replace(arabic[i], english[i]);
    }
  }
  return str;
};

const ADMIN_NOTES = gqlb.mutation({
  operation: "updateShipmentAdminNotes",
  fields: ["adminNotes"],
  variables: {
    input: {
      type: "UpdateShipmentAdminNotesInput",
      required: true,
    },
  },
});

const ShipmentsView = (props) => {
  const user = Globals.user;
  const warehousing = Globals.user.warehousing;
  const defaultPrint = Globals.settings.waybillCode;
  const collectionValidCUSTM = user?.hasPermission(
    GetCollectionPermissionSlug("custm", "list")
  );
  const collectionValidDLVBY = user?.hasPermission(
    GetCollectionPermissionSlug("dlvby", "list")
  );
  const invoiceValid = user?.hasPermission("shipping.invoice.list");
  const paymentValidCUSTM = user?.hasPermission(
    GetPaymentPermissionSlug("custm", "list")
  );
  const paymentValidDLVBY = user?.hasPermission(
    GetPaymentPermissionSlug("dlvby", "list")
  );
  // const canViewManifest = user?.hasPermission("shipping.manifest.list");
  const warehousePermission = user?.hasPermission(
    "shipping.shipment.list_in_warehouse"
  );
  const adminNotesPermission = user.hasPermission(
    "shipping.shipment.view_admin_note"
  );
  const hasDeliveryCalls = user?.hasPermission("shipping.delivery_call.list");
  const historyPermission = user.hasPermission(
    "shipping.shipment.view_history"
  );
  const permission = {
    adminNotesPermission: adminNotesPermission,
    historyPermission: historyPermission,
    warehousePermission: warehousePermission,
    hasDeliveryCalls: hasDeliveryCalls,
  }
  const TRACKING = gqlb.query({
    operation: "shipment",
    fields: [
      // ...commonShipmentFields(permission),
      // "recipientLatitude",
      // "recipientLongitude",
      "id",
      "code",
      "cancelled",
      "cancellable",
      "paidToCustomer",
      "paidToDeliveryAgent",
      "inWarehouse",
      "collected",
      "deletable",
      "editable",
      "hasProducts",
      "unpacked",
      {
        customer: [
          "id",
          "name",
          "code",
        ],
      },
      {
        originBranch: ["id", "name"],
      },
      {
        status: ["code", "name"],
      },
      {
        type: ["code", "name"],
      },
      {
        paymentType: ["code", "name"],
      },
      {
        deliveryType: ["code", "name"],
      },
      {
        operation: `invoice @include(if: ${invoiceValid})`,
        fields: ["id", "code"],
        variables: {},
      },

      {
        operation: `paymentCUSTM:payment(typeCode: CUSTM) @include(if: ${paymentValidCUSTM})`,
        fields: [
          "id",
          "code",
          "approved",
          {
            type: ["code", "name"],
          },
        ],
        variables: {},
      },
      {
        operation: `paymentDLVBY:payment(typeCode: DLVBY) @include(if: ${paymentValidDLVBY})`,
        fields: [
          "id",
          "code",
          "approved",
          {
            type: ["code", "name"],
          },
        ],
        variables: {},
      },

      {
        operation: `collectionCUSTM:collection(typeCode: CUSTM) @include(if: ${collectionValidCUSTM})`,

        fields: [
          "id",
          "code",
          "approved",
          {
            type: ["code", "name"],
          },
        ],
        variables: {},
      },
      {
        operation: `collectionDLVBY:collection(typeCode: DLVBY) @include(if: ${collectionValidDLVBY})`,

        fields: [
          "id",
          "code",
          "approved",
          {
            type: ["code", "name"],
          },
        ],
        variables: {},
      },
      {
        shipmentProducts: [
          "price",
        ],
      },
    ],

    variables: {
      id: {
        type: "Int",
      },
      code: {
        type: "String",
      },
    },
  });

  const urlCode = props.match.params.code?.trim();
  const urlId = props.match.params.id?.trim();
  const [tracking, setTracking] = useState({ refetch: false });
  const [loadingPage, setLoadingPage] = useState(true);
  const [TabsData, setTabsData] = useState({
    refetch: false
  })
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    setMessages(TabsData.messages?.shipment?.messages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TabsData?.messages]);

  const [descriptionData, setDescriptionData] = useState();
  useEffect(() => {
    setDescriptionData(TabsData.description?.shipment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TabsData?.description]);

  const [dialogDetails, setDialogDetails] = useState({
    state: false,
    function: null,
    confirmAction: true,
    title: "",
    content: "",
  });

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTracking((prev) => ({
      ...prev,
      ...(urlCode && { trackingCode: urlCode }),
      ...(urlId && { trackingId: urlId }),
      refetch: !prev.refetch,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const screenWidth = useWidth();

  const isWidthXs = isWidthDown("xs", screenWidth);

  const { handleSubmit, formState, control, setValue, watch } = useForm({
    defaultValues: {
      body: "",
      image: "",
    },
  });
  const { errors } = formState;

  const { data: settings } = useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
    }
  );
  const currencyCode = settings?.shippingSettings?.localCurrency?.name ?? "";
  const [cancelShipmentStatus, setCancelShipmentStatus] = useState(false)
  const [cancellable, setCancellable] = useState(true)
  const [tabsLoading, setTabsLoading] = useState(true)
  const { data, loading, refetch } = useQuery(
    gql`
      ${TRACKING.query}
    `,
    {
      variables: {
        ...(tracking.trackingCode && {
          code: convertArNumToEn(tracking.trackingCode),
        }),
        ...(tracking.trackingId && { id: parseInt(tracking.trackingId) }),
      },
      skip: Boolean(!tracking.trackingCode && !tracking.trackingId),
      // notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setLoadingPage(false)
        setCancelShipmentStatus(data.shipment.cancelled)
        setCancellable(data.shipment.cancellable)
      },
    }
  );
  const trackingData = data?.shipment !== null ? data?.shipment : null;
  const productList = descriptionData?.shipmentProducts;
  const shipmentId = trackingData?.id;
  const inProducts = productList && productList.filter(product => product.type === "IN");
  const outProducts = productList && productList.filter(product => product.type === "OUT");
  const [deleteLastShipmentActionMutation, { loading: loadDeleteLastAction }] =
    useMutation(
      gql`
        ${DELETE_LAST_SHIPMENT_ACTION.query}
      `,
      {
        variables: { id: shipmentId },

        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          refetch();
          setTabsData((prev) => ({
            ...prev,
            refetch: !prev.refetch,
          }));
          enqueueSnackbar(t("successfullyDeleteLastAction"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          closeConfirmationDialog();
        },
        onError: (error) => {
          console.log(error);
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
          closeConfirmationDialog();
        },
      }
    );

  const [deleteShipmentMutation, { loading: loadDelete }] = useMutation(
    gql`
      ${DELETE_SHIPMENT.query}
    `,
    {
      variables: { id: shipmentId },

      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        enqueueSnackbar(t("shipmentDeleted"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        closeConfirmationDialog();
        if (props?.location?.state?.prevUrl) {
          pushUrl(props, props.history.location.state.prevUrl);
        } else {
          pushUrl(props, "/admin/shipments");
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [cancelShipmentMutation, { loading: cancelShipmentLoading }] = useMutation(
    gql`
      ${CANCEL_SHIPMENT.query}
    `,
    {
      variables: {
        input: {
          id: shipmentId,
          cancel: !cancelShipmentStatus
        }
      },
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setCancelShipmentStatus(data.cancelShipments[0].cancelled)
        enqueueSnackbar(t("compeleteOperationSuccessfully"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        refetch()
        setTabsData((prev) => ({
          ...prev,
          refetch: !prev.refetch,
        }));
        closeConfirmationDialog();
        // if (props?.location?.state?.prevUrl) {
        //   pushUrl(props, props.history.location.state.prevUrl);
        // } else {
        //   pushUrl(props, "/admin/shipments");
        // }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const [createShipmentMessageMutation, { loading: loadMessage }] = useMutation(
    gql`
      ${SHIPMENT_MESSAGE.query}
    `,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        closeConfirmationDialog();
        // refetch();
        setTabsData((prev) => ({
          ...prev,
          refetch: !prev.refetch,
        }));
        setValue("image", "");
        setValue("body", "");
        setValue("fileName", "");
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const branchId = trackingData?.originBranch?.id;
  const canAccessBranch = user?.account ? true : user.canAccessBranch(branchId);

  const closeConfirmationDialog = () => {
    setDialogDetails((prev) => ({
      ...prev,
      state: false,
    }));
  };

  const openDeleteLastShipmentActionDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("deleteLastAction"),
      content: t("deleteLastActionMessage"),
      confirmAction: true,
      function: deleteLastShipmentActionMutation,
    }));
  };

  const openDeleteDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("delete"),
      content: t("deleteShipmentConfirmation"),
      confirmAction: true,
      function: deleteShipmentMutation,
    }));
  };
  const openCancelDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: "",
      content: t("confirmationMessage"),
      confirmAction: true,
      function: cancelShipmentMutation,
    }));
  };

  const [openPrint, setOpenPrint] = useState(false);
  const openPrintDialog = () => {
    defaultPrint ?
      printLink(defaultPrint, false, shipmentId) :
      setOpenPrint(true)
  };

  const onSubmitMessage = (data) => {
    const image = data?.image?.[0];
    createShipmentMessageMutation({
      variables: {
        input: {
          shipmentId,
          ...(data?.body && { body: data.body }),
          ...(image && {
            images: [{
              file: image
            }
            ]
          }),
        },
      },
    }).catch((error) => console.log(error));
  };
  const createMessageCondition =
    user.hasPermission("shipping.shipment_message.create") && canAccessBranch;
  const hideMessageCondition =
    user.hasPermission("shipping.shipment_message.hide") && canAccessBranch;

  const messageForm = (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmitMessage)}>
        <DialogContent>
          <ControlMUItextField
            control={control}
            errors={errors}
            name={"body"}
            label={t("message")}
            margin="normal"
            rules={{
              validate: {
                require: (value) =>
                  value || watch("image") ? true : t("fieldIsRequired"),
              },
            }}
          />
          <UploadFile
            control={control}
            setValue={setValue}
            name="image"
            icon={"add_photo_alternate"}
            label={t("uploadImage")}
            accept=".png,.jpg"
            rules={{
              validate: {
                require: (value) =>
                  value || watch("body") ? true : t("fieldIsRequired"),
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeConfirmationDialog}>
            {t("cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={loadMessage}>
            {loadMessage ? <ButtonLoading /> : t("confirm")}
          </Button>
        </DialogActions>
      </form>
    </Fragment>
  );
  const openMessageDialog = () => {
    setDialogDetails((prev) => ({
      state: true,
      title: t("newMessage"),
      content: null,
      confirmAction: true,
      function: null,
    }));
  };

  let getBackendUri = (imgPath) => {
    const domain = config.backend.domain
      ? config.backend.domain
      : window.location.hostname;

    return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  };

  const openSignatureDialog = (imgPath) => {
    setDialogDetails((prev) => ({
      state: true,
      title: null,
      content: (
        <Box sx={{ width: "100%" }} component="img" alt="signature" src={getBackendUri(imgPath.path)} />
      ),
      confirmAction: false,
      function: () => { },
    }));
  };

  const validCustomer =
    user.account === null || trackingData?.customer?.id === user?.account?.id;

  const canDelete = trackingData?.deletable;
  const canCancel = user.hasPermission("shipping.shipment.cancel") && !["DTR", "RTS", "OTR", "RTRN"].includes(trackingData?.status?.code);

  const canDeleteLastAction =
    user.hasPermission("shipping.shipment.delete_last_action") &&
    trackingData?.status?.code !== "PKR" &&
    canAccessBranch &&
    !(trackingData?.hasProducts && trackingData?.unpacked);
  // and no have products or unpacked

  const collection =
    trackingData?.collectionDLVBY ?? trackingData?.collectionCUSTM;
  const creditPayment = trackingData?.paymentType.code === "CRDT";
  const customerPayments = trackingData?.paymentCUSTM;
  const invoice = trackingData?.invoice;
  const paidToCustomerType = Boolean(creditPayment)
    ? invoice
    : customerPayments;
  const hasPaymentsToCustomer = Boolean(paidToCustomerType);
  const paidToCustomer = {
    active: trackingData?.paidToCustomer,
    permission: creditPayment
      ? user.hasPermission("shipping.invoice.list")
      : Boolean(customerPayments),
    code: paidToCustomerType?.code,
    link:
      hasPaymentsToCustomer &&
      `/admin/${creditPayment ? "invoices" : "payments"}/${paidToCustomerType?.id
      }`,
  };

  const paidToDeliveryAgent = trackingData?.paymentDLVBY;

  const updateAdminNotes = user.canAccessBranch(branchId) && user.hasPermission("shipping.shipment.update_admin_note")

  let trackingBody = null;

  // const invalidId = (
  //   <Grid container justifyContent="center" className={classes.track}>
  //     <EmptyTableMessage loading={loading} message={t("noShipmentWithCode")} />
  //   </Grid>
  // );
  const description = (
    <>
      {tabsLoading ?
        <Grid container
          sx={{ m: 0 }}
          className={clsx(classes.table, classes.tabpanel)} >
          <Paper>
            <FullScreenLoading minHeight={"100px"} />
          </Paper></Grid> : (descriptionData ? (
            <Grid spacing={2} container className={classes.tabpanel}>
              <Grid xs={12} className={classes.track}>
                <Paper container className={classes.paper} component={Grid}>
                  <KeyValuePair
                    title={t("date")}
                    value={dateFormatLL(descriptionData?.date)}
                  />

                  <KeyValuePair
                    title={t("originBranch")}
                    value={
                      descriptionData?.originBranch?.name ? (
                        <SpanLink
                          pathname={`/admin/branches/${descriptionData?.originBranch?.id}`}
                        >
                          {descriptionData?.originBranch?.name}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />
                  <KeyValuePair
                    title={t("currentBranch")}
                    value={
                      descriptionData?.branch?.name ? (
                        <SpanLink
                          pathname={`/admin/branches/${descriptionData?.branch?.id}`}
                        >
                          {descriptionData?.branch?.name}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />
                  <KeyValuePair
                    title={t("packageWeight")}
                    value={descriptionData?.weight ?? "0"}
                  />
                  <KeyValuePair
                    title={t("pieceCount")}
                    value={descriptionData?.piecesCount ?? "0"}
                  />
                  <KeyValuePair
                    title={t("packageType")}
                    value={descriptionData?.type.name}
                  />

                  <KeyValuePair
                    title={t("service")}
                    value={
                      descriptionData?.service ? (
                        <SpanLink
                          pathname={`/admin/shipping-services/${descriptionData?.service?.id}`}
                        >
                          {descriptionData?.service?.name}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />
                  <KeyValuePair
                    title={t("pickup")}
                    value={
                      descriptionData?.pickup ? (
                        <SpanLink
                          pathname={`/admin/pickups/${descriptionData?.pickup?.id}`}
                        >
                          {descriptionData?.pickup?.code}
                        </SpanLink>
                      ) : (
                        "--"
                      )
                    }
                  />
                  <KeyValuePair
                    valuesx={{ whiteSpace: "pre-line" }}
                    title={t("notes")}
                    value={descriptionData?.notes ?? "--"}
                  />
                  <MutationDialogWithTextField
                    id={shipmentId}
                    title={t("adminNotes")}
                    value={descriptionData?.adminNotes}
                    mutaion={ADMIN_NOTES.query}
                    dialogTitle="adminNotes"
                    onCompleteMessage="saveSuccessful"
                    fieldName="adminNotes"
                    fieldLabel="adminNotes"
                    viewPermission="shipping.shipment.view_admin_note"
                    updatePermission={updateAdminNotes}
                    onCompleted={() => {
                      refetch()
                      setTabsData((prev) => ({
                        ...prev,
                        refetch: !prev.refetch,
                      }))
                    }
                    }
                  />
                  <KeyValuePair
                    title={t("packageDescription")}
                    value={descriptionData?.description}
                  />
                  <KeyValuePair
                    title={t("packageOpen")}
                    value={descriptionData?.openable?.name}
                  />
                  <KeyValuePair
                    title={t("refNumber")}
                    value={descriptionData?.refNumber ?? "--"}
                  />
                  <Can permission="shipping.shipment.list_in_warehouse" showException>
                    {descriptionData?.inWarehouse && (
                      <KeyValuePair
                        title={t("wareHouse")}
                        value={
                          descriptionData?.warehouseSection?.name ? (
                            <SpanLink
                              pathname={`/admin/warehouse/${descriptionData?.warehouseSection?.id}`}
                            >
                              {descriptionData?.warehouseSection?.name}
                            </SpanLink>
                          ) : (
                            "--"
                          )
                        }
                      />
                    )}
                  </Can>
                  {descriptionData?.returnPiecesCount ? (
                    <KeyValuePair
                      title={t("returnPiecesCount")}
                      value={descriptionData?.returnPiecesCount ?? "--"}
                    />
                  ) : null}
                  {descriptionData?.deliveryDate ? (
                    <KeyValuePair
                      title={t("redeliveryDate")}
                      value={descriptionData?.deliveryDate}
                    />
                  ) : null}
                  {descriptionData?.images.length > 0 && descriptionData?.images.find(x => x.subjectCode === "SIGNATURE") ? (
                    <KeyValuePair
                      title={t("signature")}
                      value={
                        <IconButton
                          size="small"
                          onClick={() => openSignatureDialog(descriptionData?.images[descriptionData?.images.findIndex(x => x.subjectCode === "SIGNATURE")])}
                        >
                          <Image fontSize="small" />
                        </IconButton>
                      }
                    />
                  ) : null}
                  {descriptionData?.images.length > 0 && descriptionData?.images.find(x => x.subjectCode === "DELIVERY_PHOTO") ? (

                    <KeyValuePair
                      title={t("deliveryPhoto")}
                      value={
                        <IconButton
                          size="small"
                          onClick={() => openSignatureDialog(descriptionData?.images[descriptionData?.images.findIndex(x => x.subjectCode === "DELIVERY_PHOTO")])}
                        >
                          <Image fontSize="small" />
                        </IconButton>
                      }
                    />
                  ) : null}
                  <KeyValuePair
                    title={t("createdAt")}
                    value={dateFormatLL(descriptionData?.createdAt)}
                  />
                  {descriptionData?.deliveryOtp && (
                    <KeyValuePair
                      title={t("deliveryOtp")}
                      value={descriptionData.deliveryOtp}
                    />
                  )}
                  {warehousing && productList.length > 0 && (
                    <KeyValuePair
                      title={t("unpacked")}
                      value={
                        descriptionData?.unpacked ? (
                          <Icon className={classes.iconColor}>
                            check_circle_outline
                          </Icon>
                        ) : (
                          <Icon color="error">highlight_off</Icon>
                        )
                      }
                    />
                  )}
                  <KeyValuePair
                    title={t("inWarehouse")}
                    value={
                      descriptionData?.inWarehouse ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )
                    }
                  />
                  <KeyValuePair
                    title={t("cancelled")}
                    value={
                      cancelShipmentStatus ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )
                    }
                  />
                  {user.hasPermission(`shipping.shipment.choose_transaction_type`) &&
                    <KeyValuePair
                      title={t("transactionType")}
                      value={<SpanLink
                        pathname={`/admin/transaction-Types/${descriptionData?.transactionType?.id}`}
                      >
                        {descriptionData?.transactionType?.name}
                      </SpanLink>
                      }
                    />
                  }
                  <KeyValuePair
                    title={t("forwarded")}
                    value={
                      descriptionData?.forwarded ? (
                        <Icon className={classes.iconColor}>
                          check_circle_outline
                        </Icon>
                      ) : (
                        <Icon color="error">highlight_off</Icon>
                      )
                    }
                  />

                </Paper>
              </Grid>

              <Grid xs={12} md={6} sx={{ display: "flex" }}>
                <Paper container className={classes.paper} component={Grid}>
                  <KeyValuePair
                    title={t("customer")}
                    // value={descriptionData?.customer?.name}
                    value={
                      descriptionData?.customer ? (
                        user.hasPermission(
                          GetPermissionSlug(
                            "shipping",
                            "customer",
                            descriptionData?.customer?.type.code,
                            "list"
                          )
                        ) ? (
                          <SpanLink
                            pathname={`/admin/customers/${descriptionData?.customer?.id}`}
                          >
                            {descriptionData?.customer?.name +
                              ` - (${descriptionData?.customer?.code})`}
                          </SpanLink>
                        ) : (
                          descriptionData?.customer?.name +
                          ` - (${descriptionData?.customer?.code})`
                        )
                      ) : (
                        "--"
                      )
                    }
                    md={6}
                  />
                  <KeyValuePair
                    title={t("senderName")}
                    value={descriptionData?.senderName}
                    md={6}
                  />
                  <KeyValuePair
                    title={t("source")}
                    value={
                      <SpanLink
                        pathname={`/admin/zones/${descriptionData.senderZone.id}`}
                      >
                        {descriptionData?.senderZone?.name +
                          " / " +
                          descriptionData?.senderSubzone.name}
                      </SpanLink>
                    }
                    md={6}
                  />
                  <KeyValuePair
                    title={t("phone")}
                    valueStyle={{ "inlineSize": "max-content" }} dir="ltr"
                    value={
                      descriptionData?.senderMobile + ` / ${descriptionData?.senderPhone}`
                    }
                    md={6}
                  />
                  <KeyValuePair
                    title={t("postalCode")}
                    value={descriptionData?.senderPostalCode}
                    md={6}
                  />
                  <KeyValuePair
                    title={t("address")}
                    value={descriptionData?.senderAddress}
                    md={6}
                  />
                </Paper>
              </Grid>
              <Grid xs={12} md={6} sx={{ display: "flex" }}>
                <Paper container className={classes.paper} component={Grid}>
                  <KeyValuePair
                    title={t("recipient")}
                    value={descriptionData?.recipientName}
                    sm={12}
                    md={12}
                  />
                  <KeyValuePair
                    title={t("destination")}
                    value={
                      <SpanLink
                        pathname={`/admin/zones/${descriptionData.recipientZone.id}`}
                      >
                        {descriptionData?.recipientZone?.name +
                          " / " +
                          descriptionData?.recipientSubzone.name}
                      </SpanLink>
                    }
                    md={6}
                  />
                  <KeyValuePair
                    title={t("phone")}
                    valueStyle={{ "inlineSize": "max-content" }} dir="ltr"
                    value={

                      descriptionData?.recipientMobile + ` / ${descriptionData?.recipientPhone}`

                    }
                    md={6}
                  />
                  <KeyValuePair
                    title={t("postalCode")}
                    value={descriptionData?.recipientPostalCode}
                    md={6}
                  />
                  <KeyValuePair
                    title={t("address")}
                    value={descriptionData?.recipientAddress}
                    md={6}
                  />
                </Paper>
              </Grid>

              <Grid xs={12} justifyContent="center">
                <Paper container className={classes.paper} component={Grid}>
                  <KeyValuePair
                    title={t("paymentType")}
                    value={descriptionData?.paymentType?.name}
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("priceType")}
                    value={descriptionData?.priceType?.name}
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("packagePrice")}
                    value={
                      (formatMoney(descriptionData?.price) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("value")}
                    value={
                      (formatMoney(descriptionData?.amount) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("deliveryFees")}
                    value={
                      (formatMoney(descriptionData?.deliveryFees) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("extraWeightCost")}
                    value={
                      (formatMoney(descriptionData?.extraWeightFees) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("collectionFees")}
                    value={
                      (formatMoney(descriptionData?.collectionFees) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("totalCost")}
                    value={
                      (formatMoney(descriptionData?.totalAmount) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("returnFees")}
                    value={
                      (formatMoney(descriptionData?.returnFees) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("deliveryType")}
                    value={descriptionData?.deliveryType?.name ?? "--"}
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("deliveredAmount")}
                    value={
                      (formatMoney(descriptionData?.deliveredAmount) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("customerDueAmount")}
                    value={
                      (formatMoney(descriptionData?.customerDue) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("returningDueFees")}
                    value={
                      (formatMoney(descriptionData?.returningDueFees) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("partialReturnStatusName")}
                    value={descriptionData?.returnStatus?.name ?? "--"}
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("returnedValue")}
                    value={
                      (formatMoney(descriptionData?.returnedValue) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                  <KeyValuePair
                    title={t("collectedFees")}
                    value={
                      (formatMoney(descriptionData?.collectedFees) || "0.00") +
                      " " +
                      currencyCode
                    }
                    sm={6}
                  />
                    {!user.account&& <KeyValuePair
                    title={t("deliveryCommission")}
                    value={descriptionData?.deliveryCommission !== null ?
                      (formatMoney(descriptionData?.deliveryCommission)) +
                      " " +
                      currencyCode : "--"
                    }
                    sm={6}
                  />}
                  {descriptionData?.paymentType?.code === "CRDT" && (
                    <Fragment>
                      <KeyValuePair
                        title={t("tax")}
                        value={
                          (formatMoney(descriptionData?.tax) || "0.00") +
                          " " +
                          currencyCode
                        }
                        sm={6}
                      />
                      <KeyValuePair
                        title={t("postFees")}
                        value={
                          (formatMoney(descriptionData?.postFees) || "0.00") +
                          " " +
                          currencyCode
                        }
                        sm={6}
                      />
                    </Fragment>
                  )}
                </Paper>
              </Grid>
              {warehousing && productList.length > 0 && (
                <FormTabsView
                  classes={classes}
                  trackingData={descriptionData}
                  outProducts={outProducts}
                  inProducts={inProducts}
                />
              )}
            </Grid>) : null)}
    </>
  )

  const transactionTable =
    (
      <Grid
        container
        sx={{ m: 0 }}
        className={clsx(classes.table, classes.tabpanel)}
      >
        {tabsLoading ?
          <Paper>
            <FullScreenLoading minHeight={"100px"} />
          </Paper> :
          <TableFixedHeaderWraper component={Paper}>
            {
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <FixedTableCell>{t("date")}</FixedTableCell>
                    <FixedTableCell align="left">{t("code")}</FixedTableCell>
                    <FixedTableCell align="left">{t("status")}</FixedTableCell>
                    <FixedTableCell align="left">{t("branch")}</FixedTableCell>
                    <FixedTableCell align="left">
                      {t("manifestName")}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {t("manifestCode")}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {t("shippingAgent")}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {t("transactionTypeName")}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {t("deliveryType")}
                    </FixedTableCell>
                    <FixedTableCell align="left">
                      {t("deliveredAmount")}
                    </FixedTableCell>
                    <FixedTableCell align="left">{t("returnType")}</FixedTableCell>
                    <FixedTableCell align="left">{t("fees")}</FixedTableCell>
                    <FixedTableCell align="left">{t("notes")}</FixedTableCell>
                    <FixedTableCell align="left">{t("reasonName")}</FixedTableCell>
                    <Can
                      permission="shipping.shipment.choose_customer"
                      showException
                    >
                      <FixedTableCell align="left">{t("updatedBy")}</FixedTableCell>
                    </Can>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {TabsData?.transactions?.shipment?.transactions.map((items, index) => {
                    return (
                      <TableRow key={index}>
                        <FixedTableCell component="th" scope="row">
                          {items.date}
                        </FixedTableCell>
                        <FixedTableCell align="left">
                          {items?.shipmentStatus.code}
                        </FixedTableCell>
                        <TableCellColor
                          align="left"
                          shipment={items} />
                        {/* <FixedTableCell align="left">
         {items?.shipmentStatus?.name}
       </FixedTableCell> */}
                        <FixedTableCell align="left">
                          {items?.branch?.name}
                        </FixedTableCell>
                        <FixedTableCell align="left">
                          {items?.trxType?.type?.name}
                        </FixedTableCell>
                        {Globals.supportedTransactionType.some(
                          (i) => i === items?.manifest?.transactionType?.type?.code
                        ) &&
                          user?.hasPermission(
                            GetManifestPermissionSlug(
                              items?.manifest?.transactionType?.type?.code.toLowerCase(),
                              "list"
                            )
                          ) ? (
                          <CellLink
                            align="left"
                            pathname={`/admin/manifests/${items?.manifest?.id}`}
                          >
                            {items?.manifest?.code}
                          </CellLink>
                        ) : (
                          <FixedTableCell align="left">
                            {items?.manifest?.code}
                          </FixedTableCell>
                        )}
                        {items?.deliveryAgent ? (
                          <CellLink
                            align="left"
                            pathname={`/admin/delivery-agents/${items?.deliveryAgent?.id}`}
                          >
                            {items?.deliveryAgent?.name}
                          </CellLink>
                        ) : (
                          <FixedTableCell align="left"></FixedTableCell>
                        )}

                        {items?.trxType ? (
                          <CellLink
                            align="left"
                            pathname={`/admin/transaction-types/${items?.trxType?.id}`}
                          >
                            {items?.trxType?.name}
                          </CellLink>
                        ) : (
                          <FixedTableCell align="left"></FixedTableCell>
                        )}
                        <FixedTableCell align="left">
                          {items?.returnType?.code === "PRTS"
                            ? items?.returnType?.name
                            : null ?? items?.deliveryType?.name}
                        </FixedTableCell>
                        <FixedTableCell align="left">
                          {items?.deliverdAmount + " " + currencyCode}
                        </FixedTableCell>
                        <FixedTableCell align="left">
                          {items?.returnType?.name}
                        </FixedTableCell>
                        <FixedTableCell align="left">
                          {items?.fees + " " + currencyCode}
                        </FixedTableCell>
                        <FixedTableCell align="left">
                          {items?.manifestEntry?.notes}
                        </FixedTableCell>
                        <FixedTableCell align="left">
                          {items?.cancellationReason?.name}
                        </FixedTableCell>
                        <Can
                          permission="shipping.shipment.choose_customer"
                          showException
                        >
                          {items?.createdBy ? (
                            <CellLink
                              align="left"
                              pathname={`/admin/users/${items?.createdBy?.id}`}
                            >
                              {items?.createdBy?.username}
                            </CellLink>
                          ) : (
                            <FixedTableCell> </FixedTableCell>
                          )}
                        </Can>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>}

            {!(TabsData?.transactions?.shipment?.transactions?.length > 0) && (
              <EmptyTableMessage loading={loading} message={t("noMessages")} />
            )}
          </TableFixedHeaderWraper>}
      </Grid>
    );

  const deliveryCallTable = (
    <Grid
      container
      sx={{ m: 0 }}
      className={clsx(classes.table, classes.tabpanel)}
    >
      {tabsLoading ?
        <Paper>
          <FullScreenLoading minHeight={"100px"} />
        </Paper> :
        <TableFixedHeaderWraper component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <FixedTableCell>{t("date")}</FixedTableCell>
                <FixedTableCell>{t("duration")}</FixedTableCell>
                <FixedTableCell>{t("phone")}</FixedTableCell>
                <FixedTableCell>{t("type")}</FixedTableCell>
                <FixedTableCell>{t("deliveryAgent")}</FixedTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                TabsData?.calls?.shipment?.deliveryCalls?.length > 0 &&
                TabsData?.calls?.shipment?.deliveryCalls.map((items, index) => {
                  return (
                    <TableRow key={index}>
                      <FixedTableCell>{items.createdAt}</FixedTableCell>
                      <FixedTableCell>
                        {moment
                          .utc(items.duration * 1000)
                          .locale("en")
                          .format("HH:mm:ss")}
                      </FixedTableCell>
                      <FixedTableCell dir="ltr">{items.phoneNumber}</FixedTableCell>
                      <FixedTableCell>{items.type.name}</FixedTableCell>
                      <CellLink
                        pathname={`${"/admin/delivery-agents/" + items?.deliveryAgent.id
                          }`}
                      >
                        {items.deliveryAgent.name}
                      </CellLink>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {!TabsData?.calls?.shipment?.deliveryCalls?.length > 0 && (
            <EmptyTableMessage loading={loading} message={t("noCalls")} />
          )}
        </TableFixedHeaderWraper>}
    </Grid>
  );
  const hasMessage = messages?.length > 0;
  const hasMessagesPermission = user.hasPermission(
    "shipping.shipment_message.list"
  );

  const { data: subMess } = useSubscription(MESSAGE_CREATED_SUBSCRIPTION);

  useEffect(() => {
    if (subMess && messages) {
      setMessages((prev) => (
        subMess.shipmentMessageCreated?.shipment?.id === parseInt(urlId) ? [subMess?.shipmentMessageCreated, ...prev] : [...prev]
      ));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subMess?.shipmentMessageCreated?.id]);

  const status = t(ShipmentStatusCode(
    trackingData?.status?.code,
    trackingData?.collected,
    trackingData?.paidToCustomer,
    trackingData?.status?.name,
    trackingData?.inWarehouse,

  ));


  const MessageTable = (props) => {
    return (
      <Grid
        container
        sx={{ m: 0 }}
        className={clsx(classes.table, classes.tabpanel)}
      >
        {tabsLoading ?
          <Paper>
            <FullScreenLoading minHeight={"100px"} />
          </Paper> :
          <TableFixedHeaderWraper component={Paper}>

            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <FixedTableCell>{t("date")}</FixedTableCell>
                  <FixedTableCell align="left">{t("user")}</FixedTableCell>
                  <FixedTableCell align="left">{t("message")}</FixedTableCell>
                  {(createMessageCondition || hideMessageCondition) && (
                    <FixedTableCell align="left">
                      {createMessageCondition ? <IconButton
                        color="primary"
                        onClick={openMessageDialog}
                        size="large"
                      >
                        <Add />
                      </IconButton>
                        : ""
                      }
                    </FixedTableCell>
                  )}
                </TableRow>
              </TableHead>
              {hasMessage && (
                <TableBody>
                  {messages.map((items, index) => {
                    return (
                      <TableRow key={index}
                        className={clsx({ [classes.cellHidden]: items.hidden })}
                      >
                        <FixedTableCell component="th" scope="row">
                          {items.createdAt}
                        </FixedTableCell>
                        <CellLink
                          align="left"
                          pathname={`${items.user.account
                            ? items.user.account?.customerId
                              ? "/admin/customers/" +
                              items.user.account?.customerId
                              : "/admin/delivery-agents/" +
                              items.user.account?.deliveryAgentId
                            : "/admin/users/" + items.user.id
                            }`}
                        >
                          {items.user?.account?.name ?? items.user?.username}
                        </CellLink>
                        <FixedTableCell align="left">
                          {items?.body}
                          {items?.images.length > 0 && (
                            <IconButton
                              size="small"
                              onClick={() => openSignatureDialog(items?.images[0])}
                            >
                              <Image fontSize="small" />
                            </IconButton>
                          )}
                        </FixedTableCell>
                        {(createMessageCondition || hideMessageCondition) && (
                          hideMessageCondition
                            ? <FixedTableCell>
                              {!items.hidden ? <MutationWithDialog
                                dialogTitle={""}
                                mutaion={DELETE_MESSAGE_MUTATION.query}
                                icon={VisibilityOff}
                                permission={true}
                                iconTooltip={t("hideMessage")}
                                dialogContent={t("confirmationStatus")}
                                mutaionProps={{ variables: { id: parseInt(items.id), hidden: true } }}
                                onCompleted={() =>
                                  setTabsData((prev) => ({
                                    ...prev,
                                    refetch: !prev.refetch,
                                  }))
                                }
                                onCompleteMessage={t("successful")}
                              />
                                :
                                <MutationWithDialog
                                  dialogTitle={""}
                                  mutaion={DELETE_MESSAGE_MUTATION.query}
                                  icon={Restore}
                                  permission={true}
                                  iconTooltip={t("hideMessage")}
                                  dialogContent={t("confirmationStatus")}
                                  mutaionProps={{ variables: { id: parseInt(items.id), hidden: false } }}
                                  onCompleted={() =>
                                    setTabsData((prev) => ({
                                      ...prev,
                                      refetch: !prev.refetch,
                                    }))
                                  }
                                  onCompleteMessage={t("successful")}
                                />
                              }
                            </FixedTableCell>
                            :
                            <FixedTableCell allowPlaceholder={false} />
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>

            {!hasMessage && (
              <EmptyTableMessage loading={loading} message={t("noMessages")} />
            )}
          </TableFixedHeaderWraper>}
      </Grid>
    );
  };

  const tapsArray = [
    {
      tabHead: t("description"),
      panel: description,
    },
    {
      tabHead: t("transactions"),
      panel: transactionTable,
    },
  ];

  const hashKeys = ["description", "transactions"];
  const queryName = {
    description: SHIPMENT_DESCRIPTION(permission),
    transactions: SHIPMENT_TRANSACTIONS(permission)
  }

  if (hasMessagesPermission) {
    tapsArray.push({
      tabHead: t("messages"),
      panel: <MessageTable />,
    });
    hashKeys.push("messages");
    queryName.messages = SHIPMENT_MESSAGES(permission)
  }

  if (hasDeliveryCalls) {
    tapsArray.push({
      tabHead: t("calls"),
      panel: deliveryCallTable,
    });
    hashKeys.push("calls");
    queryName.calls = SHIPMENT_CAllS(permission)
  }

  //////////////// Shipment History //////////////////

  const shipmentHistory = TabsData?.history?.shipment?.history;
  if (historyPermission) {
    tapsArray.push({
      tabHead: t("history"),
      panel: (
        <ShipmentHistory
          shipments={shipmentHistory}
          warehousing={warehousing}
          shipmentProducts={trackingData?.shipmentProducts?.length > 0}
          historyLoading={tabsLoading}
        />
      ),
    });
    hashKeys.push("history");
    queryName.history = SHIPMENT_HISTORY(permission)
  }
  if (trackingData && !loading && validCustomer) {
    trackingBody = (
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{ width: "100%", gap: 2 }}
      >
        <Grid container justifyContent="center" className={classes.track}>
          <Grid className={classes.codeSection}>
            <Typography
              className={classes.codeTitle}
              variant="h6"
              color="textSecondary"
            >
              {t("shipmentCode")}
            </Typography>
            <Typography variant="h5" color="primary" sx={{ ml: 1 }}>
              {trackingData?.code}
            </Typography>
          </Grid>

          <Grid container xs={12} justifyContent={"center"}>
            <ShipmentStatus isWidthXs={isWidthXs} active={true}>
              {status}
            </ShipmentStatus>

            <ShipmentStatus
              className={classes.alertHover}
              isWidthXs={isWidthXs}
              active={trackingData?.collected}
              permission={Boolean(collection)}
              code={collection?.code}
              link={collection?.id && `/admin/collections/${collection?.id}`}
            >
              {t("collectedFrom")}{" "}
              {collection?.type?.code === "CUSTM" ? t("customer") : t("agent")}
            </ShipmentStatus>

            <ShipmentStatus
              className={classes.alertHover}
              isWidthXs={isWidthXs}
              active={paidToCustomer.active}
              permission={paidToCustomer.permission}
              code={paidToCustomer.code}
              link={paidToCustomer.link}
            >
              {t("paidToCustomer")}
            </ShipmentStatus>

            <ShipmentStatus
              className={classes.alertHover}
              isWidthXs={isWidthXs}
              active={trackingData?.paidToDeliveryAgent}
              permission={Boolean(paidToDeliveryAgent)}
              code={paidToDeliveryAgent?.code}
              link={
                paidToDeliveryAgent?.id &&
                `/admin/payments/${paidToDeliveryAgent?.id}`
              }
            >
              {t("paidToDeliveryAgent")}
            </ShipmentStatus>
          </Grid>
        </Grid>
        <Grid xs={12} justifyContent="center">
          <CustomizedSteppers
            key={i18n.language}
            statusCode={trackingData?.status?.code}
            inWareHouse={trackingData?.inWarehouse}
          />
        </Grid>

        <Grid xs={12} justifyContent="center">
          <CustomTabShipment tapDetails={tapsArray}
            hashKeys={hashKeys} queryName={queryName}
            tracking={tracking} setTabsLoading={setTabsLoading}
            setTabsData={setTabsData} TabsData={TabsData} />
        </Grid>
        {/* *******Table******* */}
      </Grid>
    );
  }

  const editURL = () => {
    pushUrl(props, `/admin/shipments/${shipmentId}/edit`);
  }
  const icons = [
    {
      id: "edit",
      title: "edit",
      action: editURL,
      icon: Edit,
      permission: trackingData?.editable,
    },
    {
      id: "print",
      title: "print",
      action: openPrintDialog,
      icon: Print,
      permission: "shipping.shipment.print",
    },
    {
      id: "deleteLastAction",
      title: "deleteLastAction",
      action: openDeleteLastShipmentActionDialog,
      icon: BackspaceOutlined,
      permission: canDeleteLastAction,
    },
    {
      id: "delete",
      title: "delete",
      action: openDeleteDialog,
      icon: Delete,
      permission: canDelete,
    },
    {
      id: "cancel",
      title: !cancelShipmentStatus ? "cancel" : "restore",
      action: openCancelDialog,
      icon: !cancelShipmentStatus ? HighlightOff : Restore,
      permission: cancellable && canCancel,
    },
  ]

  return loadingPage || loading ?
    <StyledLoading container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </StyledLoading>
    : !trackingData ? (
      <NotFound />
    ) :
      !validCustomer ? (
        <Can permission="unEditable" />
      ) :
        (
          <Root>
            <CustomDialog
              title={dialogDetails.title}
              fullWidth
              maxWidth="xs"
              onClose={closeConfirmationDialog}
              content={dialogDetails.content}
              open={dialogDetails.state}
              actions={
                dialogDetails.function !== null && (
                  <>
                    <Button color="primary" onClick={closeConfirmationDialog}>
                      {dialogDetails.confirmAction ? t("cancel") : t("close")}
                    </Button>
                    {dialogDetails.confirmAction && (
                      <Button
                        color="primary"
                        disabled={loadDelete || loadDeleteLastAction}
                        onClick={dialogDetails.function}
                      >
                        {loadDelete || loadDeleteLastAction || cancelShipmentLoading ? (
                          <ButtonLoading />
                        ) : (
                          t("confirm")
                        )}
                      </Button>
                    )}
                  </>
                )
              }
            >
              {dialogDetails.function === null && messageForm}
            </CustomDialog>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              sx={{ maxWidth: "100vw" }}
              className={classes.mainTracking}
            >
              <PrintComponent
                openPrint={openPrint}
                shipmentsId={shipmentId}
                oneShipment
                hidden={["3d"]}
                setOpenPrint={setOpenPrint}
              />
              <TitleAppBar path={props.match.path}>
                <LongMenu icons={icons} />
              </TitleAppBar>
              {trackingBody}
            </Grid>
          </Root>
        );
};

export default ShipmentsView;

const ShipmentStatus = (props) => {
  const { isWidthXs, active, link, className, permission, code } = props;

  const alert = (
    <Alert
      variant="outlined"
      icon={!active ? <RadioButtonUnchecked color={"disabled"} /> : undefined}
      className={clsx(
        classes.alert,
        {
          [classes.alertDisabled]: !active,
        },
        className && className
      )}
    >
      {props.children}
    </Alert>
  );
  return (
    <Grid xs={isWidthXs && 12}>
      {link ? (
        <SecuredNavLink
          className={classes.link}
          show={permission}
          to={{
            pathname: link,
          }}
        >
          {code ? <Tooltip title={code}>{alert}</Tooltip> : alert}
        </SecuredNavLink>
      ) : (
        alert
      )}
    </Grid>
  );
};