import * as gqlb from "gql-query-builder";

export const SUM_MANIFEST_QUERY = gqlb.query({
  operation: "sumManifests",
  fields: ["shipmentsCount", "sacksCount"],
  variables: {
    input: {
      type: "ListManifestsFilterInput",
      required: true,
    },
  },
});

export const MANIFEST_TRANSFER_SHIPMENT_DATA_VIEW = (manifestShipmentData) => gqlb.query({
  operation: "manifest",
  fields: [
    "id",
    {
      operation: `entries`,
      fields: [
        {
          operation: `paginatorInfo`,
          fields: [
            "count",
            "currentPage",
            "total",
            "firstItem",
            "hasMorePages",
            "lastItem",
            "lastPage",
            "perPage"
          ],
          variables: {}
        },
        {
          operation: `data`,
          fields: [
            "pickupAmount",
            "deliveryDate",
            "fees",
            "pickupAmount",
            "deliveredValue",
            "createdAt",
            "notes",
            {
              cancellationReason: ["id", "name"]
            },
            {
              deliveryType: ["id", "name"]
            },
            {
              returnType: ["id", "name"]
            },
            {
              warehouseSection: ["id", "name"]
            },
            {
              operation: `shipment`,
              fields: [
                "id",
                "code",
                {
                  lastTransaction: [
                    {
                      shipmentStatus: ["code"]
                    },
                    { manifest: ["approved"] }
                  ]
                },
                ...manifestShipmentData
              ],
              variables: {}
            }
          ],
          variables: {}
        }
      ],
      variables: {
        first: {
          type: "Int",
        },
        page: {
          type: "Int",
        },
      },
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const MANIFEST_SHIPMENT_DATA = gqlb.query({
  operation: "manifest",
  fields: [
    "id",
    {
      operation: `entries`,
      fields: [
        {
          operation: `paginatorInfo`,
          fields: [
            "count",
            "currentPage",
            "total",
            "firstItem",
            "hasMorePages",
            "lastItem",
            "lastPage",
            "perPage"
          ],
          variables: {}
        },
        {
          operation: `data`,
          fields: [
            "pickupAmount",
            "pickupAmount",
            "deliveryDate",
            "fees",
            "pickupAmount",
            "deliveredValue",
            "createdAt",
            "notes",
            {
              cancellationReason: ["id", "name"]
            },
            {
              deliveryType: ["id", "name"]
            },
            {
              returnType: ["id", "name"]
            },
            {
              warehouseSection: ["id", "name"]
            },
            {
              operation: `shipment`,
              fields: [
                "id",
                "code",
                "id",
                "code",
                "recipientName",
                "recipientAddress",
                "recipientMobile",
                "recipientPhone",
                "description",
                "weight",
                "price",
                "piecesCount",
                "totalAmount",
                "senderName",
                "senderAddress",
                "senderMobile",
                "senderPhone",
                "returningDueFees",
                "customerDue",
                "amount",
                "returnedValue",
                "allDueFees",
                "notes",
                "date",
                "deliveredOrReturnedDate",
                "deliveredAmount",
                "attempts",
                "deliveryDate",
                "refNumber",
                {
                  transactions: [{ returnType: ["name"] }],
                },
                {
                  warehouseSection: ["id", "name"],
                },
                {
                  status: ["name"],
                },
                {
                  paymentType: ["name", "code"],
                },
                {
                  returnType: ["name"],
                },
                {
                  type: ["name", "code"],
                },
                {
                  openable: ["name", "code"],
                },
                {
                  recipientZone: ["id", "name"],
                },
                {
                  recipientSubzone: ["name"],
                },
                {
                  senderZone: ["id", "name"],
                },
                {
                  senderSubzone: ["name"],
                },
                {
                  deliveryType: ["name"],
                },
                {
                  cancellationReason: ["id", "name"],
                },
                {
                  lastTransaction: [
                    "date",
                    {
                      shipmentStatus: ["code"]
                    },
                    { manifest: ["approved"] }
                  ]
                },
                {
                  customer: [
                    "id",
                    "name",
                    "mobile",
                    { zone: ["id", "name"] },
                    { subzone: ["name"] },
                    {
                      operation: `isMobileHidden(code: MANIFEST)`,
                      fields: [],
                      variables: {
                      },
                    },
                  ],
                },
              ],
              variables: {}
            }
          ],
          variables: {}
        }
      ],
      variables: {
        first: {
          type: "Int",
        },
        page: {
          type: "Int",
        },
      },
    },

  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const LIST_MANIFESTS_QUERY = gqlb.query({
  operation: "listManifests",
  fields: [
    {
      operation: "paginatorInfo",
      fields: [
        "count",
        "currentPage",
        "total",
        "firstItem",
        "hasMorePages",
        "lastItem",
        "lastPage",
        "perPage",
      ],
      variables: {},
    },
    {
      operation: "data",
      fields: [
        "code",
        "id",
        "notes",
        "date",
        "approved",
        "sacksCount",
        // "shipmentsCount",
        // "deliveryDate",
        {
          branch: ["id", "name"],
        },
        {
          transferType: ["id", "code", "name"],
        },
        {
          referredManifests: [
            "id",
            "code",
            "date",
            "approved",
            // "shipmentsCount",
            {
              operation: "entries",
              fields: [
                { paginatorInfo: ["total"] }
              ],
              variables: {}
            }
          ],
        },
        {
          referenceBranch: ["id", "name"],
        },
        {
          transactionType: ["id", "name"],
        },
        {
          deliveryAgent: ["name", "id"],
        },
        {
          referenceManifest: ["id", "code"],
        },
        {
          customer: ["name", "id"],
        },
        {
          operation: "entries",
          fields: [
            { paginatorInfo: ["total"] }
          ],
          variables: {}
        }
      ],
      variables: {},
    },
  ],
  variables: {
    input: {
      type: "ListManifestsFilterInput",
      required: true,
    },
    first: {
      type: "Int",
      required: true,
      value: 15,
    },
    page: {
      type: "Int",
      value: 1,
    },
  },
});

export const manifestTransferFields = [
  "id",
  "date",
  "code",
  "approved",
  "sacksCount",
  "notes",
  // "shipmentsCount",
  {
    referenceBranch: ["id", "name"],
  },
  {
    referenceManifest: ["id", "code"],
  },
  {
    referredManifests: [
      "id",
      "code",
      "date",
      "approved",
      // "shipmentsCount",
      {
        deliveryAgent: ["id", "name", "code"],
      },
      {
        branch: ["id", "name"],
      },
      {
        referenceBranch: ["id", "name"],
      },
      {
        transferType: ["id", "code", "name"],
      },
      {
        transactionType: [
          "id",
          "code",
          "name",
          {
            type: ["code", "name"],
          },
        ],
      },
      // manifestData
    ],
  },
  {
    branch: ["id", "name"],
  },
  {
    updatedBy: ["id", "username"],
  },
  {
    createdBy: ["id", "username"],
  },
  {
    transferType: ["id", "code", "name"],
  },
  {
    transactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  {
    deliveryAgent: ["id", "name", "code"],
  },
  {
    route: ["id", "name"],
  },
  {
    sumEntries: [
      "fees",
      "returnedValue",
      "returningDueFees",
      "allDueFees",
      "weight",
      "piecesCount",
      "price",
      "amount",
      "totalAmount",
      "deliveredAmount",
      "shipmentsCount",
      "pickupAmount"
    ]
  },
  {
    customer: [
      "id",
      "name",
      "phone",
      "address",
      "code",
      {
        zone: ["name"],
      },
      {
        subzone: ["name"],
      },

      {
        operation: `isMobileHidden(code: MANIFEST)`,
        fields: [],
        variables: {
        },
      },
    ],
  },
  // manifestData
];

export const manifestSelectFields = [
  "id",
  "date",
  "code",
  "approved",
  "sacksCount",
  "notes",
  {
    referenceBranch: ["id", "name"],
  },
  {
    referenceManifest: ["id", "code"],
  },
  {
    referredManifests: [
      "id",
      "code",
      "date",
      "approved",
      {
        deliveryAgent: ["id", "name", "code"],
      },
      {
        branch: ["id", "name"],
      },
      {
        referenceBranch: ["id", "name"],
      },
      {
        transferType: ["id", "code", "name"],
      },
      {
        transactionType: [
          "id",
          "code",
          "name",
          {
            type: ["code", "name"],
          },
        ],
      },
      {
        operation: "entries",
        fields: [
          { paginatorInfo: ["total"] }
        ],
        variables: {}
      }
    ],
  },
  {
    branch: ["id", "name"],
  },
  {
    updatedBy: ["id", "username"],
  },
  {
    createdBy: ["id", "username"],
  },
  {
    transferType: ["id", "code", "name"],
  },
  {
    transactionType: [
      "id",
      "code",
      "name",
      {
        type: ["code", "name"],
      },
    ],
  },
  {
    deliveryAgent: ["id", "name", "code"],
  },
  {
    route: ["id", "name"],
  },
  {
    customer: [
      "id",
      "name",
      "phone",
      "address",
      "code",
      {
        zone: ["name"],
      },
      {
        subzone: ["name"],
      },
      {
        operation: `isMobileHidden(code: MANIFEST)`,
        fields: [],
        variables: {
        },
      },
    ],
  },
  {
    sumEntries: [
      "fees",
      "returnedValue",
      "returningDueFees",
      "allDueFees",
      "weight",
      "piecesCount",
      "price",
      "amount",
      "totalAmount",
      "deliveredAmount",
      "shipmentsCount",
      "pickupAmount"
    ]
  },
  {
    operation: `entries`,
    fields: [
      {
        operation: `paginatorInfo`,
        fields: [
          "count",
          "currentPage",
          "total",
          "firstItem",
          "hasMorePages",
          "lastItem",
          "lastPage",
          "perPage"
        ],
        variables: {}
      },

    ],
    variables: {
      first: {
        type: "Int",
      },
      page: {
        type: "Int",
      },
    },
  },
];

export const MANIFEST_BY_ID_QUERY = gqlb.query({
  operation: "manifest",
  fields: manifestTransferFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const MANIFEST_BY_ID_AND_PRINT = gqlb.query([
  {
    operation: "manifest",
    fields: manifestSelectFields,
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },
  {
    operation: "organization",
    fields: [
      "name",
      "phone",
      "email",
      "website",
      "reportHeader",
      "reportFooter",
    ],
    variables: {},
  },
]);

export const GET_MANIFEST_TYPE = gqlb.query([
  {
    operation: "manifest",
    fields: [
      {
        transactionType: [
          {
            type: ["code", "name"],
          },
        ],
      },
    ],
    variables: {
      id: {
        type: "Int",
        required: true,
      },
    },
  },

]);

export const SAVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "saveManifest",
  fields: manifestTransferFields,
  variables: {
    input: {
      type: "ManifestInput",
      required: true,
    },
  },
});
export const DELETE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "deleteManifest",
  fields: [],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});
export const APPROVE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "approveManifest",
  fields: manifestTransferFields,
  variables: {
    input: {
      type: "ApproveManifestInput",
      required: true,
    },
  },
});

export const COMPLETE_MANIFEST_MUTATION = gqlb.mutation({
  operation: "completeManifest",
  fields: manifestTransferFields,
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const DELIVERY_AGENT_ID = gqlb.query({
  operation: "deliveryAgent",
  fields: [
    {
      route: ["name", "id"],
    },
  ],
  variables: {
    id: {
      type: "Int",
      required: true,
    },
  },
});

export const SAVE_MANIFEST_ENTRIES_MUTATION = gqlb.mutation({
  operation: "createManifestEntry",
  fields: [{
    manifest: ["id", "code"]
  }],
  variables: {
    input: {
      type: "ManifestEntryInput",
      required: true,
    },
  },
});

export const DELETE_MANIFEST_ENTRIES_MUTATION = gqlb.mutation({
  operation: "deleteManifestEntries",
  fields: [],
  variables: {
    input: {
      type: "DeleteManifestEntryInput",
      required: true,
    },
  },
});

export const shipmentManifest = {
  PKM: [
    { key: "code", name: "code", label: "code" },
    { name: "date", key: "date", label: "date" },
    { name: "senderName", key: "sender", label: "senderName" },
    { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
    { name: "senderAddress", key: "senderAddress", label: "senderAddress" },
    // { name: "senderPhone", key: "senderPhone", label: "senderPhone" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: "price", key: "price", label: "packagePrice" },
    { name: "piecesCount", key: "piecesCount", label: "pieceCount" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "id", key: "pickupAmount", label: "amountMustBeCollected" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  BMT: [
    { key: "code", name: "code", label: "code" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
    { name: { status: ["name"] }, key: "status", label: "shipmentStatus" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: "price", key: "price", label: "packagePrice" },
    { name: "notes", key: "notes", label: "notes" },
    { name: "piecesCount", key: "piecesCount", label: "pieceCount" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  RITS: [
    { key: "code", name: "code", label: "code" },
    { name: "date", key: "date", label: "date" },
    { name: { status: ["name"] }, key: "status", label: "shipmentStatus" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "senderPhone", key: "senderPhone", label: "senderPhone" },
    { name: { lastTransaction: ["date"] }, key: "lastTransactionDate", label: "lastTransactionDate" },
    { name: { warehouseSection: ["id", "name"] }, key: "warehouseSection", label: "wareHouse" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: "recipientName", key: "recipient", label: "recipient" },
    { name: { paymentType: ["name", "code"], }, key: "paymentType", label: "paymentType" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: "piecesCount", key: "piecesCount", label: "pieceCount" },
    { name: "price", key: "price", label: "packagePrice" },
    { name: "amount", key: "amount", label: "customerDueAmount" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "returningDueFees", key: "returningDueFees", label: "returningDueFees" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  PRP: [
    { key: "code", name: "code", label: "code" },
    { name: "date", key: "date", label: "date" },
    { name: { status: ["name"] }, key: "status", label: "shipmentStatus" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "senderName", key: "sender", label: "senderName" },
    { name: { lastTransaction: ["date"] }, key: "lastTransactionDate", label: "lastTransactionDate" },
    { name: "recipientName", key: "recipient", label: "recipient" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: { paymentType: ["name", "code"], }, key: "paymentType", label: "paymentType" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: "piecesCount", key: "piecesCount", label: "pieceCount" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "price", key: "price", label: "packagePrice" },
    { name: "returningDueFees", key: "returningDueFees", label: "returningDueFees" },
    { name: "amount", key: "amount", label: "customerDueAmount" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  RTS: [
    { key: "code", name: "code", label: "code" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: { status: ["name"] }, key: "status", label: "shipmentStatus" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "senderPhone", key: "senderPhone", label: "senderPhone" },
    { name: { warehouseSection: ["id", "name"] }, key: "warehouseSections", label: "wareHouse" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "price", key: "cancellationReason", label: "reasonName" },
    { name: { returnType: ["name"] }, key: "returnTypes", label: "returnType" },
    { name: "id", key: "fees", label: "feesAmount" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  OTR: [
    { key: "code", name: "code", label: "code" },
    { name: { status: ["name"] }, key: "status", label: "shipmentStatus" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "senderPhone", key: "senderPhone", label: "senderPhone" },
    { name: { warehouseSection: ["id", "name"] }, key: "warehouseSections", label: "wareHouse" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
    { name: { transactions: [{ returnType: ["name"] }], }, key: "transactions", label: "returnType" },
    { name: "senderAddress", key: "senderAddress", label: "senderAddress" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: "recipientPhone", key: "recipientPhone", label: "recipientPhone" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  RTRN: [
    { key: "code", name: "code", label: "code" },
    { name: { customer: ["id", "name", "code"], }, key: "customer", label: "customer" },
    { name: { status: ["name"] }, key: "status", label: "shipmentStatus" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "senderName", key: "sender", label: "senderName" },
    { name: "senderAddress", key: "senderAddress", label: "senderAddress" },
    { name: "deliveredAmount", key: "deliveredAmount", label: "deliveredAmount" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: "recipientPhone", key: "recipientPhone", label: "recipientPhone" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "returnedValue", key: "returnedValue", label: "returnedValue" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: { returnType: ["name"] }, key: "returnType", label: "returnType" },
    { name: "amount", key: "amounts", label: "totalPackagePrice" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  BMR: [
    { key: "code", name: "code", label: "code" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: "recipientAddress", key: "recipientAddress", label: "recipientAddress" },
    { name: "price", key: "price", label: "packagePrice" },
    { name: { warehouseSection: ["id", "name"] }, key: "warehouseSection", label: "wareHouse" },
    { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  HTR: [
    { key: "code", name: "code", label: "code" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: "recipientAddress", key: "recipientAddress", label: "recipientAddress" },
    { name: "senderPhone", key: "senderPhone", label: "senderPhone" },
    { name: { warehouseSection: ["id", "name"] }, key: "warehouseSection", label: "wareHouse" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "notes", key: "notes", label: "notes" },
    { name: "id", key: "deliveryDate", label: "redeliveryDate" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  RCV: [
    { key: "code", name: "code", label: "code" },
    { name: { status: ["name"] }, key: "status", label: "shipmentStatus" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: { deliveryType: ["name"], }, key: "deliveryType", label: "deliveryType" },
    { name: "deliveredOrReturnedDate", key: "deliveredOrReturnedDate", label: "deliveryCanclingDate" },
    { name: { cancellationReason: ["id", "name"], }, key: "cancellationReasonshipment", label: "CancellationReasonName" },
    { name: "attempts", key: "attempts", label: "attempts" },
    { name: { warehouseSection: ["id", "name"] }, key: "warehouseSection", label: "wareHouse" },
    { name: "senderName", key: "senderName", label: "senderName" },
    { name: { senderZone: ["id", "name"], }, key: "senderZone", label: "source" },
    { name: "recipientName", key: "recipientName", label: "recipient" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: "notes", key: "note", label: "notes" },
    // { name: "id", key: "deliveryDate", label: "redeliveryDate" },
    { name: "deliveryDate", key: "deliveryDateshipment", label: "redeliveryDate" },
    // { name: "deliveryDate", key: "deliveryDate", label: "redeliveryDate" },
    { name: "weight", key: "weight", label: "weight" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  OTD: [
    { key: "code", name: "code", label: "code" },
    { name: "senderName", key: "sender", label: "senderName" },
    { name: "recipientName", key: "recipient", label: "recipient" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "recipientAddress", key: "recipientAddress", label: "recipientAddress" },
    { name: { openable: ["name", "code"] }, key: "openable", label: "packageOpen" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "totalAmount", key: "totalAmount", label: "total" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],
  STD: [
    { key: "code", name: "code", label: "code" },
    { name: "date", key: "date", label: "date" },
    { name: "senderName", key: "sender", label: "senderName" },
    { name: "recipientName", key: "recipient", label: "recipient" },
    { name: { recipientZone: ["id", "name"], }, key: "recipientZone", label: "destination" },
    { name: "recipientAddress", key: "recipientAddress", label: "recipientAddress" },
    { name: { openable: ["name", "code"] }, key: "openable", label: "packageOpen" },
    { name: "description", key: "description", label: "packageDescription" },
    { name: { type: ["name", "code"], }, key: "type", label: "packageType" },
    { name: "totalAmount", key: "totalAmount", label: "total" },
    { name: "notes", key: "note", label: "notes" },
    { name: "", key: "barcode", label: "barcode" },
    { name: "refNumber", key: "refNumber", label: "refNumber" },
  ],

}

export const initialManifest = {
  PKM: [
    "code",
    "sender",
    "senderZone",
    "senderAddress",
    "type",
    "description",
    "price",
    "piecesCount",
    "weight",
    "pickupAmount",
  ],
  BMT: [
    "code",
    "senderName",
    "senderZone",
    "recipientName",
    "recipientZone",
    "description",
    "piecesCount",
    "weight",
    "price"
  ],

  RITS: [
    "code",
    "warehouseSection",
    "senderName",
    "recipient",
    "recipientZone",
    "paymentType",
    "description",
    "piecesCount",
    "weight",
    "price",
    "returningDueFees",
    "amount",
  ],
  PRP: [
    "code",
    "sender",
    "recipient",
    "recipientZone",
    "paymentType",
    "description",
    "piecesCount",
    "weight",
    "price",
    "returningDueFees",
    "amount",
  ],
  RTS: [
    "code",
    "senderName",
    "recipientName",
    "recipientZone",
    "cancellationReason",
    "returnTypes",
    "fees",

  ],
  OTR: [
    "code",
    "warehouseSections",
    "senderName",
    "senderZone",
    "senderAddress",
    "recipientName",
    "recipientZone",
    "description",
    "transactions",
  ],
  RTRN: [
    "code",
    "customer",
    "recipientName",
    "recipientZone",
    "description",
    "returnType",
    "weight",
    "returnedValue",
    "amounts",
  ], BMR: [
    "code",
    "senderName",
    "senderZone",
    "recipientName",
    "recipientZone",
    "description",
    "weight",
  ],
  HTR: [
    "code",
    "senderName",
    "senderZone",
    "recipientName",
    "recipientZone",
    "description",
    "deliveryDate",
    "notes",
    "weight",
  ],
  RCV: [
    "code",
    "warehouseSection",
    "senderName",
    "senderZone",
    "recipientName",
    "recipientZone",
    "description",
    "deliveryDateshipment",
    "note",
    "weight",
  ],


  OTD: [
    "code",
    "recipient",
    "recipientZone",
    "recipientAddress",
    "sender",
    "type",
    "description",
    "openable",
    "note",
    "totalAmount",
  ],

  STD: [
    "code",
    "recipient",
    "recipientZone",
    "recipientAddress",
    "sender",
    "type",
    "description",
    "openable",
    "note",
    "totalAmount",
  ]




}