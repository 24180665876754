import React, { useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  SwipeableDrawer,
  Typography,
  useTheme,
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Done, DoneAll } from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  APPROVE_MANIFEST_MUTATION,
  DELIVERY_AGENT_ID,
  MANIFEST_BY_ID_QUERY,
  MANIFEST_SHIPMENT_DATA,
  SAVE_MANIFEST_MUTATION,
} from "./Graphql";
import ShipmentList from "./ManifestShipments/ShipmentList";
// import ControlMUItextField from '../HOC/MUI/ControlMUItextField';
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import Grid from "@mui/material/Unstable_Grid2";
import * as gqlb from "gql-query-builder";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
// import MUIDate from "../HOC/MUI/MUIDate";
import BarcodeSelector from "./ManifestShipments/BarcodeSelector";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
// import useManifestDetails from "./ManifestDetails";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import { Can } from "../HOC/CustomComponents/Secured";
import { GetManifestPermissionSlug } from "../../helpers/getPermissionSlug";
// import { lookupCodeIsValid } from "../HOC/CustomFunctions/lookupCodeIsValid";
import useManifestTransferDetails from "./ManifestTransferDetails";
import TitleAppBar from "../../Layout/TitleAppBar";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import config from '../../config.json'

const PREFIX = "ManifestForm";

const classes = {
  track: `${PREFIX}-track`,
  typo: `${PREFIX}-typo`,
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  appBar: `${PREFIX}-appBar`,
  title: `${PREFIX}-title`,
  textAlign: `${PREFIX}-textAlign`,
  divider: `${PREFIX}-divider`,
  table: `${PREFIX}-table`,
  filterAnchor: `${PREFIX}-filterAnchor`,
  shipmentList: `${PREFIX}-shipmentList`,
  barcode: `${PREFIX}-barcode`,
  button: `${PREFIX}-button`,
  toolbar: `${PREFIX}-toolbar`,
  selectPadding: `${PREFIX}-selectPadding`,
  margin: `${PREFIX}-margin`,

  errorColor: `${PREFIX}-errorColor`,
  overlay: `${PREFIX}-overlay`,
  pagination: `${PREFIX}-pagination`,
  main: `${PREFIX}-main`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.track}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.typo}`]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontWeight: 600,
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.title}`]: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

  [`& .${classes.textAlign}`]: {
    textAlign: "justify",
    padding: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "1px",
    },
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.filterAnchor}`]: {
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    // overflow: "scroll",
    "& .MuiDrawer-paper": {
      [theme.breakpoints.up("sm")]: {
        // width: "50%"
      },
      top: "auto",
      width: "25%",
      border: "1px solid #0000001f",
      height: "100%",
      overflow: "hidden",
    },
  },

  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.toolbar}`]: {
    backgroundColor: "#fff",
    border: "solid 1px #eee",
    zIndex: "1300",
    justify: "space-between",
  },

  [`& .${classes.selectPadding}`]: {
    paddingTop: "6px",
    paddingBottom: "5px",
  },

  [`& .${classes.margin}`]: {
    margin: theme.spacing(1, 2),
  },

  [`& .${classes.errorColor}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },

  [`& .${classes.pagination}`]: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
}));

const GridLoadingStyle = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (48px + 40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (48px + 40px + 56px))",
    },
  },
}));

const ManifestTransferForm = (props) => {
  const formType = props.match.params.type?.toUpperCase();
  const formTransferType = props.match.params.transfertype?.toUpperCase();
  const [pathURL, setPathURL] = useState(props.match.path)

  const { t } = useTranslation();

  const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

  const [manifestType, setManifestType] = useState(formType ? formType : null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    deliveryAgent: null,
    transactionType: null,
    cancellationReason: null,
    referenceBranch: null,
    route: null,
    fromManifest: null,
  });

  const theme = useTheme();
  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approveBmr: false,
    approve: false,
  });

  const [pickedShipment, setPickedShipment] = useState({ shipments: [] });
  const [receivedShipment, setReceivedShipment] = useState({ shipments: [] });

  const [manifestData, setManifestData] = useState();
  const [referredManifestData, setReferredManifestData] = useState();
  const [allReferredManifest, setAllReferredManifest] = useState(false);

  const manifestId = props.match.params.id;



  const [fieldsState, setFieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();

  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: [],
    sum: null,
    noData: true
  });

  const [disableView, setDisableView] = useState(false);

  const typeCode = [
    "DEX",
    "PKD",
    "RTS",
    "RJCT",
    "RTRN",
    "HTR",
    "DTR",
    "PKR",
  ].find((i) => i === formType);

  const defaultTransactionField = `default${typeCode}TransactionType`;

  const SETTINGS = gqlb.query({
    operation: "shippingSettings",
    fields: [{ [defaultTransactionField]: ["id", "name"] }],
    variables: {},
  });

  const chooseCustomerPermission = Globals.user.hasPermission(
    "shipping.shipment.choose_customer"
  );
  useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
      skip: !chooseCustomerPermission || !typeCode,
      onCompleted: (data) => {
        const defaultTransactionType =
          data?.shippingSettings?.[defaultTransactionField];
        setAutocompleteValues((prev) => ({
          ...prev,
          transactionType: defaultTransactionType,
        }));
      },
    }
  );

  const [saveManifestMutation, { loading: saveManifestLoad }] = useMutation(
    gql`
      ${SAVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setManifestData(data["saveManifest"]);
      },
    }
  );
  const [saveReceiveManifestMutation, { loading: saveReceiveManifetLoad }] = useMutation(
    gql`
      ${SAVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setReferredManifestData(data["saveManifest"]);
      },
    }
  );


  // const [transferTypeCode, setTransferTypeCode] = useState("BMT")
  // const transferTypeCode = manifestData?.approve ? "BMR" : "BMT"
  // const manifestLabel = {
  //   DLV: t("distributionTripForm"),
  //   RTS: t("returnTripForm"),
  // }
  const sendWhatsappMessageStorage = localStorage.getItem(
    "sendWhatsappMessageStorage"
  );
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      sendWhatsappMessage:
        sendWhatsappMessageStorage === null ||
          sendWhatsappMessageStorage === "true"
          ? true
          : false,
    },
  });

  /******************************************* Start Pagination Functions ********************************************/


  const handleChangePage = (event, newPage) => {
    setShipmentData(prev => {
      return {
        ...prev,
        page: newPage
      }
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setShipmentData(prev => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: event.target.value
      }
    })
  };

  /******************************************* End Pagination Functions ********************************************/

  /******************************************* Start Mutations ********************************************/

  const [approveManifetMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setManifestData(data["approveManifest"]),
    }
  );

  const { data: transactionTypeBmr } = useQuery(
    gql`
      ${LIST_TRANSACTION_TYPES_DROPDOWN.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !manifestData?.approved,
      variables: {
        input: {
          type: "BMR",
          ...(watch("branchId") && {
            branchId: {
              value: watch("branchId"),
              includeNull: true,
            },
          }),
        },
      },
      onCompleted: (data) => {
      },
      onError: ({ graphQLErrors }) => {
      }
    }
  );

  // useEffect(() => {
  //   if (disabledFirstRender.current && !referredManifestData) {
  //     const shipments = manifestShipments
  //       .filter((i) => !i["pendingDelete"])
  //       .map((item) => ({
  //         shipmentId: item.shipment.id,
  //         ...(manifestType === "BMR" && {
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),
  //       }));
  //     const variables = watch();
  //     delete variables["sendWhatsappMessage"];
  //     for (const key in variables) {
  //       if (variables[key] === "") {
  //         variables[key] = null;
  //       }
  //     }

  //     variables["date"] = dateFormat(variables["date"]);

  //     saveManifestMutation({
  //       variables: {
  //         input: {
  //           ...variables,
  //           ...(manifestType === "BMT" && { sacksCount: parseInt(variables.sacksCount) }),
  //         },
  //       },
  //     })
  //       .then((data) => { })
  //       .catch(({ graphQLErrors }) => {
  //         setValidationError(graphQLErrors, setError);

  //         console.log(graphQLErrors);
  //       });
  //   }
  //   if (disabledFirstRender.current && referredManifestData) {
  //     const shipments = manifestShipmentsReceived
  //       .filter((i) => !i["pendingDelete"] && i["shipment"]["fromManifest"] === referredManifestData.id)
  //       .map((item) => ({
  //         shipmentId: item.shipment.id,
  //         ...(manifestType === "BMR" && {
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),
  //       }));

  //     saveReceiveManifetMutation({
  //       variables: {
  //         input: {
  //           id: referredManifestData.id,
  //           code: referredManifestData.code,
  //           date: referredManifestData.date,
  //           fromManifestId: parseInt(manifestId),
  //           branchId: referredManifestData.branch.id,
  //           referenceBranchId: referredManifestData.referenceBranch.id,
  //           transactionTypeId: referredManifestData.transactionType.id,
  //           deliveryAgentId: referredManifestData.deliveryAgent?.id,
  //           transferTypeCode: referredManifestData.transferType.code,
  //           manifestShipments: shipments,
  //         },
  //       },
  //     })
  //       .then((data) => { })
  //       .catch(({ graphQLErrors }) => {
  //         setValidationError(graphQLErrors, setError);
  //         console.log(graphQLErrors);
  //       });
  //   }
  //   disabledFirstRender.current = true;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pickedShipment, receivedShipment]);

  /******************************************* End Mutations ********************************************/

  /******************************************* Start Manifest By Id ********************************************/

  const { data: manifestByIdQuery, loading: manifestByIdLoad, refetch: refetchManifestQuery } = useQuery(
    gql`
      ${MANIFEST_BY_ID_QUERY.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !manifestId,
      variables: {
        id: parseInt(manifestId) || parseInt(watch("id")),
      },
      onCompleted: (data) => {
        if (
          data?.manifest === null ||
          !Globals.supportedTransactionType.some(
            (i) => i === data?.manifest?.transactionType?.type?.code
          )
        ) {
        } else {
          const manifest = data?.manifest;
          setManifestType(manifest?.transactionType?.type.code);
          const shipments = []
          if (manifest?.referredManifests.length > 0) {
            setAllReferredManifest(true)
            const referredManifestNotApproved = manifest?.referredManifests?.find((e) => e.approved === false)
            setReferredManifestData(referredManifestNotApproved)
            // manifest?.referredManifests.map((e) => {
            //   e.manifestShipments.map(i => i.shipment.fromManifest = e.id)
            //   e.manifestShipments.map(i => i.shipment.deletable = !e.approved)
            //   shipments.push(...e.manifestShipments)
            //   return (e)
            // })
            receivedShipment["shipments"] = shipments;
          }
          setManifestData(manifest);
          setValue("id", parseInt(manifest?.id));
          setValue("code", manifest?.code);
          setValue("date", manifest?.date);
          manifest?.sacksCount && setValue("sacksCount", manifest?.sacksCount);

          manifest?.notes && setValue("notes", manifest?.notes);

          manifest?.transactionType?.code &&
            setFieldsState((prev) => ({
              ...prev,
              createdAt: manifest?.date,
            }));

          setAutocompleteValues({
            branch: manifest.branch,
            ...(manifest?.customer && {
              customer: manifest?.customer,
            }),
            ...(manifest?.deliveryAgent && {
              deliveryAgent: manifest?.deliveryAgent,
            }),
            ...(manifest?.referenceBranch && {
              referenceBranch: manifest?.referenceBranch,
            }),
            ...(manifest?.route && {
              route: manifest?.route,
            }),
            transactionType: manifest.transactionType,
            fromManifest: manifest.referenceManifest,
          });
          setDisableForm(true);
          shipmentData.noData && getShipmentData()
          // data?.manifest?.approve && setTransferTypeCode("BMR")
          pickedShipment["shipments"] = [];
        }
      },
      onError: ({ graphQLErrors }) => {
        const auth = graphQLErrors[0].extensions.category
        auth === 'authorization' && setDisableView(true)
      }
    }
  );

  const [getShipmentData, { loading: loadingShipmentData, refetch: refetchShipmentDataQuery }] = useLazyQuery(
    gql`
        ${MANIFEST_SHIPMENT_DATA.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(watch("id")),
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.manifest === null) return;
        const manifest = data?.manifest;
        const shipments = manifest.entries.data.map(e => {
          return {
            ...e,
            shipment: {
              ...e.shipment,
              pendingDelete: false
            }
          };
        });
        setShipmentData(prev => {
          return {
            ...prev,
            pickedShipment: shipments,
            total: manifest.entries.paginatorInfo.total,
            noData: false,
          }
        })
        refetchManifestQuery()
      },
    }
  );

  const { details: manifestDetails } = useManifestTransferDetails({
    approved: manifestData?.approved,
    approvedBmr: referredManifestData?.approved,
    setPickedShipment,
    setReceivedShipment,
    pickedShipment,
    type: manifestType,
    saveManifestLoad,
    receivedShipment,
    referredManifestData,
    allReferredManifest,
    saveReceiveManifetLoad,
    edit: "edit",
    typeData: manifestData,
    refetchShipmentDataQuery
  });

  const manifestStatus = manifestDetails;

  const [state, setState] = useState({
    shipmentList: {
      left: false,
      bottom: false,
      right: false,
    },
    manifestList: {
      left: false,
      bottom: false,
      right: false,
    },
  });
  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const shipmentDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      shipmentList: { ...prev.shipmentList, [anchor]: open },
    }));
  };

  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";

  const history = useHistory();
  ////////////////////END DRAWER////////////////

  /******************************************* Start OnSubmit ********************************************/
  const isFormValuesChange = (data) => {
    return (
      manifestData &&
      (((manifestType === "PKM" || manifestType === "RTRN" || manifestType === "OTR") &&
        data?.customerId &&
        data?.customerId !== manifestData?.customer?.id) ||
        (data?.deliveryAgentId && data?.routeId !== manifestData?.route?.id) ||
        (data?.branchId && data?.branchId !== manifestData?.branch?.id) ||
        (manifestType === "BMR" && data?.referenceBranchId &&
          data?.referenceBranchId !== manifestData?.referenceBranch?.id) ||
        ((manifestType === "BMT" || manifestType === "BMR") && data?.transferTypeCode &&
          data?.transferTypeCode !== manifestData?.transferType?.code))
    );
  };

  const onSubmit = (data) => {
    data["date"] = dateFormat(data["date"]);
    delete data["sendWhatsappMessage"];

    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    const newManifest = {
      ...data,
    };

    if (shipmentData.pickedShipment.length > 0 && isFormValuesChange(data)) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true, data: newManifest }));
      return;
    }

    // const shipments = manifestShipments.map((item) => ({
    //   shipmentId: item.shipment.id,
    //   ...(manifestType === "RTS" && {
    //     returnTypeCode: item.returnType.code,
    //     fees: item.fees,
    //     cancellationReasonId: item.cancellationReason.id,
    //   }),
    // }));
    saveManifestMutationFun(newManifest)
  };

  const saveManifestMutationFun = (newManifest, refetchData) => {
    saveManifestMutation({
      variables: {
        input: {
          ...newManifest,
          sacksCount: parseInt(newManifest.sacksCount),
          transferTypeCode: formTransferType,
        },
      },
    })
      .then((data) => {
        setDisableForm(true);
        setValue("code", data?.data?.saveManifest?.code);
        setValue("id", parseInt(data?.data?.saveManifest?.id));
        const url = history.createHref({
          pathname: `/admin/manifests/${formTransferType.toLowerCase()}/${data?.data?.saveManifest?.id}/edit`,
        });
        setPathURL(url);
        windowReplaceUrl(url);
        refetchData && refetchManifestQuery()
        refetchData && refetchShipmentDataQuery()
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  }

  const receiveManifest = () => {
    saveReceiveManifestMutation({
      variables: {
        input: {
          date: dateFormat(moment().format()),
          fromManifestId: parseInt(manifestId),
          branchId: manifestData.referenceBranch.id,
          referenceBranchId: manifestData.branch.id,
          transactionTypeId: transactionTypeBmr?.listTransactionTypesDropdown?.[0].id,
          deliveryAgentId: null,
          transferTypeCode: formTransferType,
        },
      },
    })
      .then((data) => {

      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  };

  /******************************************* End OnSubmit ********************************************/

  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  const shipLength = shipmentData.total === 0;

  const approveManifest = (id) => {
    approveManifetMutation({
      variables: {
        input: {
          id: parseInt(id),
        },
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("recordConfirmationSucceededMessage"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/manifests/${watch("id")}`);
      })
      .catch(({ graphQLErrors }) => {
        handleDialogClose();
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };
  const onApprovedBmr = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approveBmr: true }));
  };


  const parseData = (data) => {
    return data;
  };

  /******************************************* Start Fields By Type ********************************************/
  const [getRoute] = useLazyQuery(
    gql`
      ${DELIVERY_AGENT_ID.query}
    `,
    {
      skip:
        manifestType !== "OTD" ||
        manifestType !== "PKM" ||
        manifestType !== "OTR",
      onCompleted: (data) => {
        setAutocompleteValues((prev) => ({
          ...prev,
          route: data.deliveryAgent.route,
        }));
      },
    }
  );

  const deliveryAgentField = (type) => {
    if (type !== "RITS") {
      return (
        <Grid
          sm={
            6
          }
          xs={12}
        >
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            disabled={!watch("branchId")}
            onChangeValue={(e) => {
              if (e?.id) {
                getRoute({ variables: { id: e?.id } });
                clearErrors("deliveryAgentId");
              }
            }}
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                active: true,
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    }
  };
  const branchTransferField = (type) => {
    const branchData = (data) => {
      const parsed = data?.filter((i) => i.id !== watch("branchId"));
      return parsed;
    };
    if (type === "BMT" || type === "BMR") {
      return (
        <Grid sm={6} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"referenceBranchId"}
            label={type === "BMT" ? t("toBranch") : t("fromBranch")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => branchData(data)}
            query={LIST_BRANCHES_DROPDOWN.query}
            variables={{
              input: {
                all: true,
              },
            }}
            defaultValue={autocompleteValues.referenceBranch}
          />
        </Grid>
      );
    }
  };

  const sacksCountField = (type) => {
    if (type === "BMT") {
      return (
        <Grid sm={6} xs={12}>
          <ControlMUItextField
            type="number"
            control={control}
            name={"sacksCount"}
            label={t("sacksCount")}
          />
        </Grid>
      );
    }
  };

  /******************************************* End Fields By Type ********************************************/

  let formBody = null;
  let tableBody = null;
  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          classes={classes}
          selectedShipment={shipmentData.pickedShipment}
          total={shipmentData.total}
          manifestType={manifestType}
          addShipment={() => shipmentDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          approved={referredManifestData ? referredManifestData?.approved : manifestData?.approved}
          manifestId={watch("id")}
        />

        {manifestData && !loadingShipmentData && (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              data={shipmentData.pickedShipment}
              headCells={manifestStatus.tableHead}
              parseBodyCell={manifestStatus.tableBody}
              total={manifestStatus.tableTotal}
              sumRow={manifestStatus?.sum}
              sumTableNumber={manifestStatus?.sumTableNumber}
              ranking={true}
            />
          </TableFixedHeaderWraper>
        )}

        <Grid container justifyContent="center" alignItems="center">
          {loadingShipmentData ?
            <FullScreenLoading minHeight={"100px"} />
            : shipLength && watch("id") && disableForm && (
              <EmptyTableMessage
                message={t("noResult")}
              />
            )}
        </Grid>

        {shipmentData.total > 0 && (
          <MUITablePagination
            count={shipmentData.total}
            rowsPerPage={shipmentData.rowsPerPage}
            page={shipmentData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!watch("id") || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestRequired")}
            </Typography>
          </Box>
        )}

      </Paper>
    </Grid>
  );

  const formCondition = !formType
    ? Boolean(watch("id")) && !manifestByIdLoad
    : !manifestByIdLoad;

  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      {formCondition && manifestType ? (
        <Paper container component={Grid} sx={{ padding: 1, flexGrow: 1 }}>
          {watch("id") && disableForm && (
            <Grid sm={12} xs={12}>
              <Alert
                severity="warning"
                action={
                  !manifestData?.approved && (
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setDisableForm(false);
                      }}
                    >
                      {t("update")}
                    </Button>
                  )
                }
              >
                {manifestData?.approved
                  ? t("updateRecordsForbidden")
                  : t("updateRecord")}
              </Alert>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            sx={{ position: "relative", flexGrow: 1 }}
          >
            {(manifestData?.approved || disableForm) && <Box className={classes.overlay}></Box>}
            <Grid sm={4} xs={12}>
              <ControlMUItextField
                name="code"
                control={control}
                label={t("manifestCode")}
                rules={{ required: watch("id") ? t("fieldIsRequired") : false }}
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <MUIDateTime
                name="date"
                label={t("date")}
                control={control}
                defaultValue={manifestByIdQuery?.manifest?.date}
                value={fieldsState.createdAt}
                onChange={(e) =>
                  setFieldsState((prev) => ({ ...prev, createdAt: e }))
                }
              />
            </Grid>
            <Grid sm={4} xs={12}>
              <ListBranches
                control={control}
                errors={errors}
                name={"branchId"}
                rules={{ required: t("fieldIsRequired") }}
                skip={manifestType === null}
                defaultValue={autocompleteValues.branch}
                onChangeValue={(e) => {
                  setValue("deliveryAgentId", "");
                  (manifestType === "BMT" || manifestType === "BMR") &&
                    setValue("referenceBranchId", "");
                }}
                skipDefaultBranch={Boolean(manifestId)}
              />
            </Grid>
            {deliveryAgentField(manifestType)}
            {branchTransferField(manifestType)}
            <Grid
              sm={6}
              xs={12}
            >
              <CustomAutocomplete
                control={control}
                errors={errors}
                selectFirst={true}
                name={"transactionTypeId"}
                label={t("transactionType")}
                rules={{ required: t("fieldIsRequired") }}
                parseData={(data) => parseData(data)}
                query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
                variables={{
                  input: {
                    type: manifestType,
                    ...(watch("branchId") && {
                      branchId: {
                        value: watch("branchId"),
                        includeNull: true,
                      },
                    }),
                  },
                }}
                defaultValue={autocompleteValues.transactionType}
              />
            </Grid>
            {sacksCountField(manifestType)}

            <Grid sm={12} xs={12}>
              <ControlMUItextField
                name="notes"
                control={control}
                label={t("notes")}
                rows={2}
              />
            </Grid>
          </Grid>

          <Grid sm={12} xs={12} container justifyContent="flex-end">
            {!disableForm && (
              <CustomButton
                customcolor={theme.palette.success.main}
                type="submit"
                className={classes.button}
                disabled={
                  manifestData?.approved || disableForm || saveManifestLoad
                }
                variant="contained"
                size="medium"
                loading={saveManifestLoad}
                // className={classes.button}
                startIcon={!saveManifestLoad && <Done />}
              >
                {!saveManifestLoad && t("save")}
              </CustomButton>
            )}
            {!manifestData?.approved && Globals.user.hasPermission(GetManifestPermissionSlug(manifestType.toLowerCase(), 'approve')) && disableForm && (
              <Button
                disabled={
                  shipLength || saveManifestLoad
                }
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApproved}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("prepared")}
              </Button>
            )}
            {manifestData?.approved && !referredManifestData?.approved && Globals.user.canAccessBranch(manifestData.referenceBranch.id) && Globals.user.hasPermission(GetManifestPermissionSlug("bmr", 'create')) && disableForm && referredManifestData && (
              <Button
                disabled={
                  shipLength || saveManifestLoad
                }
                className={classes.button}
                variant="contained"
                size="medium"
                color="primary"
                onClick={onApprovedBmr}
                name="approved"
                startIcon={<DoneAll />}
              >
                {t("received")}
              </Button>
            )}
            {manifestData?.approved &&
              Globals.user.canAccessBranch(manifestData.referenceBranch.id) &&
              Globals.user.hasPermission(GetManifestPermissionSlug("bmr", 'create')) &&
              disableForm && !referredManifestData && shipmentData.pickedShipment.some(e => e.shipment?.lastTransaction?.shipmentStatus?.code === "BMT") && (
                <CustomButton
                  disabled={
                    shipLength || saveReceiveManifetLoad
                  }
                  customcolor={theme.palette.success.main}
                  className={classes.button}
                  variant="contained"
                  size="medium"
                  // color="primary"
                  loading={saveReceiveManifetLoad}
                  onClick={receiveManifest}
                  name="approved"
                  startIcon={<DoneAll />}
                >
                  {t("receive")}
                </CustomButton>
              )}
          </Grid>
        </Paper>
      ) : (
        <FullScreenLoading />
      )}
    </Grid>
  );

  const validCondition =
    !manifestByIdQuery?.manifest && manifestId &&
    !Globals.supportedTransactionType.some(
      (i) => i === manifestByIdQuery?.manifest?.transactionType?.type?.code
    );

  // const icons = [
  //   {
  //     id: "print",
  //     title: "print",
  //     action: () =>
  //       window.open(
  //         `${window.location.origin}/report/print/manifest/${watch(
  //           "id"
  //         )}/${manifestType}`
  //       ),
  //     icon: Print,
  //     disabled: manifestByIdLoad,
  //     permission: !shipLength,
  //   },
  // ]

  return manifestByIdLoad ?
    <GridLoadingStyle container item justifyContent="center" className={classes.main}>
      <FullScreenLoading height={"100%"} />
    </GridLoadingStyle>
    : validCondition ? (
      <NotFound />
    ) : disableView ? (
      <Can permission="uneEditable" />
    ) : (
      <Root>
        <TitleAppBar path={pathURL} type={manifestType} transferType={formTransferType} >
          {/* <LongMenu icons={icons} /> */}
        </TitleAppBar>
        {props.children}

        {formBody}

        <Dialog
          open={openDialog.open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-title" color={"text.primary"}>
            {openDialog.edit && t("updateRecord")}
            {(openDialog.approve || openDialog.approveBmr) && t("confirmation")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {openDialog.edit && t("updateManifestWarningMessage")}
              {(openDialog.approve || openDialog.approveBmr) && t("confirmationMsg")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>

            <Button onClick={handleDialogClose} color="primary">
              {t("cancel")}
            </Button>
            <Button
              disabled={approveLoad}
              onClick={() => {
                openDialog.edit && handleDialogClose();
                openDialog.edit && setDisableForm(true);
                openDialog.edit && saveManifestMutationFun(openDialog.data, refetchShipmentDataQuery);
                openDialog.approve && approveManifest(watch("id"));
                openDialog.approveBmr && approveManifest(referredManifestData.id);
              }}
              color="primary"
              autoFocus
            >
              {openDialog.edit && t("update")}
              {(openDialog.approve || openDialog.approveBmr) && !approveLoad && t("confirmation")}
              {approveLoad && <ButtonLoading />}
            </Button>
          </DialogActions>
        </Dialog>

        <SwipeableDrawer
          variant="persistent"
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor={anchor}
          open={state.shipmentList[anchor]}
          onClose={() => false}
          onOpen={() => shipmentDrawerHandler(anchor, true)}
          classes={{ paper: classes.shipmentList }}
          dir={theme.direction}
        >
          {state.shipmentList[anchor] && (
            <ShipmentList
              type={referredManifestData ? "BMR" : manifestType}
              done={() => shipmentDrawerHandler(anchor, false)}
              refetchShipmentDataQuery={refetchShipmentDataQuery}
              variables={referredManifestData ? referredManifestData : watch()}
            />
          )}
        </SwipeableDrawer>

        <SwipeableDrawer
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor={anchor}
          open={barcodeDarwerState[anchor]}
          onClose={() => false}
          onOpen={() => barcodeDrawerHandler(anchor, true)}
          sx={{
            width: "auto",
            "& .MuiDrawer-paper": {
              width: { md: "50%" },
              height: "100%",
              overflow: "hidden",
            },
          }}
          dir={theme.direction}
        >
          {barcodeDarwerState[anchor] && <BarcodeSelector
            type={manifestType}
            refetchShipmentDataQuery={refetchShipmentDataQuery}
            done={() => barcodeDrawerHandler(anchor, false)}
            variables={referredManifestData ? referredManifestData : watch()}
          />}
        </SwipeableDrawer>

        <Grid
          container
          spacing={3}
          justifyContent="center"
          className={classes.mainTracking}
        >
          {formCondition && tableBody}
        </Grid>
      </Root>
    );
};

export default ManifestTransferForm;
