import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { Globals } from "../HOC/Classes/Globals";
export default function ShipmentColumView(props) {
  const { disabled, openViewColum, setOpenViewColum, localStorageName, initialData, setKeys, shipmentFields } = props;
  const [shipmentFieldsState, setShipmentFieldsState] = useState([]);
  const { t } = useTranslation();
  const icon = !Boolean(setOpenViewColum)

  useEffect(() => {
    // if (adminNotesPermission) {
    //   const found = shipmentFields.some(el => el.key === "adminNotes");
    //   if (!found) shipmentFields.push({ name: "adminNotes", key: "adminNotes", label: "adminNotes" },);
    //   const queryShipmentFields = shipmentFields;
    //   if (!queryShipmentFields[0].id)
    //     queryShipmentFields.forEach((ele, index) => {
    //       ele["id"] = index + 1;
    //       ele["checked"] = true;
    //     });
    // }
    const queryShipmentFields = shipmentFields;
    if (!queryShipmentFields[0].id)
      queryShipmentFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });
    const localStorageViewShipment = localStorage.getItem(localStorageName);
    const parsedViewShipment = localStorageViewShipment
      ? JSON.parse(localStorageViewShipment)
      : initialData;
    const notCheckedFields = queryShipmentFields.filter((i) => {
      if (
        !parsedViewShipment?.includes(i.key) &&
        parsedViewShipment !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = queryShipmentFields.filter((i) => {
      if (parsedViewShipment?.includes(i.key)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedViewShipment?.indexOf(a.key) >
        parsedViewShipment?.indexOf(b.key)
        ? 1
        : -1;
    });
    if (notCheckedFields.some((item) => item.key.includes("status"))) {
      const indexCode = notCheckedFields.findIndex((item) => item.key === "status");
      const element = notCheckedFields[indexCode]
      notCheckedFields.splice(indexCode, 1);

      sortedFields.splice(0, 0, element);

    }
    if (notCheckedFields.some((item) => item.key === "code")) {
      const indexCode = notCheckedFields.findIndex((item) => item.key === "code");
      const element = notCheckedFields[indexCode]
      notCheckedFields.splice(indexCode, 1);

      sortedFields.splice(0, 0, element);

    }
    setShipmentFieldsState(
      [...sortedFields, ...notCheckedFields]

    );
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openViewColum]);
  const [dialogState, setDialogState] = useState(false);
  const fieldCount = shipmentFieldsState.length;
  const fieldSelected = shipmentFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fields = [...shipmentFieldsState];
    fields.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setShipmentFieldsState(fields);
  };


  const onCloseDialog = () => {
    icon ? setDialogState(false) : setOpenViewColum(false)
  };

  const onOpenDialog = () => {
    setDialogState(true);
  };
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(shipmentFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);

    setShipmentFieldsState(items);
  }
  const handelViewShipment = () => {

    const updatedFields = shipmentFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.key);
      }
      return previous;
    }, []);
    if (updatedFields.includes("status") && updatedFields==="code") {
      updatedFields[0] = "code"
      updatedFields[1] = "status"
    }

    let keysSelected = []

    updatedFields.map((e) =>
      shipmentFields.map((i) =>
        e === i.key && keysSelected.push(i))
    )

    setKeys(updatedFields)
    localStorage.setItem(localStorageName, JSON.stringify(updatedFields));
    // setLocalDataKey([])
    // setLocalDataKey(updatedFields)
    onCloseDialog()

  };
  return (
    <>
      {icon && <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip>}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={icon ? dialogState : openViewColum}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("viewColums")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={fieldSelected === 0}
              onClick={() => {
                handelViewShipment();
              }}>

              {<ButtonLoading /> && t("save")}
            </Button>
          </>
        }
        content={
          <DragDropContext
            onDragEnd={handleOnDragEnd}
          >
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  <div>
                    {shipmentFieldsState.map((value, index) => {
                      const labelId = `checkbox-list-label-${value.id}`;
                      if (value.key === "code" || value.key === "status") {
                        return (
                          <div
                            key={value.id}
                          >
                            <CustomeItemList
                              itemDetails={value}
                              labelId={labelId}
                              state={shipmentFieldsState}
                              setState={setShipmentFieldsState}
                            />
                          </div>
                        );
                      }

                      return (
                        <Draggable
                          key={value.id}
                          draggableId={value.key}
                          index={index}
                          isDragDisabled={!value.checked}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CustomeItemList
                                itemDetails={value}
                                labelId={labelId}
                                state={shipmentFieldsState}
                                setState={setShipmentFieldsState}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                  {provided.key}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;
  const { t } = useTranslation();
  // const [checkboxState,setCheckboxState] = React.state(checked)
  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    if (itemDetails.label === "code" || itemDetails.label === "status") {
      const index = update.findIndex((i) => i.key === itemDetails.key);
      const [sortItem] = update.splice(index, 1);
      sortItem["checked"] = checked;
      update.splice(index, 0, sortItem);
      setState(update);
    }
    else {
      const checkedItemLength = update.filter((i) => i.checked).length;
      const index = update.findIndex((i) => i.key === itemDetails.key);
      const [sortItem] = update.splice(index, 1);
      sortItem["checked"] = checked;
      if (checkedItemLength === 1 || checkedItemLength === 0) {
        update.splice(2, 0, sortItem);
        return setState(update)
      }
      if (update[0].checked === false || update[1].checked === false) {
        if (update[0].checked === false && update[1].checked === false) {
          update.splice(checkedItemLength + 2, 0, sortItem);
          return setState(update)
        }
        update.splice(checkedItemLength + 1, 0, sortItem);
      }
      else {

        update.splice(checkedItemLength, 0, sortItem);
      }

      setState(update);
    }
  };
  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={t(label)} />
    </ListItem>
  );
};
export const renderData = (shipmentLocalKey, setShipmentListDetails, shipmentFieldsQuery, addQuarry) => {
  const shipmentFieldsState = shipmentFieldsQuery.filter(obj => shipmentLocalKey.includes(obj.key));
  const sortedFields = shipmentFieldsState.sort((a, b) => {
    return shipmentLocalKey?.indexOf(a.key) >
      shipmentLocalKey?.indexOf(b.key)
      ? 1
      : -1;
  });
  const shipmentTable = sortedFields
    .map((i) => (i.key));
  const shipmentField = sortedFields
    .map((i) => (i.name));
  const shipmentTableHead = sortedFields
    .map((i) => (i.label));
  if (shipmentTable.includes("senderZone")) {
    const indexCode = shipmentTable.indexOf("senderZone");
    const element = { senderSubzone: ['name'] }
    shipmentField.splice(indexCode, 0, element);
  }
  if (shipmentTable.includes("recipientZone")) {
    const indexCode = shipmentTable.indexOf("recipientZone");
    const element = { recipientSubzone: ['name'] }
    shipmentField.splice(indexCode, 0, element);
  }
  if (shipmentTable.includes("netCollection")) {
    const indexCode = shipmentTable.indexOf("netCollection");
    shipmentField.splice(indexCode, 0, "pendingCollectionAmount");
    shipmentField.splice(indexCode, 0, "");
  }

  const arrFields = []

  shipmentTable && shipmentTable.map((i, index) => {
    if (i === "price" || i === "deliveredAmount" || i === "deliveryCommission" || i === "allDueFees" || i === "customerDue" || i === "amount" || i === "totalAmount" || i === "collectedFees" || i === "returningDueFees" || i === "collectedAmount" || i === "customerDueCredit" || i === "customerDueDebit" || i === "pendingCollectionAmount") {
      arrFields.push(i)
    }
    return '';
  })
  shipmentTable && shipmentTable.map((i, index) => {
    if (i === "netCollection") {
      arrFields.push("deliveryCommission")
      arrFields.push("pendingCollectionAmount")
    }
    return '';
  })
  if (addQuarry) {
    const user = Globals.user;
    const adminNotesPermission = user.hasPermission(
      "shipping.shipment.view_admin_note"
    );
    setShipmentListDetails({
      shipmentQueryFields:
        [...shipmentField, `adminNotes @include(if: ${adminNotesPermission})`, "deliveryFees", "returnFees", "totalAmount", { paymentType: ["name", "code"], }, "allDueFees", { type: ["name", "code"] }, { branch: ["id"], }, {
          shipmentProducts: [
            "price",
            "quantity",
            {
              product: ["id", "name"]
            }
          ]
        },],
      shipmentTableBody: [...shipmentTable, "updata"],
      shipmentTableHead: [...shipmentTableHead, " "],
      sumShipmentQueryFields: arrFields,
    });

  }
  else {
    setShipmentListDetails({
      shipmentQueryFields: shipmentField,
      shipmentTableBody: shipmentTable,
      shipmentTableHead: shipmentTableHead,
      sumShipmentQueryFields: arrFields,
    })
  }
};