import React from 'react'
import { Badge, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { gql, useQuery } from '@apollo/client';
import * as gqlb from "gql-query-builder";
import { Globals } from '../Component/HOC/Classes/Globals';

const Root = styled(Link)((
    {
        theme
    }
) => ({
    color: theme.palette.mode === "dark" ? "#ffffff" : "#757575"

}));

const REQUESTS = (operation, inputType) => {
    return {
        operation: operation,
        fields: [
            {
                operation: "paginatorInfo",
                fields: ["total"],
                variables: {},
            },
        ],
        variables: {
            first: {
                type: "Int",
            },
            input: {
                name: "input",
                type: inputType,
            },
        },
    }
};

export default function HeaderIcons(props) {
    const { pathname, operation, inputType, type } = props

    const REQUESTS_LIST = gqlb.query(REQUESTS(operation, inputType))
    useQuery(
        gql`
            ${REQUESTS_LIST.query}
        `,
        {
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            variables: {
                first: 1,
                input: {
                    statusCode: "NEW",
                    ...(["PMNT", "RTRN", "MTRL"].includes(type) && { typeCode: type }),
                    ...(["PICKUP"].includes(type) && { new: true }),
                },
            },
            onCompleted: (data) => {
                Globals[type] = data[operation].paginatorInfo.total
            },
        }
    );

    return (
        <Root to={pathname}>
            <IconButton
                size={"large"}
                sx={{ p: 1 }}
            >
                <Badge
                    badgeContent={Globals[type]}
                    color="primary"
                    variant='standard'
                    invisible={!Globals[type]}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}>
                    <props.icon size={21} />
                </Badge>
            </IconButton>
        </Root>
    )

}
