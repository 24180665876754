import React, { useState } from 'react'
import CustomDialog from '../../HOC/CustomComponents/CustomDialog';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LIST_LOOKUP_ENTRIES_DROPDOWN } from '../../../GlobalsQuery/ListDropdown/ListDropdown';
import {
    Typography
} from "@mui/material";
import ControlMUItextField from '../../HOC/MUI/ControlMUItextField';
import { gql, useMutation, useQuery } from '@apollo/client';
import { DELETE_FILTER_GROUP, FILTER_GROUP_ID, SAVE_SHIPMENT_FILTER_GROUP } from '../IdleGraphQl';
import MuiSwitch from '../../HOC/MUI/MUIswitch';
import MUIselect from '../../HOC/MUI/MUIselect';
import { MultipleAutocomplete } from '../../HOC/MUI/MultipleAutocomplete';
import { Globals } from '../../HOC/Classes/Globals';
import { setValidationError } from '../../HOC/CustomFunctions/setValidationError';
import CustomButton from '../../HOC/MUI/CustomButton';
import { useTheme } from '@emotion/react';
import { parseBool } from '../../HOC/CustomFunctions/urlParameters';
import { isBoolean } from 'lodash';

function IdleDialog(props) {
    const {
        setOpenDialog,
        openDialog,
        ID,
        setID,
        refetch,
    } = props;

    const [autocompleteValues, setAutocompleteValues] = useState({
        statusCode: [],
    });

    const [saveShipmentFilterGroup, { loading: saveShipmentFilterGroupLoading }] = useMutation(
        gql`
            ${SAVE_SHIPMENT_FILTER_GROUP.query}
        `
    );

    const [deleteFilterGroup, { loading: deleteFilterGroupLoading }] = useMutation(
        gql`
            ${DELETE_FILTER_GROUP.query}
        `
    );

    const canCreateGlobal = Globals.user.hasPermission("core.filter_group.create_public")
    const canUpdateGlobal = Globals.user.hasPermission("core.filter_group.update_public")
    const canDeleteGlobal = Globals.user.hasPermission("core.filter_group.delete_public")

    const [deleteDialog, setDeleteDialog] = useState(false);

    const {
        handleSubmit,
        formState,
        control,
        setError,
        setValue,
        watch,
        reset
    } = useForm();
    const { t } = useTranslation();
    const { errors } = formState;
    const theme = useTheme();

    const closeDialog = () => {
        reset()
        setID()
        setOpenDialog(false)
    };

    const closeDeleteDialog = () => {
        setDeleteDialog(false)
    };

    const SubmitDeleteRecord = () => {
        deleteFilterGroup({
            variables: {
                id: parseInt(ID)
            }
        }).then((data) => {
            setDeleteDialog(false)
            closeDialog()
            refetch()
        }).catch((err) => {
            console.log(err);
        })

    };

    const parseData = (data) => {
        return data;
    };

    const onSubmit = (data) => {
        saveShipmentFilterGroup({
            variables: {
                input: {
                    filterGroupInput: {
                        ...(ID && { id: parseInt(ID) }),
                        name: data.filterGroupInput.name,
                        global: data.filterGroupInput.global
                    },
                    listFilterInput: {
                        statusCode: data.statusCode,
                        ...(data.lastTransactionDate && {
                            lastTransactionDate: {
                                fromDays: parseInt(data.lastTransactionDate)
                            }
                        }),
                        ...(data.deliveredOrReturnedDate && {
                            deliveredOrReturnedDate: {
                                fromDays: parseInt(data.deliveredOrReturnedDate)
                            }
                        }),
                        ...(isBoolean(parseBool(data?.collected)) && {
                            collected: data.collected
                        }),
                    }
                }
            }
        }).then((data) => {
            closeDialog()
            refetch()
        }).catch(({ graphQLErrors }) => {
            setValidationError(graphQLErrors, setError, "filterGroupInput.");
            console.log(graphQLErrors);
        });
    };
    const { data } = useQuery(
        gql`
            ${FILTER_GROUP_ID.query}
        `,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
            nextFetchPolicy: "no-cache",
            skip: !ID,
            variables: {
                id: parseInt(ID),
            },
            onCompleted: (data) => {
                const filterGroup = data.filterGroup
                const filterGroupFilters = JSON.parse(filterGroup.filters)
                setAutocompleteValues({
                    statusCode: filterGroupFilters.statusCode,
                });
                setValue("filterGroupInput.name", filterGroup.name)
                setValue("filterGroupInput.global", !(!!filterGroup.user));
                filterGroupFilters.lastTransactionDate && setValue("lastTransactionDate", filterGroupFilters.lastTransactionDate.fromDays)
                filterGroupFilters.deliveredOrReturnedDate && setValue("deliveredOrReturnedDate", filterGroupFilters.deliveredOrReturnedDate.fromDays)
                setValue("collected", filterGroupFilters.collected)
            }
        },
    );

    const createdBy = data?.filterGroup.user

    const formCondition = ID && !(!!createdBy ? true : canUpdateGlobal)
    const formConditionDelete = ID && !(!!createdBy ? true : canDeleteGlobal)

    return (
        <CustomDialog
            title={ID ? t("update") : t("addNew")}
            fullWidth
            maxWidth="xs"
            onClose={closeDialog}
            content={
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack my={1} spacing={2}>
                        <Stack>
                            <ControlMUItextField
                                control={control}
                                errors={errors}
                                name={"filterGroupInput.name"}
                                label={t("name")}
                                rules={{ required: t("fieldIsRequired") }}
                                disabled={formCondition}
                            />
                        </Stack>
                        <Stack>
                            <MultipleAutocomplete
                                name={"statusCode"}
                                label={t("shipmentStatus")}
                                control={control}
                                errors={errors}
                                variables={{ input: { code: "SHP_REQUEST_STATUS" } }}
                                query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
                                parseData={(data) => parseData(data)}
                                multiple
                                valueKey="code"
                                defaultValue={autocompleteValues.statusCode}
                                disabled={formCondition}
                            />
                        </Stack>
                        <Stack>
                            <MUIselect
                                name={"collected"}
                                label={t("collection")}
                                control={control}
                                errors={errors}
                                data={[
                                    { key: t("notCollected"), value: false },
                                    { key: t("collected"), value: true },
                                ]}
                                disabled={formCondition}
                            />
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <ControlMUItextField
                                control={control}
                                errors={errors}
                                name={"lastTransactionDate"}
                                label={t("lastTransactionDateFrom")}
                                type={"number"}
                                sx={{
                                    [`& input[type=number]::-webkit-inner-spin-button`]: {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                    },
                                    [`& input[type=number]::-webkit-outer-spin-button`]: {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                    },
                                }}
                                disabled={formCondition}
                            />
                            <Typography paragraph mb={0}>{t("day")}</Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            <ControlMUItextField
                                control={control}
                                errors={errors}
                                name={"deliveredOrReturnedDate"}
                                label={t("deliveredCancelledFrom")}
                                type={"number"}
                                sx={{
                                    [`& input[type=number]::-webkit-inner-spin-button`]: {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                    },
                                    [`& input[type=number]::-webkit-outer-spin-button`]: {
                                        WebkitAppearance: "none",
                                        margin: 0,
                                    },
                                }}
                                disabled={formCondition}
                            />
                            <Typography paragraph mb={0}>{t("day")}</Typography>
                        </Stack>
                        <Stack justifyContent={"space-between"} direction={"row"} spacing={1}>
                            <CustomDialog
                                title={t("confirmation")}
                                fullWidth
                                maxWidth="xs"
                                onClose={closeDeleteDialog}
                                content={
                                    <Stack my={1} spacing={2}>
                                        <Stack>
                                            <Typography paragraph>
                                                {t("confirmationStatus")}
                                            </Typography>
                                        </Stack>
                                        <Stack justifyContent={"flex-end"} direction={"row"}>
                                            <CustomButton
                                                customcolor={theme.palette.error.main}
                                                disabled={
                                                    deleteFilterGroupLoading || formConditionDelete
                                                }
                                                onClick={SubmitDeleteRecord}
                                                variant="contained"
                                                size="medium"
                                                loading={deleteFilterGroupLoading}
                                            >
                                                {!deleteFilterGroupLoading && t("delete")}
                                            </CustomButton>
                                        </Stack>
                                    </Stack>
                                }
                                open={deleteDialog}
                            />
                            {canCreateGlobal && <MuiSwitch
                                edge="end"
                                name="filterGroupInput.global"
                                label={t("forAllUser")}
                                control={control}
                            />}
                            <Stack justifyContent={"flex-end"} direction={"row"} spacing={1}>
                                <CustomButton
                                    customcolor={theme.palette.success.main}
                                    type="submit"
                                    disabled={
                                        !(watch("statusCode") ||
                                            watch("collected") ||
                                            watch("lastTransactionDate") ||
                                            watch("deliveredOrReturnedDate")) ||
                                        formCondition ||
                                        saveShipmentFilterGroupLoading
                                    }
                                    variant="contained"
                                    size="medium"
                                    loading={saveShipmentFilterGroupLoading}
                                >
                                    {!saveShipmentFilterGroupLoading && t("save")}
                                </CustomButton>
                                {ID &&
                                    <CustomButton
                                        customcolor={theme.palette.error.main}
                                        disabled={
                                            deleteFilterGroupLoading || formConditionDelete
                                        }
                                        onClick={() => setDeleteDialog(true)}
                                        variant="contained"
                                        size="medium"
                                        loading={deleteFilterGroupLoading}
                                    >
                                        {!deleteFilterGroupLoading && t("delete")}
                                    </CustomButton>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </form>
            }
            open={openDialog}
        />
    )
}

export default IdleDialog