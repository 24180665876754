import React, { Fragment, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import * as gqlb from "gql-query-builder";
import { withRouter } from "react-router";
import clsx from "clsx";

import { urlParameters } from "../../HOC/CustomFunctions/urlParameters";
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

// import moment from "moment";
import CheckCrossIcons from "../../HOC/FunctionComponents/CheckCrossIcons";

const PREFIX = "SHIPMENT_STICKY_PRINT";

export const classes = {
  hideSenderPhone: `${PREFIX}-hideSenderPhone`,
  printSticky: `${PREFIX}-printSticky`,
  flexBetween: `${PREFIX}-flexBetween`,
  flex: `${PREFIX}-flex`,
  stickyCode: `${PREFIX}-stickyCode`,
  customHeight16: `${PREFIX}-customHeight16`,
  customHeight12: `${PREFIX}-customHeight12`,
  customHeight5: `${PREFIX}-customHeight5`,
  customHeight6: `${PREFIX}-customHeight6`,
  customHeight13: `${PREFIX}-customHeight13`,
  customHeight8: `${PREFIX}-customHeight8`,
  bold: `${PREFIX}-bold`,
  paddingX: `${PREFIX}-paddingX`,
  tableHeader: `${PREFIX}-tableHeader`,
  borderRadius: `${PREFIX}-borderRadius`,
  fs12: `${PREFIX}-fs12`,
  borderRight: `${PREFIX}-borderRight`,
  borderBottom: `${PREFIX}-borderBottom`,
  width50: `${PREFIX}-width50`,
  tableCell: `${PREFIX}-tableCell`,
  tableCellAddress: `${PREFIX}-tableCellAddress`,
  flexChild: `${PREFIX}-flexChild`,
  details: `${PREFIX}-details`,
  width20: `${PREFIX}-width20`,
  widthInherit: `${PREFIX}-widthInherit`,
  borderLeft: `${PREFIX}-borderLeft`,
};
export const Root = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  [`& .${classes.hideSenderPhone}`]: {
    display: "none !important",
  },
  [`& .${classes.printSticky}`]: {
    // border: "1px solid #000",
    pageBreakAfter: "always",
    position: "relative",
    pageBreakInside: "avoid",
    width: "17.5cm",
    height: "10cm",
    maxHeight: "10cm",
    overflow: "hidden",
    fontSize: 11,
    color: "black",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "0.8cm",
  },

  [`& .${classes.flexBetween}`]: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
  },
  [`& .${classes.flex}`]: {
    display: 'flex',
    alignItems: "center",
  },

  [`& .${classes.stickyCode}`]: {
    fontFamily: "'Libre Barcode 39 Text'",
    // height: "100px",
    overflow: "hidden",
    fontSize: 50,
  },

  [`& .${classes.customHeight16}`]: {
    height: "16%",
  },
  [`& .${classes.customHeight12}`]: {
    height: "12%"
  },
  [`& .${classes.customHeight5}`]: {
    height: "5%"
  },
  [`& .${classes.customHeight6}`]: {
    height: "6%"
  },
  [`& .${classes.customHeight13}`]: {
    height: "13%"
  },
  [`& .${classes.customHeight8}`]: {
    height: "8%"
  },

  [`& .${classes.bold}`]: {
    fontWeight: 700,
  },

  [`& .${classes.paddingX}`]: {
    padding: theme.spacing(0, 1),
  },

  [`& .${classes.borderRadius}`]: {
    borderRadius: "10px",
    border: "1px solid black",
  },

  [`& .${classes.tableHeader}`]: {
    backgroundColor: "#ddd",
    padding: theme.spacing(0.5, 2),
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.fs12}`]: {
    fontSize: "12px"
  },
  [`& .${classes.borderRight}`]: {
    borderRight: "1px solid black",
    textTransform: "capitalize",
    height: "100%",
  },
  [`& .${classes.borderLeft}`]: {
    borderLeft: "1px solid black",
    textTransform: "capitalize",
    height: "100%",
  },
  [`& .${classes.borderBottom}`]: {
    borderBottom: "1px solid black",
  },
  [`& .${classes.width50}`]: {
    width: "50%",
  },
  [`& .${classes.width20}`]: {
    width: "70px",
    minWidth: "70px",
    // maxWidth: "77px",
  },

  [`& .${classes.tableCell}`]: {
    display: "flex",
    padding: '0 6px',
    // border: "1px solid black",
    // borderBlockEnd: "none",
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },

  [`& .${classes.tableCellAddress}`]: {
    display: "flex",
    padding: '0 6px',
    // border: "1px solid black",
    // borderBlockEnd: "none",
    overflow: "hidden",
    // flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.flexChild}`]: {
    display: "flex",
    flex: "1 1 auto",
    minHeight: 0,
  },
  [`& .${classes.details}`]: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
    minHeight: 0,
  },
  [`& .${classes.widthInherit}`]: {
    width: "inherit",
    // whiteSpace: "pre-line",
    color: "black",
    fontSize: 11,
    margin: 0,
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    display: "-webkit-box !important"
  },
}));
const organizationQuery = {
  operation: "organization",
  fields: ["reportFooter"],
  variables: {},
};
const listShipmentsFields = [
  {
    data: [
      "code",
      "recipientName",
      "recipientAddress",
      "recipientMobile",
      "recipientPhone",
      "description",
      "weight",
      "piecesCount",
      "totalAmount",
      "senderName",
      "senderPhone",
      "senderMobile",
      "refNumber",
      "notes",
      "date",
      {
        type: ["name", "code"],
      },
      {
        originBranch: ["name"],
      },
      {
        customer: [
          "code",
          {
            operation: `isMobileHidden(code: WAYBILL)`,
            fields: [],
            variables: {
            },
          },
        ],
      },
      {
        openable: ["code"],
      },

      {
        recipientZone: ["name"],
      },
      {
        recipientSubzone: ["name"],
      },
      {
        senderZone: ["name"],
      },
      {
        senderSubzone: ["name"],
      },
    ],
  },
];
const barcodeBatch = [
  "id",
  { barcodes: ["id", "code"] },
  {
    customer: [
      "code",
      "name",
      {
        operation: `isMobileHidden(code: WAYBILL)`,
        fields: [],
        variables: {
        },
      },
      {
        zone: ["id", "code", "name"]
      },
      {
        subzone: ["id", "code", "name"]
      },
      "phone",
      "mobile"
    ],
  },
];
const queryBuilder = (ids) => {
  const queryNumber = Math.ceil(ids.length / 100);
  const queryArray = [organizationQuery];
  for (let index = 0; index < queryNumber; index++) {
    const id = ids.slice(100 * index, 100 * (index + 1));
    const initialQuery = {
      operation: `list${index}:listShipments(first:100,input:{id:[${id}]})`,
      fields: listShipmentsFields,
      variables: {},
    };
    queryArray.push(initialQuery);
  }
  return queryArray;
};
const queryBuilderCode = (ids) => {
  const queryArray = [organizationQuery];
  const initialQuery = {
    operation: `listCode:barcodeBatch(id:${ids[0]})`,
    fields: barcodeBatch,
    variables: {},
  };
  queryArray.push(initialQuery);
  return queryArray;
};
export const SHIPPING_SETTING = gqlb.query({
  operation: "shippingSettings",
  fields: [{ localCurrency: ["name"] }],
  variables: {},
});

// const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm");

const ShipmentStickyPrint = (props) => {
  const id = props.match.params.id.split(",").map(Number);
  const isBatch = props.location.pathname.startsWith('/batch');
  const urlQuery = urlParameters(window.location.search);

  const { t, i18n } = useTranslation();

  const queryBody = isBatch ? queryBuilderCode(id) : queryBuilder(id);
  // const [hideSenderPhone, setHideSenderPhone] = useState(true);

  const SHIPMENT_QUERY = gqlb.query(queryBody);

  const token = urlQuery["token"];
  const lang = urlQuery["lang"];

  const { data: settings, loading: settingsLoad } = useQuery(
    gql`
      ${SHIPPING_SETTING.query}
    `,
    {
      nextFetchPolicy: "no-cache",
      fetchPolicy: "no-cache",
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
    }
  );

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    return () => { };
  }, [i18n, lang, settings]);

  const { data, loading } = useQuery(
    gql`
      ${SHIPMENT_QUERY.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      skip: !id,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        input: {
          id,
        },
      },
      ...(token && {
        context: {
          headers: {
            authorization: `Bearer ${token}`,
            ...(lang && { "Content-Language": lang }),
          },
        },
      }),
      onCompleted: () => {
        document.fonts.onloadingdone = function (fontFaceSetEvent) {
          window.print();
          document.fonts.onloadingdone = null;
        };
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  // const currency = ` ${settings?.shippingSettings?.localCurrency?.name} ` ?? "";
  // const reportFooter = data?.organization?.reportFooter?.split("\n")
  // const footer = reportFooter?.toString().replace(/,/g, " | ");

  let parsedData = [];
  if (data?.list0) {
    let concatData = [];
    for (const key in data) {
      if (key.startsWith("list")) {
        const listData = data[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }
  // let customer

  // if (data?.listCode) {
  //   customer = data?.listCode.customer
  //   let concatData = [...data?.listCode.barcodes];
  //   parsedData = concatData;
  // }


  const validData = parsedData.length > 0;

  // const getSenderMobiles = (shipment) => {
  //   return customer ? customer?.mobile && customer?.phone
  //     ? `${customer?.mobile} - ${customer?.phone}`
  //     : customer?.mobile ??
  //     customer?.phone :
  //     shipment?.senderMobile && shipment?.senderPhone
  //       ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
  //       : shipment?.senderMobile ??
  //       shipment?.senderPhone
  // }

  return (
    <Fragment>
      <link
        rel="stylesheet"
        type="text/css"
        href={process.env.PUBLIC_URL + "/print-sticky17X10.css"}
      />
      {loading || (!loading && !validData) || settingsLoad ? (
        <>
          <EmptyTableMessage
            loading={loading}
            message={t("noShipmentWithCode")}
          />

          {/* <FullScreenLoading minHeight="25%" /> */}
        </>
      ) : (
        parsedData.map((shipment, index) => {
          const rtsShipment = shipment?.type?.code === "RTS";
          return (
            <Root key={index}>

              <div
                className={clsx({
                  [classes.printSticky]: !loading && validData,
                })}
                style={{ lineHeight: "1.2", paddingLeft: "7.3cm", paddingRight: "0.6cm" }}
              >
                <div style={{ justifyContent: "center" }} className={clsx(
                  classes.flex,
                  classes.paddingX,
                  classes.stickyCode,
                )}>
                  *{shipment.code}*
                </div>

                <Box className={clsx(
                  classes.tableHeader,
                  classes.borderRadius,
                  classes.flexBetween,
                )}>
                  <Box className={classes.flex}>
                    <Box
                      component="span"
                      sx={{ fontSize: "0.8rem", fontWeight: 500, mx: 0.5 }}
                    >
                      {rtsShipment ? 0 : shipment?.totalAmount}
                    </Box>
                    <Typography className={classes.fs12} sx={{ mr: 1 }}>
                      $
                    </Typography>
                  </Box>
                  <Typography className={classes.fs12}>
                    {shipment?.type?.name}
                  </Typography>
                  <Box className={classes.flex}>
                    <Typography className={classes.fs12} sx={{ mr: 1 }}>
                      {t("packageOpen")}
                    </Typography>
                    <div
                      style={{ alignItems: "end", fontSize: 10 }}
                    >
                      {!isBatch && (
                        <CheckCrossIcons
                          sx={{ fontSize: "18px !important" }}
                          active={shipment?.openable?.code === "Y"}
                        />
                      )}
                    </div>
                  </Box>
                  <Box className={classes.flex}>
                    <Typography className={classes.fs12} sx={{ mr: 1 }}>
                      {t("pieces")} :
                    </Typography>
                    <div>
                      {shipment?.piecesCount}
                    </div>
                  </Box>
                </Box>
                <Box className={clsx(
                  classes.borderRadius,
                  classes.details,
                )}>
                  <div className={clsx(classes.borderBottom, classes.flexChild)}>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("refNumber")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.refNumber}
                      </div>
                    </div>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.borderLeft, classes.tableCell, classes.width20)}>
                        {t("branch")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.originBranch?.name}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.borderBottom, classes.flexChild)}>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("phone")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.recipientMobile && shipment?.recipientPhone
                          ? `${shipment?.recipientMobile} - ${shipment?.recipientPhone}`
                          : shipment?.recipientMobile ?? shipment?.recipientPhone}
                      </div>
                    </div>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.borderLeft, classes.tableCell, classes.width20)}>
                        {t("storeName")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {!isBatch &&
                          shipment?.customer?.code + "-" + shipment?.senderName}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.borderBottom, classes.flexChild)}>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("date")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.date?.split(" ")[0]}
                      </div>
                    </div>
                    <div className={clsx(classes.width50, classes.flex)}>
                      <div className={clsx(classes.borderRight, classes.borderLeft, classes.tableCell, classes.width20)}>
                        {t("storeNumber")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.senderMobile && shipment?.senderPhone
                          ? `${shipment?.senderMobile} - ${shipment?.senderPhone}`
                          : shipment?.senderMobile ?? shipment?.senderPhone}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.borderBottom, classes.flexChild)}>
                    <div className={classes.flex}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("destination")}
                      </div>
                      <div className={clsx(classes.tableCellAddress, classes.widthInherit)}>
                        <div >
                          {shipment?.recipientZone
                            ? shipment?.recipientZone?.name +
                            " - " +
                            shipment?.recipientSubzone?.name
                            : ""}
                        </div>
                        <div> {t("address") + " : " + shipment?.recipientAddress}</div>
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.borderBottom, classes.flexChild)}>
                    <div className={classes.flex} style={{ width: "100%" }}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("notes")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.notes}
                      </div>
                    </div>
                  </div>
                  <div className={clsx(classes.flexChild, classes.customHeight13)}>
                    <div className={classes.flex} style={{ width: "100%" }}>
                      <div className={clsx(classes.borderRight, classes.tableCell, classes.width20)}>
                        {t("description")}
                      </div>
                      <div className={clsx(classes.tableCell, classes.widthInherit)}>
                        {shipment?.description}
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </Root>
          )
        })
      )}
    </Fragment>
  );
};

export default withRouter(ShipmentStickyPrint);
