import {
  Button,
  Grid,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Clear, Search } from "@mui/icons-material";
import clsx from "clsx";

import moment from "moment";
import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import LookupDropdown from "../Component/HOC/CustomComponents/LookupDropdown";
import { Can } from "../Component/HOC/CustomComponents/Secured";
import { lookupCodeIsValid } from "../Component/HOC/CustomFunctions/lookupCodeIsValid";
import { windowUrl } from "../Component/HOC/CustomFunctions/pushUrl";
import { urlParameters } from "../Component/HOC/CustomFunctions/urlParameters";
import ControlMUItextField from "../Component/HOC/MUI/ControlMUItextField";
import { CustomAutocomplete } from "../Component/HOC/MUI/CustomAutocomplete";
import MUIDateRangeCustom from "../Component/HOC/MUI/MUIDateRangeCustom";
import MUItextField from "../Component/HOC/MUI/MUItextField";
import { MultipleAutocomplete } from "../Component/HOC/MUI/MultipleAutocomplete";
import MUIselect from "../Component/HOC/MUI/MUIselect";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_LOOKUP_ENTRIES_DROPDOWN,
  LIST_SHIPPING_SERVICES_DROPDOWN,
  LIST_ZONES_DROPDOWN,
  LIST_WAREHOUSE_DROPDOWN,
  LIST_USERS_DROPDOWN,
} from "../GlobalsQuery/ListDropdown/ListDropdown";
import { dateFormat } from "../helpers/dateFunctions";
import MUIDate from "../Component/HOC/MUI/MUIDate";

const PREFIX = "shipmentListFilters";

const classes = {
  button: `${PREFIX}-button`,
  btnMargin: `${PREFIX}-btnMargin`,
  searchForm: `${PREFIX}-searchForm`,
  searchField: `${PREFIX}-searchField`,
  overlay: `${PREFIX}-overlay`,
  searchPadding: `${PREFIX}-searchPadding`,
};

const Root = styled(Grid)(({ theme }) => ({
  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
  },

  [`& .${classes.btnMargin}`]: {
    width: "100%",
  },

  [`&.${classes.searchForm}`]: {
    overflowY: "scroll",
    width: "100%",
    margin: 0,
    height: "100vh",
  },

  [`& .${classes.searchField}`]: {
    padding: theme.spacing(1, 1, 0, 1),
    position: "relative",
    overflow: "hidden",
  },

  [`& .${classes.overlay}`]: {
    width: "100%",
    height: "100%",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    zIndex: 2,
  },

  //////////////////////Drawer///////////////////
  [`& .${classes.searchPadding}`]: {
    padding: theme.spacing(1),
  },
}));

// const useListShipmentsFilters = ({ onSubmitFunc, rowsPerPage, resetPage }) => {
const FiltersForm = ({
  loading,
  resetPage,
  onSubmitFunc,
  dateValue,
  queryVariables,
  rowsPerPage,
  preventPushUrl,
  initData,
  initLastTransaction,
  pathname,
  removeFiltersFields,
  addFiltersFields,
  custody,
  clearCheckBox,
  statusDataHandler,
  initDlvrBefore,
  inWarehouse,
  removeResetDate,
  filtersAllowed,
  removeSearch,
  allowCode,
  customerType,
  filterLoad
}) => {
  const { handleSubmit, register, errors, watch, control, setValue, setError } =
    useForm();
  // filters={["date","dlvAtemp","dlvryBefore","status","warehouseSection","branch","originBranch","custm","dlvAgent","zone","subZone","refNumber","service","deliveryType","returnType","packageType","paymentType","collection",]}
  const history = useHistory();


  const urlQuery = urlParameters(window.location.search);

  const validUrlParameters = Object.keys(urlQuery).length !== 0;

  const initFromDate = urlQuery["fromDate"]
    ? urlQuery["fromDate"]
    : initData?.["fromDate"] ?? null;
  const initToDate = urlQuery["toDate"]
    ? urlQuery["toDate"]
    : initData?.["toDate"] ?? null;

  const initLastTransactionFromDate = urlQuery["lastTransactionFromDate"]
    ? urlQuery["lastTransactionFromDate"]
    : initLastTransaction?.["lastTransactionFromDate"] ?? null;
  const initLastTransactionToDate = urlQuery["lastTransactionToDate"]
    ? urlQuery["lastTransactionToDate"]
    : initLastTransaction?.["lastTransactionToDate"] ?? null;

  const [dateRange, setDateRange] = useState([initFromDate, initToDate]);
  const [lastTransactionDate, setLastTransactionDate] = useState([
    initLastTransactionFromDate,
    initLastTransactionToDate,
  ]);
  const [deliveryBeforeData, setDeliveryBefore] = useState(
    dateFormat(initDlvrBefore) ?? null
  );

  const fromDate = moment(dateRange[0]).locale("en").format("YYYY-MM-DD");
  const toDate = moment(dateRange[1]).locale("en").format("YYYY-MM-DD");
  const lastTransactionFromDate = moment(lastTransactionDate[0])
    .locale("en")
    .format("YYYY-MM-DD");
  const lastTransactionToDate = moment(lastTransactionDate[1])
    .locale("en")
    .format("YYYY-MM-DD");
  const deliveryBefore = moment(deliveryBeforeData)
    .locale("en")
    .format("YYYY-MM-DD");
  const initSearch = () => {
    let searchParameters = {
      ...(dateRange[0] && { fromDate }),
      ...(dateRange[1] && { toDate }),
      ...(lastTransactionDate[0] && { lastTransactionFromDate }),
      ...(lastTransactionDate[1] && { lastTransactionToDate }),
      ...(deliveryBeforeData && { deliveryBefore }),
      refetch: true,
    };
    if (validUrlParameters) {
      if (urlQuery["search"]) {
        urlQuery["search"] = urlQuery["search"].toString().trim();
      }
      if (urlQuery["code"]) urlQuery["code"] = urlQuery["code"]?.toString();
      if (urlQuery["refNumber"])
        urlQuery["refNumber"] = urlQuery["refNumber"].toString().trim();

      const searchParams = { ...urlQuery };
      delete searchParams["rowsPerPage"];

      if (urlQuery["statusCode"])
        searchParams["statusCode"] = searchParams["statusCode"].split(",");

      if (
        urlQuery["recipientZoneId"] &&
        urlQuery["recipientZoneId"].length > 1
      ) {
        searchParams["recipientZoneId"] = searchParams["recipientZoneId"]
          .split(",")
          .map((ele) => {
            return parseInt(ele);
          });
      }
      if (
        urlQuery["recipientSubzoneId"] &&
        urlQuery["recipientSubzoneId"].length > 1
      ) {
        searchParams["recipientSubzoneId"] = searchParams["recipientSubzoneId"]
          .split(",")
          .map((ele) => {
            return parseInt(ele);
          });
      }

      if (
        urlQuery["senderZoneId"] &&
        urlQuery["senderZoneId"].length > 1
      ) {
        searchParams["senderZoneId"] = searchParams["senderZoneId"]
          .split(",")
          .map((ele) => {
            return parseInt(ele);
          });
      }
      if (
        urlQuery["senderSubzoneId"] &&
        urlQuery["senderSubzoneId"].length > 1
      ) {
        searchParams["senderSubzoneId"] = searchParams["senderSubzoneId"]
          .split(",")
          .map((ele) => {
            return parseInt(ele);
          });
      }

      if (searchParams["search"])
        searchParams["search"] = searchParams["search"]
          .split(",")
          .filter((i) => i.trim() !== "")
          .map((i) => i.trim());

      if (searchParams["refNumber"])
        searchParams["refNumber"] = searchParams["refNumber"]
          .split(",")
          .filter((i) => i.trim() !== "")
          .map((i) => i.trim());

      // if (searchParams["inDeliveryAgentCustody"])
      //   searchParams["inDeliveryAgentCustody"] = {
      //     deliveryAgentId: searchParams["inDeliveryAgentCustody"],
      //   };


      searchParameters = searchParams;
    }
    if (custody) {
      for (const key in searchParameters) {
        if (key === "lastDeliveryAgentId") {
          delete searchParameters[key];
        }
      }
    }
    if (searchParameters.fromDate || searchParameters.toDate) {
      const date = {
        ...(searchParameters?.fromDate && { fromDate: searchParameters?.fromDate }),
        ...(searchParameters?.toDate && { toDate: searchParameters?.toDate }),
      }
      delete searchParameters["fromDate"]
      delete searchParameters["toDate"]
      searchParameters.date = date
    }
    if (searchParameters.lastTransactionToDate || searchParameters.lastTransactionFromDate) {
      const lastTransactionDate = {
        ...(searchParameters?.lastTransactionFromDate && { fromDate: searchParameters?.lastTransactionFromDate }),
        ...(searchParameters?.lastTransactionToDate && { toDate: searchParameters?.lastTransactionToDate}),
      }
      delete searchParameters["lastTransactionToDate"]
      delete searchParameters["lastTransactionFromDate"]
      searchParameters.lastTransactionDate = lastTransactionDate
    }
    return searchParameters;
  };
  const [search, setSearch] = useState(initSearch());

  const pushUrlSearch = (param) => {
    if (preventPushUrl) return;
    if (urlQuery["idleSearch"] || urlQuery["idleSearch"] === '0') {
      param.idleSearch = urlQuery["idleSearch"]
    }
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");

    const url = history.createHref({
      pathname: `/admin/${pathname}`,
      search: "?" + queryString,
    });
    windowUrl(url);
  };

  const dateValueWithCode = () => {
    return {
      ...(Boolean(!watch("search")) && {
        fromDate,
        toDate,
      }),
    };
  };

  useEffect(() => {
    dateValue && dateValue(dateValueWithCode());
    const inputSearch = { ...search };
    queryVariables(inputSearch);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const [filterLoading, setFilterLoading] = useState(true);
  const onSubmit = (data) => {
    // if (data.statusCode[0] === "RTS") {
    //   const filteredData = data.statusCode.filter(code => code !== "RTS")
    //   // console.log(filteredData);
    // }
    onSubmitFunc && onSubmitFunc(data);
    setFilterLoading(false)
    const recipientZoneId =
      data?.recipientZoneId?.length > 0 &&
      data?.recipientZoneId?.map(function (str) {
        return parseInt(str);
      });
    const recipientSubzoneId =
      data?.recipientSubzoneId?.length > 0 &&
      data?.recipientSubzoneId?.map(function (str) {
        return parseInt(str);
      });
    const senderZoneId =
      data?.senderZoneId?.length > 0 &&
      data?.senderZoneId?.map(function (str) {
        return parseInt(str);
      });
    const senderSubzoneId =
      data?.senderSubzoneId?.length > 0 &&
      data?.senderSubzoneId?.map(function (str) {
        return parseInt(str);
      });

    clearCheckBox && clearCheckBox();

    // moment.locale("en");
    let handledData = {
      ...(dateRange[0] && {
        fromDate,
      }),
      ...(dateRange[1] && {
        toDate,
      }),
      ...(lastTransactionDate[0] && {
        lastTransactionFromDate: lastTransactionFromDate,
      }),
      ...(lastTransactionDate[1] && {
        lastTransactionToDate: lastTransactionToDate,
      }),
      statusCode: data.statusCode,
      code: data?.code?.toString(),
      deliveryTypeCode: data.deliveryTypeCode,
      returnTypeCode: data.returnTypeCode,
      branchId: data.branchId,
      originBranchId: data.originBranchId,
      sectionId: data.sectionId,
      customerId: data.customerId,
      // ...(custody
      //   ? {
      //     inDeliveryAgentCustody: {
      //       ...(data.lastDeliveryAgentId && {
      //         deliveryAgentId: data.lastDeliveryAgentId,
      //       }),
      //     },
      //   }
      //   : { lastDeliveryAgentId: data.lastDeliveryAgentId }),
      lastDeliveryAgentId: data.lastDeliveryAgentId,
      serviceId: data.serviceId,
      ...(recipientZoneId && {
        recipientZoneId: recipientZoneId,
      }),
      ...(recipientSubzoneId && {
        recipientSubzoneId: recipientSubzoneId,
      }),
      ...(senderZoneId && {
        senderZoneId: senderZoneId,
      }),
      ...(senderSubzoneId && {
        senderSubzoneId: senderSubzoneId,
      }),
      search: data?.search?.toString().trim(),
      refNumber: data?.refNumber?.toString(),
      typeCode: data.typeCode,
      paymentTypeCode: data.paymentTypeCode,
      customerTypeCode: data.customerTypeCode,
      collected: data.collected,
      paid: data.paid,
      new: data.new,
      userId: data.userId,
      cancelled: data.cancelled,
      forwarded: data.forwarded,
      hasProducts: data.hasProducts,
      deliveryBefore: dateFormat(data.deliveryBefore),
    };

    for (const key in handledData) {
      if ([undefined, "", null].includes(handledData[key])) {
        delete handledData[key];
      }
    }

    resetPage();
    const newUrlParameters = { ...handledData };
    // if (newUrlParameters?.inDeliveryAgentCustody?.deliveryAgentId) {
    //   newUrlParameters["inDeliveryAgentCustody"] =
    //     newUrlParameters.inDeliveryAgentCustody.deliveryAgentId;
    // } else {
    //   delete newUrlParameters["inDeliveryAgentCustody"];
    // }

    pushUrlSearch({
      ...newUrlParameters,
      rowsPerPage,
      page: 0,
    });

    // if (data.search) {
    //   handledData = {
    //     search: data.search.toString(),
    //   };
    // }

    if (handledData.search) {
      handledData.search = handledData.search
        .split(",")
        .filter((i) => i.trim() !== "")
        .map((i) => i.trim());
    }

    if (handledData.code) {
      handledData = {
        code: data.code.toString(),
        // page: 0,
      };
    }

    if (handledData.refNumber) {
      handledData.refNumber = handledData.refNumber
        .split(",")
        .filter((i) => i.trim() !== "")
        .map((i) => i.trim());
    }
    if (custody) {
      for (const key in handledData) {
        if (key === "lastDeliveryAgentId") {
          delete handledData[key];
        }
      }
    }
    if (handledData.fromDate || handledData.toDate) {
      const date = {
        ...(handledData?.fromDate && { fromDate: handledData?.fromDate }),
        ...(handledData?.toDate && { toDate: handledData?.toDate }),
      }
      delete handledData["fromDate"]
      delete handledData["toDate"]
      handledData.date = date
    }
    if (handledData.lastTransactionToDate || handledData.lastTransactionFromDate) {
      const lastTransactionDate = {
        ...(handledData?.lastTransactionFromDate && { fromDate: handledData?.lastTransactionFromDate }),
        ...(handledData?.lastTransactionToDate && { toDate: handledData?.lastTransactionToDate}),
      }
      delete handledData["lastTransactionToDate"]
      delete handledData["lastTransactionFromDate"]
      handledData.lastTransactionDate = lastTransactionDate
    }
  
    setSearch((prev) => ({
      // ...prev,
      ...handledData,
      ...(custody
        && {
        inDeliveryAgentCustody: {
          ...(data.lastDeliveryAgentId && {
            deliveryAgentId: data.lastDeliveryAgentId,
          }),
        },
      }),
      refetch: !prev.refetch,
    }));
  };

  const resetLastTransactionDate = () => {
    setLastTransactionDate([null, null]);
  };
  const resetDeliveryBeforeDate = () => {
    setDeliveryBefore(null);
  };

  // const clearCode = () => {
  //   setValue("search", "");

  //   resetPage();
  //   resetLastTransactionDate();
  //   setSearch((prev) => ({
  //     ...search,
  //     // ...(dateRange[0] && { fromDate }),
  //     // ...(dateRange[1] && { toDate }),
  //     // refetch: !prev.refetch,
  //   }));
  //   // delete search.refetch;
  //   const newUrlParameters = { ...search };
  //   if (newUrlParameters?.inDeliveryAgentCustody?.deliveryAgentId) {
  //     newUrlParameters["inDeliveryAgentCustody"] =
  //       newUrlParameters.inDeliveryAgentCustody.deliveryAgentId;
  //   } else {
  //     delete newUrlParameters["inDeliveryAgentCustody"];
  //   }
  //   pushUrlSearch({
  //     ...newUrlParameters,
  //     rowsPerPage,
  //     page: 0,
  //   });
  // };

  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    originBranch: null,
    wareHouseSection: null,
    customer: null,
    deliveryAgent: null,
    userId: null,
    service: null,
    recipientZone: [],
    recipientSubzone: [],
    statusCode: [],
    senderZoneId: [],
    senderSubzoneId: [],
  });

  const lookupComplete = (data, name) => {
    const validData = lookupCodeIsValid(data, urlQuery[name]);
    if (validData) {
      setValue(name, urlQuery[name]);
    }
  };

  useEffect(() => {
    urlQuery["search"] && setValue("search", urlQuery["search"].toString());
    urlQuery["code"] && setValue("code", urlQuery["code"].toString());
    urlQuery["refNumber"] &&
      setValue("refNumber", urlQuery["refNumber"].toString());
    urlQuery["collected"] !== undefined &&
      setValue("collected", urlQuery["collected"]);
    urlQuery["paid"] !== undefined && setValue("paid", urlQuery["paid"]);
    urlQuery["new"] !== undefined && setValue("new", urlQuery["new"]);
    urlQuery["cancelled"] !== undefined && setValue("cancelled", urlQuery["cancelled"]);
    urlQuery["forwarded"] !== undefined && setValue("forwarded", urlQuery["forwarded"]);
    urlQuery["hasProducts"] !== undefined && setValue("hasProducts", urlQuery["hasProducts"]);
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["statusCode"] && {
        statusCode: urlQuery["statusCode"].split(","),
      }),
    }));

    let recipientZoneId;
    if (urlQuery["recipientZoneId"]) {
      if (urlQuery["recipientZoneId"].length > 1) {
        recipientZoneId = urlQuery["recipientZoneId"].split(",").map((ele) => {
          return parseInt(ele);
        });
      } else {
        recipientZoneId = [urlQuery["recipientZoneId"]];
      }
    }

    let recipientSubzoneId;
    if (urlQuery["recipientSubzoneId"]) {
      if (urlQuery["recipientSubzoneId"].length > 1) {
        recipientSubzoneId = urlQuery["recipientSubzoneId"]
          .split(",")
          .map((ele) => {
            return parseInt(ele);
          });
      } else {
        recipientSubzoneId = [urlQuery["recipientSubzoneId"]];
      }
    }

    let senderZoneId;
    if (urlQuery["senderZoneId"]) {
      if (urlQuery["senderZoneId"].length > 1) {
        senderZoneId = urlQuery["senderZoneId"].split(",").map((ele) => {
          return parseInt(ele);
        });
      } else {
        senderZoneId = [urlQuery["senderZoneId"]];
      }
    }

    let senderSubzoneId;
    if (urlQuery["senderSubzoneId"]) {
      if (urlQuery["senderSubzoneId"].length > 1) {
        senderSubzoneId = urlQuery["senderSubzoneId"]
          .split(",")
          .map((ele) => {
            return parseInt(ele);
          });
      } else {
        senderSubzoneId = [urlQuery["senderSubzoneId"]];
      }
    }
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["recipientZoneId"] && {
        recipientZone: recipientZoneId,
      }),
    }));

    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["recipientSubzoneId"] && {
        recipientSubzone: recipientSubzoneId,
      }),
    }));
    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["senderZoneId"] && {
        senderZoneId: senderZoneId,
      }),
    }));

    setAutocompleteValues((prev) => ({
      ...prev,
      ...(urlQuery["senderSubzoneId"] && {
        senderSubzoneId: senderSubzoneId,
      }),
    }));

    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();
  const parseData = (data) => {
    // console.log(data);
    //   const hasRTS = data?.some(item => item?.code === "RTS");

    // if (hasRTS) {

    //   const filteredData = data?.filter(item => item?.code !== "RTS");

    //   // Add new objects with "RTS" code to the filteredData
    //   filteredData.push(
    //     { id: null, name: 'Status test', code: 'RTST', inwarehouse: true },
    //     { id: null, name: 'Status test2', code: 'RTSF', inwarehouse: false }
    //   );
    //   return filteredData;
    // } else {
    return data;
    // }
  };

  const handelAutocompleteDefaultValue = (data, id, fieldName) => {
    const defaultValue = data[Object.keys(data)[0]].find(
      (i) => i.id === parseInt(id)
    );
    defaultValue &&
      setAutocompleteValues((prev) => ({
        ...prev,
        [fieldName]: defaultValue,
      }));
  };

  const inputSubzone = watch("recipientZoneId") ?? watch("recipientZoneId");
  const inputSenderZone = watch("senderZoneId") ?? watch("senderZoneId");
  const resetDate = () => {
    setDateRange([null, null]);
  };

  const filtersComponents = [
    {
      id: 1,
      code: "date",
      component: (
        <MUIDateRangeCustom
          value={dateRange}
          onChangeValue={(value) => setDateRange(value)}
          resetDate={removeResetDate ? false : resetDate}
        />
      ),
    },
    {
      id: 2,
      code: "dlvAtemp",
      component: (
        <MUIDateRangeCustom
          startText={t("lastTransactionDate")}
          value={lastTransactionDate}
          onChangeValue={(value) => setLastTransactionDate(value)}
          resetDate={resetLastTransactionDate}
        />
      ),
    },
    {
      id: 3,
      code: "dlvryBefore",
      component: (
        // <MUIDateRangeCustom
        //   startText={t("redeliveryDate")}
        //   value={deliveryBeforeData}
        //   onChangeValue={(value) => setDeliveryBefore(value)}
        //   resetDate={resettestDate}
        // />
        <MUIDate
          name="deliveryBefore"
          label={t("redeliveryDate")}
          control={control}
          value={deliveryBeforeData}
          onChange={(date) => setDeliveryBefore(date)}
          readOnly={false}
          resetDate={resetLastTransactionDate}
          onError={(resone, value) => {
            setError("deliveryBefore", { message: resone });
          }}
          InputProps={{
            ...{
              endAdornment: (
                <IconButton
                  size="small"
                  key={"deliveryBefore"}
                  onClick={() => {
                    resetDeliveryBeforeDate();
                    setValue("deliveryBefore", "");
                  }}
                >
                  <Clear size="small" />
                </IconButton>
              ),
            },
          }}
        />
      ),
    },
    {
      id: 4,
      code: "status",
      component: (
        <MultipleAutocomplete
          name={"statusCode"}
          label={t("shipmentStatus")}
          skip={filterLoad && filterLoading}
          control={control}
          errors={errors}
          variables={{ input: { code: "SHP_REQUEST_STATUS" } }}
          query={LIST_LOOKUP_ENTRIES_DROPDOWN.query}
          parseData={(data) =>
            statusDataHandler ? statusDataHandler(data) : parseData(data)
          }
          multiple
          valueKey="code"
          defaultValue={autocompleteValues.statusCode}
        />
      ),
    },
    {
      id: 5,
      code: "warehouseSection",
      component: (
        <CustomAutocomplete
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          name={"sectionId"}
          label={t("wareHouse")}
          parseData={(data) => parseData(data)}
          query={LIST_WAREHOUSE_DROPDOWN.query}
          onCompleted={(data) =>
            handelAutocompleteDefaultValue(
              data,
              urlQuery["sectionId"],
              "wareHouseSection"
            )
          }
          defaultValue={autocompleteValues.wareHouseSection}
        />
      ),
    },
    {
      id: 6,
      code: "branch",
      Permissions: "shipping.shipment.filter_by_branch",
      component: (
        <CustomAutocomplete
          name={"branchId"}
          label={t("currentBranch")}
          skip={filterLoad && filterLoading}
          control={control}
          errors={errors}
          parseData={(data) => parseData(data)}
          onCompleted={(data) =>
            handelAutocompleteDefaultValue(data, urlQuery["branchId"], "branch")
          }
          query={LIST_BRANCHES_DROPDOWN.query}
          defaultValue={autocompleteValues.branch}
        />
      ),
    },
    {
      id: 7,
      code: "originBranch",
      Permissions: "shipping.shipment.filter_by_branch",
      component: (
        <CustomAutocomplete
          name={"originBranchId"}
          label={t("originBranch")}
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          parseData={(data) => parseData(data)}
          onCompleted={(data) =>
            handelAutocompleteDefaultValue(
              data,
              urlQuery["originBranchId"],
              "originBranch"
            )
          }
          defaultValue={autocompleteValues.originBranch}
          query={LIST_BRANCHES_DROPDOWN.query}
        />
      ),
    },
    {
      id: 8,
      code: "custm",
      Permissions: "shipping.shipment.filter_by_customer",
      component: (
        <CustomAutocomplete
          name={"customerId"}
          label={
            ["", "MERCHANT", undefined].includes(
              customerType ?? watch("customerTypeCode")
            )
              ? t("customer")
              : t("individual")
          }
          skip={filterLoad && filterLoading}
          control={control}
          errors={errors}
          parseData={(data) => parseData(data)}
          onCompleted={(data) =>
            handelAutocompleteDefaultValue(
              data,
              urlQuery["customerId"],
              "customer"
            )
          }
          query={LIST_CUSTOMERS_DROPDOWN.query}
          variables={{
            input: {
              active: true,
              typeCode: ["", "MERCHANT", undefined].includes(
                customerType ?? watch("customerTypeCode")
              )
                ? "MERCHANT"
                : "INDIVIDUAL",
            },
          }}
          defaultValue={autocompleteValues.customer}
        />
      ),
    },
    {
      id: 9,
      code: "dlvAgent",
      Permissions: "shipping.shipment.filter_by_delivery_agent",
      component: (
        <CustomAutocomplete
          name={"lastDeliveryAgentId"}
          label={t("shippingAgent")}
          skip={filterLoad && filterLoading}
          control={control}
          errors={errors}
          parseData={(data) => parseData(data)}
          onCompleted={(data) =>
            handelAutocompleteDefaultValue(
              data,
              urlQuery[
              `${"lastDeliveryAgentId"}`
              ],
              "deliveryAgent"
            )
          }
          query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
          variables={{
            input: {
              active: true,
            },
          }}
          defaultValue={autocompleteValues.deliveryAgent}
        />
      ),
    },
    {
      id: 10,
      code: "zone",
      component: (
        <MultipleAutocomplete
          name={"recipientZoneId"}
          label={t("toZone")}
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          variables={{ input: { parentId: null } }}
          query={LIST_ZONES_DROPDOWN.query}
          parseData={(data) => parseData(data)}
          multiple
          valueKey="id"
          defaultValue={autocompleteValues.recipientZone}
          onChangeValue={(e) => {
            setValue("recipientSubzoneId", "");
          }}
        />
      ),
    },
    {
      id: 11,
      code: "subZone",
      component: (
        <MultipleAutocomplete
          name={"recipientSubzoneId"}
          label={t("toSubzone")}
          control={control}
          errors={errors}
          variables={{ input: { parentId: inputSubzone && inputSubzone[0] } }}
          query={LIST_ZONES_DROPDOWN.query}
          parseData={(data) => parseData(data)}
          skip={!watch("recipientZoneId")}
          disabled={
            !watch("recipientZoneId") ||
            watch("recipientZoneId").length > 1 ||
            watch("recipientZoneId").length === 0
          }
          multiple
          valueKey="id"
          defaultValue={autocompleteValues.recipientSubzone}
        />
      ),
    },
    {
      id: 24,
      code: "zoneSender",
      component: (
        <MultipleAutocomplete
          name={"senderZoneId"}
          label={t("fromZone")}
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          variables={{ input: { parentId: null } }}
          query={LIST_ZONES_DROPDOWN.query}
          parseData={(data) => parseData(data)}
          multiple
          valueKey="id"
          defaultValue={autocompleteValues.senderZoneId}
          onChangeValue={(e) => {
            setValue("senderSubzoneId", "");
          }}
        />
      ),
    },
    {
      id: 25,
      code: "subZoneSender",
      component: (
        <MultipleAutocomplete
          name={"senderSubzoneId"}
          label={t("fromSubzone")}
          control={control}
          errors={errors}
          variables={{ input: { parentId: inputSenderZone && inputSenderZone[0] } }}
          query={LIST_ZONES_DROPDOWN.query}
          parseData={(data) => parseData(data)}
          skip={!watch("senderZoneId")}
          disabled={
            !watch("senderZoneId") ||
            watch("senderZoneId").length > 1 ||
            watch("senderZoneId").length === 0
          }
          multiple
          valueKey="id"
          defaultValue={autocompleteValues.senderSubzoneId}
        />
      ),
    },
    {
      id: 12,
      code: "refNumber",
      component: (
        <ControlMUItextField
          control={control}
          errors={errors}
          name={"refNumber"}
          label={t("refNumber")}
          onPaste={(e) => {
            e.preventDefault();
            const input = e.target;
            const inputValue = e.clipboardData
              .getData("Text")
              .replace(/\n/g, ",");

            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
              window.HTMLInputElement.prototype,
              "value"
            ).set;
            nativeInputValueSetter.call(input, input.value + inputValue);

            input.dispatchEvent(new Event("input", { bubbles: true }));
          }}
        />
      ),
    },
    {
      id: 13,
      code: "service",
      component: (
        <CustomAutocomplete
          name={"serviceId"}
          label={t("service")}
          skip={filterLoad && filterLoading}
          control={control}
          errors={errors}
          parseData={(data) => parseData(data)}
          onCompleted={(data) =>
            handelAutocompleteDefaultValue(
              data,
              urlQuery["serviceId"],
              "service"
            )
          }
          query={LIST_SHIPPING_SERVICES_DROPDOWN.query}
          defaultValue={autocompleteValues.service}
        />
      ),
    },
    {
      id: 14,
      code: "deliveryType",
      component: (
        <LookupDropdown
          name={"deliveryTypeCode"}
          label={t("deliveryType")}
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          variables={{ input: { code: "SHP_DELIVERY_TYPE" } }}
          onCompleted={(data) => lookupComplete(data, "deliveryTypeCode")}
        />
      ),
    },
    {
      id: 15,
      code: "returnType",
      component: (
        <LookupDropdown
          name={"returnTypeCode"}
          label={t("returnType")}
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          variables={{ input: { code: "SHP_RETURNING_TYPE" } }}
          onCompleted={(data) => lookupComplete(data, "returnTypeCode")}
        />
      ),
    },
    {
      id: 16,
      code: "packageType",
      component: (
        <LookupDropdown
          name={"typeCode"}
          label={t("packageType")}
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          variables={{ input: { code: "SHP_REQUEST_TYPE" } }}
          onCompleted={(data) => lookupComplete(data, "typeCode")}
        />
      ),
    },
    {
      id: 17,
      code: "paymentType",
      component: (
        <LookupDropdown
          name={"paymentTypeCode"}
          label={t("paymentType")}
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          variables={{ input: { code: "SHP_PAYMENT_TYPE" } }}
          onCompleted={(data) => lookupComplete(data, "paymentTypeCode")}
        />
      ),
    },
    {
      id: 18,
      code: "collection",
      component: (
        <MUIselect
          name={"collected"}
          label={t("collection")}
          control={control}
          errors={errors}
          data={[
            { key: t("notCollected"), value: false },
            { key: t("collected"), value: true },
          ]}
        />
      ),
    },
    {
      id: 19,
      code: "paid",
      component: (
        <MUIselect
          name={"paid"}
          label={t("paidToCustomer")}
          control={control}
          errors={errors}
          data={[
            { key: t("yes"), value: true },
            { key: t("no"), value: false },
          ]}
        />
      ),
    },
    {
      id: 23,
      code: "new",
      component: (
        <MUIselect
          name={"new"}
          label={t("new")}
          control={control}
          errors={errors}
          data={[
            { key: t("yes"), value: true },
            { key: t("no"), value: false },
          ]}
        />
      ),
    },
    {
      id: 20,
      code: "customerType",
      Permissions: "shipping.shipment.filter_by_customer",
      component: (
        <LookupDropdown
          name={"customerTypeCode"}
          label={t("businessType")}
          control={control}
          errors={errors}
          filters={["MERCHANT", "INDIVIDUAL"]}
          skip={filterLoad && filterLoading}
          variables={{ input: { code: "SHP_CUSTOMER_TYPE" } }}
          onCompleted={(data) => lookupComplete(data, "customerTypeCode")}
          onChanges={(e) => {
            setValue("customerId", "");
          }}
        />
      ),
    },
    {
      id: 21,
      code: "createdBy",
      Permissions: "shipping.shipment.filter_by_user",
      component: (
        <CustomAutocomplete
          name={"userId"}
          label={t("createdBy")}
          control={control}
          errors={errors}
          skip={filterLoad && filterLoading}
          parseData={(data) => parseData(data)}
          onCompleted={(data) =>
            handelAutocompleteDefaultValue(data, urlQuery["userId"], "userId")
          }
          query={LIST_USERS_DROPDOWN.query}
          defaultValue={autocompleteValues.userId}
        />
      ),
    },
    {
      id: 22,
      code: "cancelled",
      component: (
        <MUIselect
          name={"cancelled"}
          label={t("cancelled")}
          control={control}
          errors={errors}
          data={[
            { key: t("yes"), value: true },
            { key: t("no"), value: false },
          ]}
        />
      ),
    }, {
      id: 23,
      code: "forwarded",
      component: (
        <MUIselect
          name={"forwarded"}
          label={t("forwarded")}
          control={control}
          errors={errors}
          data={[
            { key: t("forwarded"), value: true },
            { key: t("notForwarded"), value: false },
          ]}
        />
      ),
    },
    {
      id: 24,
      code: "hasProducts",
      component: (
        <MUIselect
          name={"hasProducts"}
          label={t("hasProducts")}
          control={control}
          errors={errors}
          data={[
            { key: t("yes"), value: true },
            { key: t("no"), value: false },
          ]}
        />
      ),
    },
  ];

  const showFilters = [];
  for (let x = 0; x < filtersAllowed.length; x++) {
    const filter = filtersComponents.filter(
      (elem) => elem.code === filtersAllowed[x]
    );
    showFilters.push(filter[0]);
  }

  return (
    <Root
      container
      item
      justifyContent="center"
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      className={classes.searchForm}
      alignContent="flex-start"
    >
      <Grid
        container
        item
        sm={12}
        justifyContent="flex-start"
        spacing={1}
        className={classes.searchField}
      >
        <Grid container item sm={12} alignItems="flex-start">
          <Can permission={!removeSearch} showException>
            <MUItextField
              label={t("search")}
              name={"search"}
              inputProps={{
                dir: "ltr",
              }}
              onPaste={(e) => {
                e.preventDefault();
                const input = e.target;
                const inputValue = e.clipboardData
                  .getData("Text")
                  .replace(/\n/g, ",");

                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                  window.HTMLInputElement.prototype,
                  "value"
                ).set;
                nativeInputValueSetter.call(input, input.value + inputValue);

                input.dispatchEvent(new Event("input", { bubbles: true }));
              }}
              onKeyPress={(e) => {
                const value = e.target.value.trim();
                if (value.slice(-1) !== "," && value !== "") {
                  if (e.key === "Enter") {
                    // e.preventDefault();
                    setValue("search", `${value},`);
                  }
                }
              }}
              formType={"requireFalse"}
              register={register}
              errors={errors}
              // InputProps={{
              //   endAdornment: Boolean(watch("search")) && (
              //     <InputAdornment position="end">
              //       <IconButton
              //         disabled={loading}
              //         aria-label="code"
              //         onClick={clearCode}
              //         edge="end"
              //         size="large"
              //       >
              //         <Clear color="error" />
              //       </IconButton>
              //     </InputAdornment>
              //   ),
              // }}
              watch={watch("search")}
            />
          </Can>
          <Can permission={Boolean(allowCode)} showException>
            <MUItextField
              label={t("code")}
              name={"code"}
              inputProps={{
                dir: "ltr",
              }}
              formType={"requireFalse"}
              register={register}
              errors={errors}
              watch={watch("code")}
            />
          </Can>
        </Grid>
        {showFilters.map((ele, index) => (
          <Can showException permission={ele.Permissions} key={index}>
            <Grid container item sm={12} alignItems="flex-start">
              {ele.component}
            </Grid>
          </Can>
        ))}
      </Grid>
      <Grid
        container
        item
        sm={12}
        className={clsx(classes.searchPadding, classes.button)}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          className={clsx(classes.btnMargin)}
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          startIcon={<Search />}
          disabled={loading}
        >
          {t("search")}
        </Button>
      </Grid>
    </Root>
  );
};

export default memo(
  FiltersForm,
  (prevProps, nextProps) =>
    prevProps.loading === nextProps.loading &&
    prevProps.rowsPerPage === nextProps.rowsPerPage
);
