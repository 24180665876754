import React, { useState } from "react";

import { styled } from "@mui/material/styles";

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  SwipeableDrawer,
  Typography,
  useTheme,
  Stack
} from "@mui/material";

import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Done, DoneAll, Print } from "@mui/icons-material";
import { Alert } from "@mui/material";
import clsx from "clsx";
import moment from "moment";
import { useForm } from "react-hook-form";
import {
  APPROVE_MANIFEST_MUTATION,
  DELIVERY_AGENT_ID,
} from "./Graphql";
import ShipmentList from "./ManifestShipments/ShipmentList";
import ControlMUItextField from "../HOC/MUI/ControlMUItextField";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";

import Grid from "@mui/material/Unstable_Grid2";
import * as gqlb from "gql-query-builder";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import NotFound from "../../Error/NotFound";
import {
  LIST_BRANCHES_DROPDOWN,
  LIST_CUSTOMERS_DROPDOWN,
  LIST_DELIVERY_AGENTS_DROPDOWN,
  LIST_MANIFEST_DROPDOWN,
  LIST_ROUTES_DROPDOWN,
  LIST_TRANSACTION_TYPES_DROPDOWN,
} from "../../GlobalsQuery/ListDropdown/ListDropdown";
import useWidth from "../../Hooks/useWidth";
import { Globals } from "../HOC/Classes/Globals";
import ListBranches from "../HOC/ComponentWithSpecificQuery/ListBranches";
import ShipmentsTable from "../HOC/CustomComponents/ShipmentsTable";
import TableFixedHeaderWraper from "../HOC/CustomComponents/TableWithFixedHeader";
import { pushUrl, windowReplaceUrl } from "../HOC/CustomFunctions/pushUrl";
import { setValidationError } from "../HOC/CustomFunctions/setValidationError";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";
import { CustomAutocomplete } from "../HOC/MUI/CustomAutocomplete";
import CustomButton from "../HOC/MUI/CustomButton";
import MuiSwitch from "../HOC/MUI/MUIswitch";
import BarcodeSelector from "./ManifestShipments/BarcodeSelector";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";
import useManifestDetails from "./ManifestDetails";
import MUIDateTime from "../HOC/MUI/MUIDateTime";
import { Can } from "../HOC/CustomComponents/Secured";
import TitleAppBar from "../../Layout/TitleAppBar";
import LongMenu from "../../Layout/MenuAppBar";
import EmptyTableMessage from "../HOC/FunctionComponents/EmptyTableMessage";
import { MANIFEST_BY_ID_QUERY_BUILDER, MANIFEST_SHIPMENT_DATA, SAVE_MANIFEST_MUTATION } from "./ManifestGraphQl";
import config from '../../config.json'

const PREFIX = "ManifestForm";

const classes = {
  mainTracking: `${PREFIX}-mainTracking`,
  paper: `${PREFIX}-paper`,
  table: `${PREFIX}-table`,
  shipmentList: `${PREFIX}-shipmentList`,
  overlay: `${PREFIX}-overlay`,
  button: `${PREFIX}-button`,
  main: `${PREFIX}-main`,
  customHeight: `${PREFIX}-customHeight`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.main}`]: {
    height: "calc(100vh - (48px + 40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (48px + 40px + 56px))",
    },
  },

  [`& .${classes.mainTracking}`]: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },

  [`& .${classes.paper}`]: {
    marginBottom: theme.spacing(2),
    position: "relative",
    minHeight: "327px",
    gridAutoRows: "max-content",
  },

  [`& .${classes.table}`]: {
    display: "grid",
    width: "100%",
  },

  [`& .${classes.shipmentList}`]: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },

  [`& .${classes.button}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.overlay}`]: {
    backgroundColor: theme.palette.background.paper,
    opacity: 0.7,
    position: "absolute",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    top: 0,
    right: 0,
  },
}));
const GridLoadingStyle = styled(Grid)(({ theme }) => ({
  [`&.${classes.main}`]: {
    height: "calc(100vh - (48px + 40px + 64px))",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      height: "calc(100dvh - (48px + 40px + 56px))",
    },
  },
  [`&.${classes.customHeight}`]: {
    height: "200px !important",
  },
}));

const ManifestForm = (props) => {
  const clearanceManifest = props.clearanceManifest
  const formType = props.match.params.type?.toUpperCase();

  const [pathURL, setPathURL] = useState(props.match.path)

  const { t } = useTranslation();

  const dateFormat = (date) => moment(date).locale("en").format("YYYY-MM-DD HH:mm:ss");

  const [manifestType, setManifestType] = useState(formType ? formType : null);
  const [autocompleteValues, setAutocompleteValues] = useState({
    branch: null,
    customer: null,
    deliveryAgent: null,
    transactionType: null,
    cancellationReason: null,
    referenceBranch: null,
    fromManifest: null,
    route: null,
  });

  const theme = useTheme();
  const [disableForm, setDisableForm] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    open: false,
    edit: false,
    approve: false,
    data: null,
  });

  const [pickedShipment, setPickedShipment] = useState({ shipments: [] });
  const manifestShipments = [];

  const [shipmentData, setShipmentData] = useState({
    page: 0,
    total: 0,
    rowsPerPage: config.app.pageSize,
    pickedShipment: [],
    sum: null,
    noData: true
  });

  const [manifestData, setManifestData] = useState();

  const manifestId = clearanceManifest ? clearanceManifest : props.match.params.id;

  const [fieldsState, setFieldsState] = useState({
    createdAt: new Date(),
  });
  const { enqueueSnackbar } = useSnackbar();

  const [disableView, setDisableView] = useState(false);

  const typeCode = [
    "DEX",
    "PKD",
    "RTS",
    "RJCT",
    "RTRN",
    "HTR",
    "DTR",
    "PKR",
  ].find((i) => i === formType);

  const defaultTransactionField = `default${typeCode}TransactionType`;

  const SETTINGS = gqlb.query({
    operation: "shippingSettings",
    fields: [{ [defaultTransactionField]: ["id", "name"] }],
    variables: {},
  });

  const chooseCustomerPermission = Globals.user.hasPermission(
    "shipping.shipment.choose_customer"
  );
  useQuery(
    gql`
      ${SETTINGS.query}
    `,
    {
      fetchPolicy: "no-cache",
      skip: !chooseCustomerPermission || !typeCode,
      onCompleted: (data) => {
        const defaultTransactionType =
          data?.shippingSettings?.[defaultTransactionField];
        setAutocompleteValues((prev) => ({
          ...prev,
          transactionType: defaultTransactionType,
        }));
      },
    }
  );

  const [saveManifestMutation, { loading: saveManifestLoad }] = useMutation(
    gql`
      ${SAVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => {
        setManifestData(data["saveManifest"]);
      },
    }
  );

  const sendWhatsappMessageStorage = localStorage.getItem(
    "sendWhatsappMessageStorage"
  );
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      sendWhatsappMessage:
        sendWhatsappMessageStorage === null ||
          sendWhatsappMessageStorage === "true"
          ? true
          : false,
    },
  });

  /******************************************* Start Pagination Functions ********************************************/

  const handleChangePage = (event, newPage) => {
    setShipmentData(prev => {
      return {
        ...prev,
        page: newPage
      }
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setShipmentData(prev => {
      return {
        ...prev,
        page: 0,
        rowsPerPage: event.target.value
      }
    })
  };

  /******************************************* End Pagination Functions ********************************************/

  /******************************************* Start Mutations ********************************************/

  const [approveManifetMutation, { loading: approveLoad }] = useMutation(
    gql`
      ${APPROVE_MANIFEST_MUTATION.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onCompleted: (data) => setManifestData(data["approveManifest"]),
    }
  );

  // useEffect(() => {
  //   if (disabledFirstRender.current) {
  //     const shipments = manifestShipments
  //       .filter((i) => !i["pendingDelete"])
  //       .map((item) => ({
  //         shipmentId: item.shipment.id,
  //         ...(manifestType === "RITS" && {
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),
  //         ...(manifestType === "RCV" && {
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),

  //         ...(manifestType === "BMR" && {
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),

  //         ...(manifestType === "RTS" && {
  //           returnTypeCode: item.returnType.code,
  //           fees: item.fees,
  //           cancellationReasonId: item.cancellationReason.id,
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),

  //         ...(manifestType === "HTR" && {
  //           ...(item.notes && { notes: item.notes }),
  //           ...(item.deliveryDate && {
  //             deliveryDate: item.deliveryDate,
  //           }),
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),
  //       }));

  //     const variables = watch();
  //     delete variables["sendWhatsappMessage"];

  //     for (const key in variables) {
  //       if (variables[key] === "") {
  //         variables[key] = null;
  //       }
  //     }

  //     variables["date"] = dateFormat(variables["date"]);

  //     saveManifetMutation({
  //       variables: {
  //         input: {
  //           ...variables,
  //           manifestShipments: shipments,
  //         },
  //       },
  //     })
  //       .then((data) => { })
  //       .catch(({ graphQLErrors }) => {
  //         setValidationError(graphQLErrors, setError);

  //         console.log(graphQLErrors);
  //       });
  //   }
  //   disabledFirstRender.current = true;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pickedShipment]);

  /******************************************* End Mutations ********************************************/

  /******************************************* Start Manifest By Id ********************************************/

  const { data: manifestByIdQuery, loading: manifestByIdLoad, refetch: refetchManifestQuery } = useQuery(
    gql`
      ${MANIFEST_BY_ID_QUERY_BUILDER.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !manifestId,
      variables: {
        id: manifestId ? parseInt(manifestId) : watch("id"),
      },
      onCompleted: (data) => {
        if (
          data?.manifest === null ||
          !Globals.supportedTransactionType.some(
            (i) => i === data?.manifest?.transactionType?.type?.code
          )
        ) {
        } else {
          const manifest = data?.manifest;
          setManifestType(manifest?.transactionType?.type.code);

          setManifestData(manifest);
          setValue("id", parseInt(manifest?.id));
          setValue("code", manifest?.code);
          setValue("date", manifest?.date);
          if (clearanceManifest) {
            setValue("branchId", manifest.branch.id)
            setValue("transactionTypeId", manifest?.transactionType?.id)
            setValue("deliveryAgentId", manifest.deliveryAgent.id)
          }
          manifest?.notes && setValue("notes", manifest?.notes);

          manifest?.transactionType?.code &&
            setFieldsState((prev) => ({
              ...prev,
              createdAt: manifest?.date,
            }));

          console.log(manifest);

          setAutocompleteValues({
            branch: manifest.branch,
            ...(manifest?.customer && {
              customer: manifest?.customer,
            }),
            ...(manifest?.deliveryAgent && {
              deliveryAgent: manifest?.deliveryAgent,
            }),
            ...(manifest?.referenceBranch && {
              referenceBranch: manifest?.referenceBranch,
            }),
            ...(manifest?.referenceManifest && {
              fromManifest: manifest?.referenceManifest,
            }),
            ...(manifest?.route && {
              route: manifest?.route,
            }),
            transactionType: manifest.transactionType,
          });

          // pickedShipment["shipments"] = data.manifest.manifestShipments;
          shipmentData.noData && getShipmentData()
          setDisableForm(true);
        }
      },
      onError: ({ graphQLErrors }) => {
        const auth = graphQLErrors[0].extensions.category
        auth === 'authorization' && setDisableView(true)
      }
    }
  );

  const [getShipmentData, { loading: loadingShipmentData, refetch: refetchShipmentDataQuery }] = useLazyQuery(
    gql`
        ${MANIFEST_SHIPMENT_DATA.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      variables: {
        id: parseInt(watch("id")),
        page: shipmentData.page + 1,
        first: shipmentData.rowsPerPage
      },
      onCompleted: (data) => {
        if (data?.manifest === null) return;
        const manifest = data?.manifest;
        const shipments = manifest.entries.data.map(e => {
          return {
            ...e,
            shipment: {
              ...e.shipment,
              pendingDelete: false
            }
          };
        });
        setShipmentData(prev => {
          return {
            ...prev,
            pickedShipment: shipments,
            total: manifest.entries.paginatorInfo.total,
            noData: false,
          }
        })
        refetchManifestQuery()
      },
    }
  );
  const { details: manifestDetails } = useManifestDetails({
    approved: manifestData?.approved,
    type: manifestType,
    refetchShipmentDataQuery,
    setShipmentData,
    manifestId: manifestData?.id,
    typeData: manifestData,
    setPickedShipment,
    pickedShipment,
    saveManifestLoad,
  });
  const manifestStatus = manifestDetails;

  const [state, setState] = useState({
    shipmentList: {
      left: false,
      bottom: false,
      right: false,
    },
    manifestList: {
      left: false,
      bottom: false,
      right: false,
    },
  });
  const [barcodeDarwerState, setBarcodeDarwerState] = useState({
    left: false,
    bottom: false,
    right: false,
  });

  const shipmentDrawerHandler = (anchor, open) => {
    setState((prev) => ({
      ...prev,
      shipmentList: { ...prev.shipmentList, [anchor]: open },
    }));
  };

  const barcodeDrawerHandler = (anchor, open) => {
    setBarcodeDarwerState((prev) => ({
      ...prev,
      [anchor]: open,
    }));
  };

  const screenWidth = useWidth();

  const anchor = screenWidth === "xs" ? "bottom" : "right";

  const history = useHistory();
  ////////////////////END DRAWER////////////////

  /******************************************* Start OnSubmit ********************************************/
  const isFormValuesChange = (data) => {
    return (
      manifestData &&
      ((["PKM", "OTR", "RTRN"].includes(manifestType) && data?.customerId && data?.customerId !== manifestData?.customer?.id) ||
        (data?.deliveryAgentId && data?.routeId !== manifestData?.route?.id) ||
        (data?.branchId && data?.branchId !== manifestData?.branch?.id) ||
        (manifestType === "RCV" && data?.deliveryAgentId && data?.deliveryAgentId !== manifestData?.deliveryAgentId?.id) ||
        (manifestType === "RITS" && data?.deliveryAgentId && data?.deliveryAgentId !== manifestData?.deliveryAgentId?.id) ||
        (manifestType === "BMR" && data?.referenceBranchId && data?.referenceBranchId !== manifestData?.referenceBranch?.id) ||
        (manifestType === "OTD" && data?.fromManifestId ? data?.fromManifestId !== manifestData?.referenceManifest?.id : manifestData?.referenceManifest?.id)
      )
    );
  };

  const onSubmit = (data) => {

    // const shipments = manifestShipments.map((item) => ({
    //   shipmentId: item.shipment.id,
    //   ...(manifestType === "RTS" && {
    //     returnTypeCode: item.returnType.code,
    //     fees: item.fees,
    //     cancellationReasonId: item.cancellationReason.id,
    //   }),
    // }));

    data["date"] = dateFormat(data["date"]);
    delete data["sendWhatsappMessage"];

    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    const newManifest = {
      ...data,
    };
    if (shipmentData.pickedShipment.length > 0 && isFormValuesChange(data)) {
      setOpenDialog((prev) => ({ ...prev, open: true, edit: true, data: newManifest }));
      return;
    }
    saveManifestMutationFun(newManifest)
  };

  const saveManifestMutationFun = (newManifest, refetchData) => {
    saveManifestMutation({
      variables: {
        input: {
          ...newManifest,
        },
      },
    })
      .then((data) => {
        setDisableForm(true);
        setValue("code", data?.data?.saveManifest?.code);
        setValue("id", parseInt(data?.data?.saveManifest?.id));
        // if (!props.match.params.id) {
        //   data?.data?.saveManifest.customer?.id && setValue("customerId", "");
        //   data?.data?.saveManifest.deliveryAgent?.id &&
        //     setValue("deliveryAgentId", "");
        //   setValue("transactionTypeId", "");
        //   setValue("branchId", "");
        // }
        const url = history.createHref({
          pathname: `/admin/manifests/${data?.data?.saveManifest?.id}/edit`,
        });
        setPathURL(url)
        windowReplaceUrl(url);
        refetchData && refetchManifestQuery()
        refetchData && refetchShipmentDataQuery()
        // props.history.replace(
        //   `/admin/manifests//edit`
        // );
      })
      .catch(({ graphQLErrors }) => {
        setValidationError(graphQLErrors, setError);
        console.log(graphQLErrors);
      });
  }

  /******************************************* End OnSubmit ********************************************/

  const handleDialogClose = () => {
    setOpenDialog({ edit: false, open: false, approve: false });
  };

  const whatsappValidation =
    (manifestType === "RITS" || manifestType === "OTD") && openDialog.approve;

  const shipLength = shipmentData.total === 0;

  const approveManifest = () => {
    approveManifetMutation({
      variables: {
        input: {
          id: parseInt(watch("id")),
          ...(whatsappValidation && {
            sendWhatsappMessage: watch("sendWhatsappMessage"),
          }),
        },
      },
    })
      .then((data) => {
        handleDialogClose();
        enqueueSnackbar(t("recordConfirmationSucceededMessage"), {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
        pushUrl(props, `/admin/manifests/${watch("id")}`);
      })
      .catch(({ graphQLErrors }) => {
        handleDialogClose();
        enqueueSnackbar(graphQLErrors[0].message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          TransitionComponent: Collapse,
        });
      });
  };
  const onApproved = () => {
    setOpenDialog((prev) => ({ ...prev, open: true, approve: true }));
  };

  const parseData = (data) => {
    return data;
  };

  /******************************************* Start Fields By Type ********************************************/

  const customerField = (type) => {
    if (type) {
      return (
        <Grid
          sm={manifestType === "PKM" || manifestType === "OTR" ? 6 : 4}
          xs={12}
        >
          <CustomAutocomplete
            control={control}
            errors={errors}
            rules={{
              ...(manifestType !== "PKM" && { required: t("fieldIsRequired") }),
            }}
            name={"customerId"}
            label={t("customer")}
            disabled={!watch("branchId")}
            parseData={(data) => parseData(data)}
            query={LIST_CUSTOMERS_DROPDOWN.query}
            skip={!watch("branchId")}
            variables={{
              input: {
                typeCode: ["MERCHANT", "INDIVIDUAL"],
                active: true,
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.customer}
          />
        </Grid>
      );
    }
  };

  const [getRoute] = useLazyQuery(
    gql`
      ${DELIVERY_AGENT_ID.query}
    `,
    {
      skip:
        manifestType !== "OTD" ||
        manifestType !== "STD" ||
        manifestType !== "PKM" ||
        manifestType !== "OTR",
      onCompleted: (data) => {
        setAutocompleteValues((prev) => ({
          ...prev,
          route: data.deliveryAgent.route,
        }));
      },
    }
  );
  const routeField = (type) => {
    if (['OTR', 'PKM', 'OTD', 'STD'].includes(type))
      return (
        <Grid sm={['STD'].includes(type) ? 4 : 6} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"routeId"}
            label={t("route")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => parseData(data)}
            query={LIST_ROUTES_DROPDOWN.query}
            defaultValue={autocompleteValues.route}
          />
        </Grid>
      );
  };
  const deliveryAgentField = (type) => {
    if (!['PRP'].includes(type)) {
      return (
        <Grid
          sm={
            type === "HTR" ||
              type === "RCV" ||
              type === "PKM" ||
              type === "OTR" ||
              type === "RITS" ||
              type === "OTD" ||
              type === "RTS"
              ? 6
              : 4
          }
          xs={12}
        >
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"deliveryAgentId"}
            label={t("shippingAgent")}
            rules={{ ...(!["HTR", "RTS", "RTRN", "RCV", "RITS"].includes(manifestType) && { required: t("fieldIsRequired") }) }}
            disabled={!watch("branchId")}
            onChangeValue={(e) => {
              if (e?.id) {
                getRoute({ variables: { id: e?.id } });
                clearErrors("deliveryAgentId");
              }
            }}
            parseData={(data) => parseData(data)}
            query={LIST_DELIVERY_AGENTS_DROPDOWN.query}
            skip={!watch("branchId") || manifestType === "PRP"}
            variables={{
              input: {
                active: true,
                branchId: {
                  value: watch("branchId"),
                  includeNull: true,
                },
              },
            }}
            defaultValue={autocompleteValues.deliveryAgent}
          />
        </Grid>
      );
    }
  };
  const branchTransferField = (type) => {
    const branchData = (data) => {
      const parsed = data?.filter((i) => i.id !== watch("branchId"));
      return parsed;
    };
    if (type === "BMT" || type === "BMR") {
      return (
        <Grid sm={4} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"referenceBranchId"}
            label={type === "BMT" ? t("toBranch") : t("fromBranch")}
            rules={{ required: t("fieldIsRequired") }}
            parseData={(data) => branchData(data)}
            query={LIST_BRANCHES_DROPDOWN.query}
            variables={{
              input: {
                all: true,
              },
            }}
            defaultValue={autocompleteValues.referenceBranch}
          />
        </Grid>
      );
    }
  };
  const fromManifestField = (type) => {
    const parseData = (data) => {
      const parsed = data?.filter((i) => i.name = i.code);
      return parsed;
    };
    if (autocompleteValues.fromManifest) {
      autocompleteValues.fromManifest.name = autocompleteValues.fromManifest.code;
    }
    if (type === "OTD") {
      return (
        <Grid sm={6} xs={12}>
          <CustomAutocomplete
            control={control}
            errors={errors}
            name={"fromManifestId"}
            label={t("fromManifest")}
            parseData={(data) => parseData(data)}
            query={LIST_MANIFEST_DROPDOWN.query}
            disabled={!watch("branchId")}
            skip={!watch("branchId")}
            hideCode
            variables={{
              input: {
                transactionTypeCode: type,
                forReference: {
                  branchId: watch("branchId")
                }
              },
            }}
            defaultValue={autocompleteValues.fromManifest}
          />
        </Grid>
      );
    }
  };

  const whatsappSwitch = (
    <MuiSwitch
      edge="end"
      name="sendWhatsappMessage"
      label={t("sendWhatsappMessage")}
      control={control}
      onChange={(event) => {
        localStorage.setItem(
          "sendWhatsappMessageStorage",
          event.target.checked
        );
      }}
    />
  );

  // useEffect(() => {
  //   if (disabledFirstRender.current) {
  //     const shipments = manifestShipments
  //       .filter((i) => !i["pendingDelete"])
  //       .map((item) => ({
  //         shipmentId: item.shipment.id,
  //         ...(manifestType === "RITS" && {
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),
  //         ...(manifestType === "RCV" && {
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),

  //         ...(manifestType === "BMR" && {
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),

  //         ...(manifestType === "RTS" && {
  //           returnTypeCode: item.returnType.code,
  //           fees: item.fees,
  //           cancellationReasonId: item.cancellationReason.id,
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),

  //         ...(manifestType === "HTR" && {
  //           ...(item.notes && { notes: item.notes }),
  //           ...(item.deliveryDate && {
  //             deliveryDate: item.deliveryDate,
  //           }),
  //           ...(item?.warehouseSection?.id && {
  //             warehouseSectionId: item?.warehouseSection?.id,
  //           }),
  //         }),
  //       }));

  //     const variables = watch();
  //     delete variables["sendWhatsappMessage"];

  //     for (const key in variables) {
  //       if (variables[key] === "") {
  //         variables[key] = null;
  //       }
  //     }

  //     variables["date"] = dateFormat(variables["date"]);

  //     saveManifetMutation({
  //       variables: {
  //         input: {
  //           ...variables,
  //           manifestShipments: shipments,
  //         },
  //       },
  //     })
  //       .then((data) => { })
  //       .catch(({ graphQLErrors }) => {
  //         setValidationError(graphQLErrors, setError);

  //         console.log(graphQLErrors);
  //       });
  //   }
  //   disabledFirstRender.current = true;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pickedShipment]);

  /******************************************* End Fields By Type ********************************************/

  let formBody = null;
  let tableBody = null;

  tableBody = (
    <Grid container xs={12} justifyContent="center" sx={{ width: "100%" }}>
      {/* *******Table******* */}
      <Paper className={clsx(classes.table, classes.paper)}>
        <EnhancedTableToolbar
          {...props}
          classes={classes}
          selectedShipment={shipmentData.pickedShipment}
          total={shipmentData.total}
          manifestType={manifestType}
          addShipment={() => shipmentDrawerHandler(anchor, true)}
          barcode={() => barcodeDrawerHandler(anchor, true)}
          approved={manifestData?.approved}
          manifestId={watch("id")}
        />
        {manifestType !== null && !loadingShipmentData && (
          <TableFixedHeaderWraper>
            <ShipmentsTable
              data={shipmentData.pickedShipment}
              headCells={manifestStatus.tableHead}
              parseBodyCell={manifestStatus.tableBody}
              total={manifestStatus.tableTotal}
              sumRow={manifestStatus?.sum}
              sumTableNumber={manifestStatus?.sumTableNumber}
              ranking={true}
            />
          </TableFixedHeaderWraper>
        )}
        <Grid container justifyContent="center" alignItems="center">
          {loadingShipmentData ?
            <FullScreenLoading minHeight={"100px"} />
            : shipLength && watch("id") && disableForm && (
              <EmptyTableMessage
                message={t("noResult")}
              />
            )}
        </Grid>
        {shipmentData.total > 0 && (
          <MUITablePagination
            count={shipmentData.total}
            rowsPerPage={shipmentData.rowsPerPage}
            page={shipmentData.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}

        {(!watch("id") || !disableForm) && (
          <Box className={classes.overlay}>
            <Typography color="textPrimary" variant="body1">
              {t("saveManifestRequired")}
            </Typography>
          </Box>
        )}
      </Paper>
    </Grid>
  );

  const formCondition = !formType
    ? Boolean(watch("id")) && !manifestByIdLoad
    : !manifestByIdLoad;

  formBody = (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ padding: "24px", width: "100%", margin: "0" }}
    >
      <Paper container component={Grid} sx={{ padding: 1, flexGrow: 1 }}>
        {watch("id") && disableForm && (
          <Grid sm={12} xs={12}>
            <Alert
              severity="warning"
              action={
                !manifestData?.approved && (
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setDisableForm(false);
                      // fireMutaion([])
                    }}
                  >
                    {t("update")}
                  </Button>
                )
              }
            >
              {manifestData?.approved
                ? t("updateRecordsForbidden")
                : t("updateRecord")}
            </Alert>
          </Grid>
        )}

        <Grid
          spacing={2}
          container
          sx={{ position: "relative", flexGrow: 1 }}
        >
          {(manifestData?.approved || disableForm) && (
            <Box
              className={classes.overlay}
              sx={{ backgroundColor: "rgb(255 255 255 / 45%)" }}
            >
              {/* <Typography color="textPrimary" variant="body1" >لا يمكن التعديل</Typography> */}
            </Box>
          )}
          <Grid sm={['PRP'].includes(manifestType) ? 6 : 4} xs={12}>
            <ControlMUItextField
              name="code"
              control={control}
              label={t("manifestCode")}
              rules={{ required: watch("id") ? t("fieldIsRequired") : false }}
            />
          </Grid>
          <Grid sm={['PRP'].includes(manifestType) ? 6 : 4} xs={12}>
            <MUIDateTime
              name="date"
              label={t("date")}
              control={control}
              defaultValue={manifestByIdQuery?.manifest?.date}
              value={fieldsState.createdAt}
              onChange={(e) =>
                setFieldsState((prev) => ({ ...prev, createdAt: e }))
              }
            />
          </Grid>
          <Grid sm={['PRP'].includes(manifestType) ? 6 : 4} xs={12}>
            <ListBranches
              control={control}
              errors={errors}
              name={"branchId"}
              rules={{ required: t("fieldIsRequired") }}
              skip={manifestType === null}
              defaultValue={autocompleteValues.branch}
              onChangeValue={(e) => {
                !['RITS', 'PRP'].includes(manifestType) && setValue("deliveryAgentId", "");
                ["PKM", "OTR", "RTRN"].includes(manifestType) && setValue("customerId", "");
                (manifestType === "BMT" || manifestType === "BMR") &&
                  setValue("referenceBranchId", "");
                // setPickedShipment({ shipments: [] })
              }}
              skipDefaultBranch={Boolean(manifestId)}
            />
          </Grid>
          {deliveryAgentField(manifestType)}
          {routeField(manifestType)}

          <Grid
            sm={
              manifestType === "HTR" ||
                manifestType === "RCV" ||
                manifestType === "RTS" ||
                manifestType === "PKM" ||
                manifestType === "OTR" ||
                manifestType === "RITS" ||
                manifestType === "OTD" ||
                manifestType === "PRP"
                ? 6
                : 4
            }
            xs={12}
          >
            <CustomAutocomplete
              control={control}
              errors={errors}
              selectFirst={true}
              name={"transactionTypeId"}
              label={t("transactionType")}
              rules={{ required: t("fieldIsRequired") }}
              parseData={(data) => parseData(data)}
              query={LIST_TRANSACTION_TYPES_DROPDOWN.query}
              skip={!watch("branchId")}
              variables={{
                input: {
                  type: manifestType,
                  ...(watch("branchId") && {
                    branchId: {
                      value: watch("branchId"),
                      includeNull: true,
                    },
                  }),
                },
              }}
              defaultValue={autocompleteValues.transactionType}
            />
          </Grid>
          {customerField(manifestStatus?.customer)}
          {branchTransferField(manifestType)}
          {fromManifestField(manifestType)}
          <Grid sm={12} xs={12}>
            <ControlMUItextField
              name="notes"
              control={control}
              label={t("notes")}
              rows={2}
            />
          </Grid>
        </Grid>

        <Grid sm={12} xs={12} container justifyContent="flex-end">
          {!disableForm && (
            <CustomButton
              customcolor={theme.palette.success.main}
              type="submit"
              className={classes.button}
              disabled={
                manifestData?.approved || disableForm || saveManifestLoad
              }
              variant="contained"
              size="medium"
              loading={saveManifestLoad}
              // className={classes.button}
              startIcon={!saveManifestLoad && <Done />}
            >
              {!saveManifestLoad && t("save")}
            </CustomButton>
          )}
          {disableForm && (
            <Button
              disabled={
                shipLength || manifestData?.approved || saveManifestLoad
              }
              className={classes.button}
              variant="contained"
              size="medium"
              color="primary"
              onClick={onApproved}
              name="approved"
              startIcon={<DoneAll />}
            >
              {t("approve")}
            </Button>
          )}
        </Grid>
      </Paper>
    </Grid>
  );

  const validCondition =
    !manifestByIdQuery?.manifest && manifestId &&
    !Globals.supportedTransactionType.some(
      (i) => i === manifestByIdQuery?.manifest?.transactionType?.type?.code
    );

  const icons = [
    {
      id: "print",
      title: "print",
      action: () =>
        window.open(
          `${window.location.origin}/report/print/manifest/${watch(
            "id"
          )}/${manifestType}`
        ),
      icon: Print,
      disabled: manifestByIdLoad,
      permission: !shipLength,
    },
  ]

  return manifestByIdLoad ?
    <GridLoadingStyle container item justifyContent="center" className={clsx(classes.main, { [classes.customHeight]: clearanceManifest })}>
      <FullScreenLoading height={"100%"} />
    </GridLoadingStyle>
    : validCondition ? (
      <NotFound />
    ) : disableView ? (
      <Can permission="uneEditable" />
    ) : (
      <Root>
        {!clearanceManifest && <>
          <TitleAppBar path={pathURL} type={manifestType}>
            <LongMenu icons={icons} />
          </TitleAppBar>
          {props.children}
          {formBody}
        </>}
        <Dialog
          open={openDialog.open}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle id="alert-dialog-title" color={"text.primary"}>
            {openDialog.edit && t("updateRecord")}
            {openDialog.approve && t("approveRecord")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {openDialog.edit && t("updateManifestWarningMessage")}
              {openDialog.approve && t("approveRecordConfirmationMessage")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {whatsappValidation &&
              <Stack sx={{
                flex: "100% 1 1",
                padding: (theme) => theme.spacing(0, 1),
              }}>

                {whatsappSwitch}
              </Stack>}

            <Button onClick={handleDialogClose} color="primary">
              {t("cancel")}
            </Button>
            <Button
              disabled={approveLoad}
              onClick={() => {
                openDialog.edit && handleDialogClose();
                openDialog.edit && setDisableForm(true);
                openDialog.edit && saveManifestMutationFun(openDialog.data, refetchShipmentDataQuery);
                openDialog.approve && approveManifest();
              }}
              color="primary"
              autoFocus
            >
              {openDialog.edit && t("update")}
              {openDialog.approve && !approveLoad && t("approve")}
              {approveLoad && <ButtonLoading />}
            </Button>
          </DialogActions>
        </Dialog>

        <SwipeableDrawer
          variant="persistent"
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor={anchor}
          open={state.shipmentList[anchor]}
          onClose={() => false}
          onOpen={() => shipmentDrawerHandler(anchor, true)}
          classes={{ paper: classes.shipmentList }}
          dir={theme.direction}
        >
          {state.shipmentList[anchor] && (
            <ShipmentList
              refetchShipmentDataQuery={refetchShipmentDataQuery}
              type={manifestType}
              selected={manifestShipments}
              done={() => shipmentDrawerHandler(anchor, false)}
              variables={watch()}
            />
          )}
        </SwipeableDrawer>

        <SwipeableDrawer
          disableDiscovery={true}
          disableSwipeToOpen={true}
          anchor={anchor}
          open={barcodeDarwerState[anchor]}
          onClose={() => false}
          onOpen={() => barcodeDrawerHandler(anchor, true)}
          sx={{
            width: "auto",
            "& .MuiDrawer-paper": {
              width: { md: "50%" },

              height: "100%",
              overflow: "hidden",
            },
          }}
          dir={theme.direction}
        >
          {barcodeDarwerState[anchor] && <BarcodeSelector
            refetchShipmentDataQuery={refetchShipmentDataQuery}
            type={manifestType}
            selected={manifestShipments}
            done={() => barcodeDrawerHandler(anchor, false)}
            variables={watch()}
          />}
        </SwipeableDrawer>

        {formCondition && <Grid
          container
          spacing={3}
          justifyContent="center"
          className={classes.mainTracking}
        >
          {tableBody}
        </Grid>}
      </Root>
    );
};

export default ManifestForm;
