import { Box } from "@mui/material";
import formatMoney from "../../helpers/numbersDot";
import { dateFormatLL } from "../../helpers/dateFunctions";
import { initShipmentFields } from "../Turbo/initShipmentFields";
import { Globals } from "../HOC/Classes/Globals";

export const getListShipmentTable = (shipment, key, ifCode, account) => {

    if (account && key === "deliveryCommission") {
        key = undefined;
    }
    if (key === undefined) {
        return { value: "noVal" }
    }
    const ListShipmentDetails = {
        customer: {
            pathname: `/admin/customers/${shipment?.customer?.id}`,
            value: <>
                <Box component="span" fontWeight="bold">{` (${shipment.customer?.code}) `}</Box>
                <Box component="span">  {shipment.customer?.name}</Box>
            </>
        },
        code: {
            pathname: `/admin/shipments/${shipment.id}`,
            value: shipment.code,
            ifCode: "code"
        },
        originBranch: {
            pathname: `/admin/branches/${shipment.originBranch?.id}`,
            value: shipment.originBranch?.name
        },
        branch: {
            pathname: `/admin/branches/${shipment.branch?.id}`,
            value: shipment.branch?.name
        },
        lastDeliveryAgent: {
            pathname: `/admin/delivery-agents/${shipment.lastDeliveryAgent?.id}`,
            value: shipment?.lastDeliveryAgent?.name
        },
        date: {
            value: dateFormatLL(shipment?.date)
        },
        deliveryDate: {
            value: dateFormatLL(shipment?.deliveryDate)
        },
        deliveredOrReturnedDate: {
            value: dateFormatLL(shipment?.deliveredOrReturnedDate)
        },
        description: {
            value: shipment?.description,
            condition: "description",
        },
        senderZone: {
            pathname: `/admin/zones/${shipment.senderZone?.id}`,
            zone: shipment.senderZone?.name,
            subzone: shipment.senderSubzone?.name
        },
        recipientZone: {
            pathname: `/admin/zones/${shipment.recipientZone?.id}`,
            zone: shipment.recipientZone?.name,
            subzone: shipment.recipientSubzone?.name
        },
        collected: {
            value: shipment?.collected
        },
        paidToCustomer: {
            value: shipment?.paidToCustomer
        },
        cancelled: {
            value: shipment?.cancelled
        },
        inWarehouse: {
            value: shipment?.inWarehouse
        },
        hasProducts: {
            value: shipment?.hasProducts
        },
        customerDueCredit: {
            value: shipment?.customerDue >= 0 ? formatMoney(shipment?.customerDue) : 0
        },
        customerDueDebit: {
            value: shipment?.customerDue < 0 ? formatMoney(Math.abs(shipment?.customerDue)) : 0
        },
        customerDue: {
            value: formatMoney(shipment?.customerDue)
        },
        netCollection: {
            value: formatMoney(shipment?.pendingCollectionAmount - shipment?.deliveryCommission)
        },
        price: {
            value: formatMoney(shipment?.price)
        },
        deliveredAmount: {
            value: formatMoney(shipment?.deliveredAmount)
        },
        allDueFees: {
            value: formatMoney(shipment?.allDueFees)
        },
        amount: {
            value: formatMoney(shipment?.amount)
        },
        totalAmount: {
            value: formatMoney(shipment?.totalAmount)
        },
        deliveryCommission: {
            value: formatMoney(shipment?.deliveryCommission)
        },
        collectedFees: {
            value: formatMoney(shipment?.collectedFees)
        },
        returningDueFees: {
            value: formatMoney(shipment?.returningDueFees)
        },
        pendingCollectionAmount: {
            value: formatMoney(shipment?.pendingCollectionAmount)
        },
        status: {
            shipmentStatus: shipment?.status,
            shipmentDeliveryType: shipment?.deliveryType,
            ifCode: ifCode
        },
        lastTransactionDate: {
            value: dateFormatLL(shipment.lastTransaction?.date)
        },
        type: {
            value: shipment.type?.name
        },
        paymentType: {
            value: shipment.paymentType?.name
        },
        priceType: {
            value: shipment.priceType?.name
        },
        openable: {
            value: shipment.openable?.name
        },
        cancellationReason: {
            value: shipment.cancellationReason?.name
        },
        returnType: {
            value: shipment.returnType?.name
        },
        deliveryType: {
            value: shipment.deliveryType?.name
        },
        warehouseSection: {
            value: shipment.warehouseSection?.name
        },
        barcode: {
            value: shipment.barcode
        },
    }
    return Boolean(ListShipmentDetails[key]) ? ListShipmentDetails[key] : { value: shipment[key] }
}

export const defaultQueryFields = (keys, permission, someDefaultKeys, account) => {
    const defaultKeys = [
        "id",
        "code",
        "hasProducts",
        "cancelled",
        { status: ["name", "code"] },
        { deliveryType: ["name"] },
        { branch: ["id"], },
    ].concat(initShipmentFields)

    if (someDefaultKeys) {
        const keys = [
            "deliveryFees",
            "returnFees",
            "totalAmount",
            { paymentType: ["name", "code"], },
            "allDueFees",
            { type: ["name", "code"] },
            {
                shipmentProducts: [
                    "price",
                    "quantity",
                    {
                        product: ["id", "name"]
                    }
                ]
            }
        ]

        keys.map((e) => defaultKeys.push(e))
    }

    const queries = {
        date: {
            queryName: "date"
        },
        description: {
            queryName: "description"
        },
        deliveredOrReturnedDate: {
            queryName: "deliveredOrReturnedDate"
        },
        ...(permission && {
            adminNotes: {
                queryName: "adminNotes"
            }
        }),
        senderName: {
            queryName: "senderName"
        },
        recipientName: {
            queryName: "recipientName"
        },
        recipientAddress: {
            queryName: "recipientAddress"
        },
        recipientMobile: {
            queryName: "recipientMobile"
        },
        refNumber: {
            queryName: "refNumber"
        },
        piecesCount: {
            queryName: "piecesCount"
        },
        price: {
            queryName: "price"
        },
        allDueFees: {
            queryName: "allDueFees"
        },
        deliveredAmount: {
            queryName: "deliveredAmount"
        },
        collectedFees: {
            queryName: "collectedFees"
        },
        amount: {
            queryName: "amount"
        },
        totalAmount: {
            queryName: "totalAmount"
        },
        customerDue: {
            queryName: "customerDue"
        },
        returningDueFees: {
            queryName: "returningDueFees"
        },
        collected: {
            queryName: "collected"
        },
        paidToCustomer: {
            queryName: "paidToCustomer"
        },
        cancelled: {
            queryName: "cancelled"
        },
        collectedAmount: {
            queryName: "collectedAmount"
        },
        inWarehouse: {
            queryName: "inWarehouse"
        },
        deliveryDate: {
            queryName: "deliveryDate"
        },
        attempts: {
            queryName: "attempts"
        },
        status: {
            queryName: {
                status: ["name", "code"],
            }
        },
        lastTransactionDate: {
            queryName: {
                lastTransaction: ["date"],
            }
        },
        customer: {
            queryName: {
                customer: ["id", "name", "code"],
            }
        },
        recipientZone: {
            queryName: [
                {
                    recipientZone: ["id", "name"],
                },
                {
                    recipientSubzone: ["name"]
                }
            ]
        },
        originBranch: {
            queryName: {
                originBranch: ["id", "name"],
            }
        },
        branch: {
            queryName: {
                branch: ["id", "name"],
            }
        },

        senderZone: {
            queryName: [
                {
                    senderZone: ["id", "name"],
                },
                {
                    senderSubzone: ["id", "name"],
                }
            ]
        },

        netCollection: {
            queryName: ["pendingCollectionAmount", "deliveryCommission"]
        },

        priceType: {
            queryName: {
                priceType: ["name"],
            }
        },
        type: {
            queryName: {
                type: ["name"],
            }
        },
        paymentType: {
            queryName: {
                paymentType: ["name"],
            }
        },
        openable: {
            queryName: {
                openable: ["name"],
            }
        },
        returnType: {
            queryName: {
                returnType: ["name"],
            }
        },
        deliveryType: {
            queryName: {
                deliveryType: ["name"],
            }
        },
        warehouseSection: {
            queryName: {
                warehouseSection: ["name"],
            }
        },
        lastDeliveryAgent: {
            queryName: {
                lastDeliveryAgent: ["name", "id"],
            }
        },
        cancellationReason: {
            queryName: {
                cancellationReason: ["name", "id"],
            }
        },
        notes: {
            queryName: "notes"
        },
        pendingCollectionAmount: {
            queryName: "pendingCollectionAmount"
        },
        ...(!account && {
            deliveryCommission: {
                queryName: "deliveryCommission"
            },
        }),

    }

    keys.map((e) => {
        let customerDue = false
        if (queries?.[e]) {
            if (Array.isArray(queries[e].queryName)) {
                queries[e].queryName.map((i) => defaultKeys.push(i))
            } else {
                if (["customerDueDebit", "customerDueCredit", "customerDue"].includes(e)) {
                    !customerDue && defaultKeys.push("customerDue")
                } else {
                    defaultKeys.push(queries[e].queryName)
                }
            }
        }
        return ''
    })
    return defaultKeys
};

export const shipmentFieldsFun = (permission, checkIfKeyExist, account) => {
    let user = Globals?.user?.account
    const shipmentFields = [
        {
            key: "code",
            label: "code",
            type: "link",
            path: "shipments"
        },
        {
            key: "status",
            label: "status",
            type: "status"
        },
        {
            key: "date",
            label: "date",
            type: ""
        },
        {
            key: "deliveredOrReturnedDate",
            label: "deliveryCanclingDate",
            type: ""
        },
        {
            key: "description",
            label: "description",
            type: ""
        },
        {
            key: "senderName",
            label: "senderName",
            type: ""
        },
        {
            key: "recipientName",
            label: "recipient",
            type: ""
        },
        {
            key: "recipientAddress",
            label: "recipientAddress",
            type: ""
        },
        {
            key: "recipientMobile",
            label: "recipientMobile",
            type: "phone"
        },
        {
            key: "piecesCount",
            label: "pieceCount",
            type: ""
        },
        {
            key: "price",
            label: "packagePrice",
            type: "",
            ifSum: "price"
        },
        {
            key: "allDueFees",
            label: "allDueFees",
            type: "",
            ifSum: "allDueFees"
        },
        {
            key: "deliveredAmount",
            label: "deliveredAmount",
            type: "",
            ifSum: "deliveredAmount"
        },
        {
            key: "collectedFees",
            label: "collectedFees",
            type: "",
            ifSum: "collectedFees"
        },
        {
            key: "amount",
            label: "customerNet",
            type: "",
            ifSum: "amount"
        },
        {
            key: "totalAmount",
            label: "totalPackagePrice",
            type: "",
            ifSum: "totalAmount"
        },
        {
            key: "customerDue",
            label: "customerDueAmount",
            type: "",
            ifSum: "customerDue"
        },
        {
            key: "returningDueFees",
            label: "returningDueFees",
            type: "",
            ifSum: "returningDueFees"
        },
        {
            key: "collected",
            label: "collected",
            type: "boolean"
        },
        {
            key: "hasProducts",
            label: "hasProducts",
            type: "boolean"
        },
        {
            key: "paidToCustomer",
            label: "paidToCustomer",
            type: "boolean",
        },
        {
            key: "collectedAmount",
            label: "collectedAmount",
            type: "",
            ifSum: "collectedAmount",
        },
        {
            key: "deliveryDate",
            label: "redeliveryDate",
            type: ""
        },
        {
            key: "cancelled",
            label: "cancelled",
            type: "boolean"
        },
        {
            key: "inWarehouse",
            label: "inWarehouse",
            type: "boolean"
        },
        {
            key: "customerDueDebit",
            label: "customerDueDebit",
            type: "",
            ifSum: "customerDueDebit"
        },
        {
            key: "customerDueCredit",
            label: "customerDueCredit",
            type: "",
            ifSum: "customerDueCredit"
        },
        {
            key: "attempts",
            label: "attempts",
            type: ""
        },
        {
            key: "refNumber",
            label: "refNumber",
            type: ""
        },
        {
            key: "notes",
            label: "notes",
            type: ""
        },
        {
            key: "netCollection",
            label: "netCollection",
            type: "",
            ifSum: ["deliveryCommission", "pendingCollectionAmount"]
        },
        {
            key: "pendingCollectionAmount",
            label: "deservedAmount",
            type: "",
            ifSum: "pendingCollectionAmount"
        },
        {
            key: "deliveryType",
            label: "deliveryType",
            type: ""
        },
        {
            key: "customer",
            label: "customer",
            type: "link"
        },
        {
            key: "originBranch",
            label: "originBranch",
            type: "link"
        },
        {
            key: "branch",
            label: "branch",
            type: "link"
        },
        {
            key: "recipientZone",
            label: "destination",
            type: "zone"
        },
        {
            key: "senderZone",
            label: "source",
            type: "zone"
        },
        {
            key: "priceType",
            label: "priceType",
            type: ""
        },
        {
            key: "type",
            label: "type",
            type: ""
        },
        {
            key: "openable",
            label: "packageOpen",
            type: ""
        },
        {
            key: "lastDeliveryAgent",
            label: "shippingAgent",
            type: "link"
        },
        {
            key: "cancellationReason",
            label: "CancellationReasonName",
            type: ""
        },
        {
            key: "paymentType",
            label: "paymentType",
            type: ""
        },
        {
            key: "returnType",
            label: "returnType",
            type: ""
        },
        {
            key: "warehouseSection",
            label: "wareHouse",
            type: ""
        },
        {
            key: "lastTransactionDate",
            label: "lastTransactionDate",
            type: ""
        },
        {
            key: "barcode",
            label: "barcode",
            type: "barcode"
        },
    ]
  
    if (account) {
        user = true
    }
    !user && shipmentFields.push({
        key: "deliveryCommission",
        label: "deliveryCommission",
        type: "",
        ifSum: "deliveryCommission"
    },)
    if (checkIfKeyExist) {
        return shipmentFields.some(field => field.key.includes(checkIfKeyExist))
    }
    permission && shipmentFields.push({ key: "adminNotes", label: "adminNotes", type: "text" })
    return shipmentFields
}



export const initialData = {
    "shipments": [
        "code",
        "status",
        "date",
        "customer",
        "senderName",
        "branch",
        "originBranch",
        "senderZone",
        "recipientName",
        "recipientZone",
        "description",
        "price",
        "lastDeliveryAgent",
    ],
    "shipments-in-warehouse": [
        "code",
        "status",
        "date",
        "warehouseSection",
        "lastDeliveryAgent",
        "totalAmount",
        "deliveredAmount",
        "customerDue",
    ],
    "idle-shipments": [
        "code",
        "status",
        "lastTransactionDate",
        "warehouseSection",
        "lastDeliveryAgent",
        "totalAmount",
        "deliveredAmount",
        "customerDue",
    ],
    "delivery-list-actions": [
        "code",
        "status",
        "date",
        "senderName",
        "recipientName",
        "lastDeliveryAgent",
        "paymentType",
        "type"],
    "receive-undelivered-shipments": [
        "code",
        "status",
        "cancellationReason",
        "type",
        "deliveryDate",
        "attempts",
        "lastDeliveryAgent",
        "deliveryType",
        "recipientName",
    ],
    "not-paid-to-customer": [
        "code",
        "status",
        "date",
        "customer",
        "price",
        "allDueFees",
        "customerDueCredit",
        "customerDueDebit",
        "customerDue",
        "totalAmount",
    ],
    "uncollected-from-delivery-agents": [
        "code",
        "status",
        "date",
        "deliveredOrReturnedDate",
        "lastDeliveryAgent",
        "senderName",
        "recipientName",
        "recipientZone",
        "price",
        "collectedFees",
        "deliveredAmount",
        "pendingCollectionAmount",
    ]
}

export const getShipmentsTableHeader = (keys, permission, ifUpdate, account) => {
    let tableHeader = []
    keys.map((e) =>
        shipmentFieldsFun(permission, null, account).map((i) =>
            e === i.key && tableHeader.push(i.label)
        )
    )
    ifUpdate && tableHeader.push(' ')
    return tableHeader
}

export const getShipmentsSumQuery = (keys, account) => {
    let ShipmentsSumFields = [
        "price", "deliveredAmount",
        "deliveryCommission", "allDueFees",
        "customerDue", "amount", "totalAmount",
        "collectedFees", "returningDueFees",
        "collectedAmount", "customerDueCredit",
        "customerDueDebit", "pendingCollectionAmount"
    ]

    let queryFields = []
    let selected = []

    keys.map((e) => {
        if (account && e === "deliveryCommission") {
            return;}
            
        const found = ShipmentsSumFields.find((i) => i === e)
        if (found) {
            queryFields.push(found)
        } else if (e === "netCollection") {
            queryFields.push("pendingCollectionAmount", "deliveryCommission")
            selected.push("netCollection")
        }
        !["code", "status", "netCollection"].includes(e) && shipmentFieldsFun("", e) && selected.push(found)
        return ""
    })

    return {
        queryFields: queryFields,
        selected: selected
    }
}