import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Icon
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect } from "react";

import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useTranslation } from "react-i18next";
import NotFound from "../../Error/NotFound";
import FullScreenLoading from "../HOC/FunctionComponents/LoadingPages/FullScreenLoading";

import * as gqlb from "gql-query-builder";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import "../ManifestPrint/print.css";
import { dateFormatLL } from "../../helpers/dateFunctions";
import LogoImg from "../../Layout/LogoImg";
import { RootPrintCollectionList } from "../Collection/CollectionStyle";

const PREFIX = "PaymentPrint";

const classes = {
  headerCells: `${PREFIX}-headerCells`,
  title: `${PREFIX}-title`,
  notes: `${PREFIX}-notes`,
  noteCell: `${PREFIX}-noteCell`,
  barcodeFont: `${PREFIX}-barcodeFont`,
  cairoFont: `${PREFIX}-cairoFont`,
  barcode: `${PREFIX}-barcode`,
  headerTableCell: `${PREFIX}-headerTableCell`,
  mainCellBorder: `${PREFIX}-mainCellBorder`,
  signature: `${PREFIX}-signature`,
  reportHeader: `${PREFIX}-reportHeader`,
  hiddenFooter: `${PREFIX}-hiddenFooter`,
  pageFooter: `${PREFIX}-pageFooter`,
  logoSection: `${PREFIX}-logoSection`,
  headerDate: `${PREFIX}-headerDate`,
  whiteSpace: `${PREFIX}-whiteSpace`,
  approve: `${PREFIX}-approve`,
  rowWithoutBorder: `${PREFIX}-rowWithoutBorder`,
  mainDetailsCell: `${PREFIX}-mainDetailsCell`,
  tableHeadCell: `${PREFIX}-tableHeadCell`,
  tableWidth: `${PREFIX}-tableWidth`,
  shipmentsTable: `${PREFIX}-shipmentsTable`,
  signatureBlock: `${PREFIX}-signatureBlock`,
  iconColor: `${PREFIX}-iconColor`,
};

const Root = styled("div")(({ theme }) => ({
  fontFamily: ['"Cairo"', "sans-serif"].join(","),
  [`& .${classes.headerCells}`]: {
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.title}`]: {
    color: theme.palette.info.main,
    // whiteS;pace: "break-spaces",
  },

  [`& .${classes.notes}`]: {
    padding: theme.spacing(1),
    border: "1px solid " + theme.palette.action.disabled,
    wordBreak: "break-all",
    width: "100%",
    minHeight: 80,
  },

  [`& .${classes.noteCell}`]: {
    width: "30%",
    overflow: "hidden",
  },

  [`& .${classes.barcodeFont}`]: {
    fontFamily: '"Libre Barcode 39 Text"',
  },

  [`& .${classes.cairoFont}`]: {
    fontFamily: ['"Cairo"', "sans-serif"].join(","),
  },

  [`& .${classes.barcode}`]: {
    fontSize: 40,
    textAlign: "center",
  },

  [`& .${classes.headerTableCell}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.mainCellBorder}`]: {
    border: "none",
    padding: 0,
  },

  [`& .${classes.signature}`]: {
    textAlign: "center",
    padding: theme.spacing(2, 1, 1, 1),
    border: "none",
  },

  [`& .${classes.reportHeader}`]: {
    whiteSpace: "pre-line",
    padding: theme.spacing(0, 2),
    lineHeight: "120%",
    fontSize: 12,
  },

  [`& .${classes.hiddenFooter}`]: {
    visibility: "hidden",
  },

  [`& .${classes.pageFooter}`]: {
    //   "&::after": {
    //    " content:  counter(page)  of  counter(pages)"
    //   },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: "1px solid " + theme.palette.text.secondary,
    display: "flex",
    alignItems: "flex-end",
  },

  [`& .${classes.logoSection}`]: {
    display: "flex",
    alignItems: "center",
  },

  [`& .${classes.headerDate}`]: {
    textAlign: "end",
  },

  [`& .${classes.whiteSpace}`]: {
    "& tr": {
      "& td": {
        whiteSpace: "initial",
      },
    },
  },

  [`& .${classes.approve}`]: {
    textAlign: "end",
  },

  [`& .${classes.rowWithoutBorder}`]: {
    "& td": {
      border: "none",
      padding: 0,
    },
  },

  [`& .${classes.mainDetailsCell}`]: {
    "& td": { border: "none", verticalAlign: "top" },
    "& td:first-of-type": { paddingLeft: 0 },
    "& td:last-of-type": { paddingRight: 0 },

    border: "none",
    padding: theme.spacing(1, 0),
  },

  [`& .${classes.tableHeadCell}`]: {
    padding: 0,
  },

  [`& .${classes.tableWidth}`]: {
    width: "100vw",
  },

  [`& .${classes.shipmentsTable}`]: {
    "& tr": {
      "&:hover": {
        backgroundColor: "#ffffff00 !important",
      },
    },
    "& td , & th": {
      padding: theme.spacing(1),
    },
  },

  [`& .${classes.signatureBlock}`]: {
    breakInside: "avoid",
  },
  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

}));

export { };

const org = {
  operation: "organization",
  fields: [
    "name",
    "phone",
    "email",
    "website",
    "reportHeader",
    "reportFooter",
    "registrationNumber",
    "taxNumber",
  ],
  variables: {},
};

const settings = {
  operation: "shippingSettings",
  fields: [
    "allowedCustomerTypes"
  ],
  variables: {},
};

const paymentsSum = {
  operation: "sumPayments",
  fields: ["paymentAmount", "dueFees", "shipmentCount", "pickupCount", "conciergeRequestCount"],
  variables: {
    input: {
      type: "ListPaymentFilterInput",
      required: true,
    },
  },
};
const paymentsListFields = [
  {
    data: [
      "code",
      "id",
      "date",
      {
        deliveryAgent: ["id", "name", "code"],
      },
      {
        safe: ["id", "name"],
      },
      {
        customer: ["id", "name", "code"],
      },
      "approved",
      "glApproved",
      {
        operation: `sumEntries`,
        fields: [
          "dueFees",
          "paymentAmount",
        ],
        variables: {},
      },
      {
        operation: `entriesShipment:entries(typeCode: SHIPMENT)`,
        fields: [
          {
            paginatorInfo: ["total"]
          }
        ],
        variables: {},
      },
      {
        operation: `entriesConcierge:entries(typeCode: CONCIERGE_REQUEST)`,
        fields: [
          {
            paginatorInfo: ["total"]
          }
        ],
        variables: {},
      },
      {
        operation: `entriesPickup:entries(typeCode: PICKUP)`,
        fields: [
          {
            paginatorInfo: ["total"]
          }
        ],
        variables: {},
      },
    ],
  },
];

const PAGE_COUNT = gqlb.query({
  operation: "listPayments",
  fields: [{ paginatorInfo: ["lastPage"] }],
  variables: {
    input: {
      type: "ListPaymentFilterInput",
      required: true,
    },
    first: {
      type: "Int",
    },
  },
});

const PaymentPrint = (props) => {
  const urlQuery = urlParameters(window.location.search);
  const isUser = props?.match?.params?.user;
  const stringValues = ["code", "fromCode", "toCode"];
  stringValues.forEach((i) => {
    if (urlQuery[i]) urlQuery[i] = String(urlQuery[i]);
  });

  const { data: pageCount, loading: pageLoad } = useQuery(
    gql`
      ${PAGE_COUNT.query}
    `,
    {
      first: 100,
      variables: {
        input: urlQuery,
        first: 100,
      },
    }
  );
  const pages = pageCount?.listPayments?.paginatorInfo?.lastPage;

  const queryBuilder = () => {
    const queryNumber = Math.ceil(pages / 100);
    const queryArray = [org, paymentsSum, settings];
    for (let index = 0; index < queryNumber; index++) {
      const initialQuery = {
        operation: `listPayments${index}:listPayments`,
        fields: paymentsListFields,
        variables: {
          input: {
            type: "ListPaymentFilterInput",
            required: true,
          },
          first: {
            type: "Int",
          },
          ["page" + index]: {
            type: "Int",
            name: "page",
          },
        },
      };
      queryArray.push(initialQuery);
    }
    return queryArray;
  };

  const queryBody = queryBuilder();
  const PAYMENTS_PRINT = gqlb.query(queryBody);

  const { t } = useTranslation();

  let variables = {
    input: urlQuery,
    first: 100,
  };
  for (let index = 0; index < pages; index++) {
    variables["page" + index] = index + 1;
  }

  const { data: queryData, loading } = useQuery(
    gql`
      ${PAYMENTS_PRINT.query}
    `,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      skip: !pages,
      variables,
      onError: () => {
        return;
      },
    }
  );

  let parsedData = [];
  if (queryData?.listPayments0) {
    let concatData = [];
    for (const key in queryData) {
      if (key.startsWith("list")) {
        const listData = queryData[key].data;
        concatData = concatData.concat(listData);
      }
    }
    parsedData = concatData;
  }

  useEffect(() => {
    if (parsedData.length > 0) {
      window.print();
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const type = urlQuery?.typeCode;
  const paymentTitles = {
    DLVBY: t("deliveryAgentPayment"),
    CUSTM: t("customerPayment"),
  };

  const sumPayments =
    queryData?.sumPayments !== null ? queryData?.sumPayments : null;
  const shippingSettings =
    queryData?.shippingSettings !== null ? queryData?.shippingSettings : null;
  const reportDetails = parsedData.length > 0;

  const ALLOWED_SHIPMENT = shippingSettings?.allowedCustomerTypes?.includes("MERCHANT") || shippingSettings?.allowedCustomerTypes?.includes("INDIVIDUAL")
  const ALLOWED_CONCIERGE = shippingSettings?.allowedCustomerTypes?.includes("CONCIERGE")
  const header = !loading && !pageLoad && reportDetails && (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell padding="none">
            <Table>
              <TableBody>
                <TableRow className={classes.rowWithoutBorder}>
                  <TableCell>
                    <Box className={classes.logoSection}>
                      <LogoImg height={30} forceLight={true} />
                      <Typography
                        className={clsx(classes.reportHeader, "landscape-zoom")}
                      >
                        {queryData.organization.reportHeader}
                      </Typography>
                    </Box>
                  </TableCell>

                  <TableCell className={clsx(classes.headerDate)}>
                    <Typography variant="h6">
                      {moment(new Date()).format("ll")}
                    </Typography>
                    <TypographyKeyValue
                      title={t("registrationNumber")}
                      value={queryData.organization?.registrationNumber}
                      hidden={!queryData.organization?.registrationNumber}
                    />
                    <TypographyKeyValue
                      title={t("taxNumber")}
                      value={queryData.organization?.taxNumber}
                      hidden={!queryData.organization?.taxNumber}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.headerTableCell}>
                    <Table className={classes.whiteSpace}>
                      <TableBody>
                        <TableRow className={classes.rowWithoutBorder}>
                          <TableCell>
                            <Typography variant="h5">
                              {paymentTitles[type]}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <TypographyKeyValue
                              title={t("fromDate")}
                              value={urlQuery?.fromDate && moment(urlQuery?.fromDate).format("ll")}
                            />
                            <TypographyKeyValue
                              title={t("toDate")}
                              value={urlQuery?.toDate && moment(urlQuery?.toDate).format("ll")}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
  const table = !loading && !pageLoad && reportDetails !== null && (
    <Table id="manifestPrintTable">
      <EnhancedTableHead type={type} isUser={isUser} ALLOWED_SHIPMENT={ALLOWED_SHIPMENT} ALLOWED_CONCIERGE={ALLOWED_CONCIERGE} />

      <TableBody>
        {parsedData.length > 0 &&
          parsedData?.map((row, index) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                <FixedTableCell component="th" scope="row">
                  {row.code}
                </FixedTableCell>
                <FixedTableCell>{dateFormatLL(row?.date)}</FixedTableCell>
                {row?.deliveryAgent || row?.customer ? (
                  <FixedTableCell>
                    {type === "DLVBY"
                      ? row?.deliveryAgent?.name
                      : row?.customer?.name}
                  </FixedTableCell>
                ) : (
                  <FixedTableCell> </FixedTableCell>
                )}

                <FixedTableCell>{row?.safe?.name}</FixedTableCell>

                {type === "DLVBY" && ALLOWED_SHIPMENT && (
                  <FixedTableCell>{row?.entriesPickup?.paginatorInfo?.total}</FixedTableCell>
                )}
                {type === "DLVBY" && ALLOWED_SHIPMENT && (
                  <FixedTableCell>{row?.entriesShipment?.paginatorInfo?.total}</FixedTableCell>
                )}
                {type === "DLVBY" && ALLOWED_CONCIERGE && (
                  <FixedTableCell>{row?.entriesConcierge?.paginatorInfo?.total}</FixedTableCell>
                )}
                {type !== "DLVBY" && (
                  <FixedTableCell>{row?.sumEntries?.dueFees}</FixedTableCell>
                )}
                <FixedTableCell>{row?.sumEntries?.paymentAmount}</FixedTableCell>
                <FixedTableCell>
                  {row?.approved ? (
                    <Icon className={classes.iconColor}>
                      check_circle_outline
                    </Icon>
                  ) : (
                    <Icon color="error">highlight_off</Icon>
                  )}
                </FixedTableCell>
                {!isUser && (
                  <FixedTableCell>
                    {row?.glApproved ? (
                      <Icon className={classes.iconColor}>
                        check_circle_outline
                      </Icon>
                    ) : (
                      <Icon color="error">highlight_off</Icon>
                    )}
                  </FixedTableCell>
                )}

              </TableRow>
            );
          })}
        <TableRow>
          <FixedTableCell colSpan={3} />
          <FixedTableCell variant="head">{t("total")}</FixedTableCell>
          {type !== "DLVBY" && (
            <FixedTableCell>{sumPayments?.dueFees}</FixedTableCell>
          )}
          {type === "DLVBY" && ALLOWED_SHIPMENT && (
            <FixedTableCell variant="head">
              {sumPayments?.shipmentCount}
            </FixedTableCell>
          )}
          {type === "DLVBY" && ALLOWED_SHIPMENT && (
            <FixedTableCell variant="head">
              {sumPayments?.pickupCount}
            </FixedTableCell>
          )}
          {type === "DLVBY" && ALLOWED_CONCIERGE && (
            <FixedTableCell variant="head">
              {sumPayments?.conciergeRequestCount}
            </FixedTableCell>
          )}
          <FixedTableCell variant="head">
            {sumPayments?.paymentAmount}
          </FixedTableCell>
          <FixedTableCell colSpan={3}> </FixedTableCell>
        </TableRow>
      </TableBody>
    </Table>
  );

  return (
    <RootPrintCollectionList id="maniContainer">
      {loading || pageLoad ? (
        // {true ? (
        <>
          <FullScreenLoading minHeight="20%" />

        </>
      ) : "reportDetails" === null ? (
        <NotFound />
      ) : (
        <Root>
          <div id="printManifestContainer">

            <div>
              <div className="page-header">{header}</div>

              <div className="page-footer">
                {queryData.organization.reportFooter}
              </div>

              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <td>
                      <div className="page-header-space">{header}</div>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <div
                        className={clsx(classes.shipmentsTable, {
                          // [classes.tableWidth]: tableZoom,
                        })}
                      >
                        {table}
                      </div>
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td>
                      <div className="page-footer-space">
                        {queryData.organization.reportFooter}
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </Root>
      )}
    </RootPrintCollectionList>

  );

  function TypographyKeyValue(props) {
    const { title, value, hidden, ...restProps } = props;
    return hidden ? null : (
      <Typography variant="body1" paddingBottom={1} {...restProps}>
        <span className={classes.title}>{title}:</span> <span>{value}</span>
      </Typography>
    );
  }
};
function EnhancedTableHead(props) {
  //*********Table Function*********
  const { type, isUser, ALLOWED_CONCIERGE, ALLOWED_SHIPMENT } = props;
  const { t } = useTranslation();
  const headCells = [
    { numeric: true, disablePadding: false, label: t("recordCode") },
    { numeric: true, disablePadding: false, label: t("paymentDate") },
    {
      numeric: true,
      disablePadding: false,
      label: t(type === "DLVBY" ? "shippingAgent" : "customer"),
    },
    { numeric: true, disablePadding: false, label: t("safe") },
    { numeric: true, disablePadding: false, label: t("value") },
    { numeric: true, disablePadding: false, label: t("approved") }
  ];
  if (type === "CUSTM") {
    const referenceBranch = {
      id: "5",
      numeric: true,
      disablePadding: false,
      label: t("returningDueFees"),
    };
    headCells.splice(4, 0, referenceBranch);
  }
  if (type === "DLVBY") {
    const shipmentsNumber = {
      id: "9",
      numeric: true,
      disablePadding: false,
      label: t("shipments"),
    };
    ALLOWED_SHIPMENT && headCells.splice(4, 0, shipmentsNumber);
    const pickups = {
      id: "10",
      numeric: true,
      disablePadding: false,
      label: t("pickups"),
    };
    ALLOWED_SHIPMENT && headCells.splice(5, 0, pickups);
    const conciergeRequest = {
      id: "11",
      numeric: true,
      disablePadding: false,
      label: t("conciergeRequestsNumber"),
    };
    const palace = ALLOWED_SHIPMENT ? 6 : 4
    ALLOWED_CONCIERGE && headCells.splice(palace, 0, conciergeRequest);
  }
  if (!isUser) {
    const shipmentsNumber = {
      id: "8",
      numeric: true,
      disablePadding: false,
      label: t("glApproved"),
    };
    headCells.splice(9, 0, shipmentsNumber);
  }
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          return (
            <FixedTableCell
              key={index}
              padding={headCell.disablePadding ? "none" : "normal"}
            >
              {headCell.label}
            </FixedTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default PaymentPrint;
