import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  TableBody,
  TableRow,
} from "@mui/material";
import { FilterList, Print } from "@mui/icons-material";
import { SHIPMENTS_BY_ZONE_REPORT } from "./Graphql";
import { useTranslation } from "react-i18next";
import MUITablePagination from "../HOC/MUI/TablePagination/MUITablePagination";
import { FixedTableCell } from "../HOC/CustomComponents/FixedTableCell";
import { styled } from "@mui/material/styles";
import { urlParameters } from "../HOC/CustomFunctions/urlParameters";
import useWidth from "../../Hooks/useWidth";
import { useHistory } from "react-router";
import { windowUrl } from "../HOC/CustomFunctions/pushUrl";
import ListWrapper from "../CustomComponents/ListWrapper/ListWrapper";
import ShipmentRegion from "../HOC/CustomComponents/ShipmentRegion";
import FiltersForm from "../../Hooks/shipmentListFilters";
import formatMoney from "../../helpers/numbersDot";
import config from "../../config.json";

const PREFIX = "ReportsByZone";

const classes = {
  span: `${PREFIX}-span`,
  iconColor: `${PREFIX}-iconColor`,
  headerTable: `${PREFIX}-headerTable`,
  button: `${PREFIX}-button`,
  filterForm: `${PREFIX}-filterForm`,
  filterField: `${PREFIX}-filterField`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.span}`]: {
    display: "inline"
  },

  [`& .${classes.iconColor}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.headerTable}`]: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.button}`]: {
    position: "sticky",
    bottom: 0,
    zIndex: 3,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10,
    padding: 7,
    width: "100%"
  },

  [`& .${classes.filterForm}`]: {
    overflowY: "auto",
    width: "100%",
    margin: 0,
    position: "relative",
    height: "100vh",
    alignContent: "space-between",
  },

  [`& .${classes.filterField}`]: {
    padding: theme.spacing(1),
    width: "100%",
    margin: 0,
    flexDirection: "column"
  },
}));

const TotalsPerZones = (props) => {
  const { t } = useTranslation();
  const urlQuery = urlParameters(window.location.search);
  const screenWidth = useWidth();
  const filterAnchor = screenWidth === "xs" ? "bottom" : "left";

  const [drawerState, setDrawerState] = React.useState({
    top: true,
    left: screenWidth === "xs" ? false : true,
    bottom: screenWidth === "xs" ? false : true,
    right: screenWidth === "xs" ? false : true,
  });

  const [rowsPerPage, setRowsPerPage] = useState(urlQuery["rowsPerPage"] ?? config.app.pageSize);
  const [page, setPage] = useState(urlQuery["page"] ?? 0);
  const [queryVariables, setQueryVariables] = useState({
    page: -1
  });
  const resetPage = () => setPage(0);

  const { refetch, page: pageNum, ...restVariables } = queryVariables;

  const history = useHistory();

  const searchParams = (param) => {
    const queryParams = [];
    for (const i in param) {
      encodeURIComponent(param[i]) &&
        queryParams.push(
          encodeURIComponent(i) + "=" + encodeURIComponent(param[i])
        );
    }
    const queryString = queryParams.join("&");
    return queryString;
  };

  const pushUrlSearch = (param) => {
    const params = searchParams(param);

    const url = history.createHref({
      pathname: `/admin/reports/totals-per-zones`,
      search: "?" + params,
    });
    windowUrl(url);
  };


  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      (event.type === "keydown" || event.type === "submit") &&
      (event.type === "submit" || event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState({ ...drawerState, [anchor]: open });
  };

  const onSubmitFunc = () => {
    filterAnchor === "bottom" &&
      setDrawerState({ ...drawerState, [filterAnchor]: false });
  };

  //////////Table Function////////////
  const handleChangePage = (event, newPage) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: newPage,
      rowsPerPage: rowsPerPage,
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    pushUrlSearch({
      ...urlQuery,
      // ...dateValue,
      page: 0,
      rowsPerPage: +event.target.value,
    });

    setRowsPerPage(+event.target.value);
    resetPage();
  };


  for (const key in queryVariables) {
    if (queryVariables[key] === "" || queryVariables[key] === null) {
      delete queryVariables[key];
    }
  }


  const { data, loading } = useQuery(
    gql`
      ${SHIPMENTS_BY_ZONE_REPORT.query}
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      partialRefetch: refetch,
      skip: pageNum === -1,
      variables: {
        first: rowsPerPage,
        page: page + 1,
        input: {
          ...restVariables
        },
      },
    }
  );

  const shipmentsByZoneReport = data?.shipmentsByZoneReport?.records?.data;


  const onPrint = () => {
    const params = searchParams(restVariables);
    const domain = `${window.location.origin}`;

    window.open(`${domain}/reports/totals-per-zones/print?${params}`);
  };

  const icons = [
    {
      id: "filterList",
      title: "search",
      action: toggleDrawer(filterAnchor, !drawerState[filterAnchor]),
      icon: FilterList,
    },
    {
      id: "print",
      title: "print",
      action: onPrint,
      icon: Print,
      disabled: shipmentsByZoneReport?.length === 0,
    },
  ]

  return (
    <Root>
      <ListWrapper
        drawerState={drawerState[filterAnchor]}
        icons={icons}
        path={props.match.path}
        empty={shipmentsByZoneReport?.length === 0}
        loading={loading}
        filters={
          <FiltersForm
            {...{ onSubmitFunc, resetPage, rowsPerPage, loading: loading, filterLoad: loading || pageNum === -1 }}
            queryVariables={(variables) => setQueryVariables(variables)}
            // removeResetDate={true}
            // dateValue={(date) => setDateValue(date)}
            // initData={FILTER_INIT_DATE}
            pathname="reports/totals-per-zones"
            filtersAllowed={[
              "date",
              "dlvAtemp",
              "status",
              "packageType",
              "new",
              "branch",
              "originBranch",
              "customerType",
              "custm",
              "dlvAgent",
              "createdBy",
              "zoneSender",
              "subZoneSender",
              "zone",
              "subZone",
              "refNumber",
              "service",
              "deliveryType",
              "returnType",
              "paymentType",
              "collection",
              "paid",
              "cancelled"
            ]}
          />
        }
        tableHeaders={
          [
            {
              name: "zone"
            },
            {
              name: "shipments"
            },
            {
              name: "fees"
            },
          ]
        }
        tableBody={
          <TableBody>
            {shipmentsByZoneReport &&
              shipmentsByZoneReport?.map((row, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                  >
                    <ShipmentRegion
                      pathname={`/admin/zones/${row?.zoneId}`}
                      zone={row?.zoneName}
                    />
                    <FixedTableCell>
                      {row.shipmentsCount}
                    </FixedTableCell>
                    <FixedTableCell>
                      {formatMoney(row.fees)}
                    </FixedTableCell>
                  </TableRow>
                );
              })}
            <TableRow>
              <FixedTableCell variant="head">{t("total")}</FixedTableCell>
              <FixedTableCell>{data?.shipmentsByZoneReport?.sum?.shipmentsCount}</FixedTableCell>
              <FixedTableCell>{formatMoney(data?.shipmentsByZoneReport?.sum?.fees)}</FixedTableCell>
            </TableRow>
          </TableBody>
        }
        pagination={
          <MUITablePagination
            count={data?.shipmentsByZoneReport?.records?.paginatorInfo?.total}
            rowsPerPage={rowsPerPage}
            page={!data?.shipmentsByZoneReport ? 0 : page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
      />
    </Root>
  );
};

export default TotalsPerZones;
