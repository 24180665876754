import React from "react";
import { styled } from "@mui/material/styles";
import { Checkbox, Icon, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ShipmentRegion from "../../HOC/CustomComponents/ShipmentRegion";
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell";
import { WarehouseSectionTableCell } from "./WarehouseSectionTableCell";
import CellLink from "../../HOC/CustomComponents/CellLink";
import { TableCellColor } from "../../HOC/CustomComponents/TableCellColor";

const PREFIX = "ShipmentListDetails";

const classes = {
  shipmentTable_checkbox: `${PREFIX}-shipmentTable_checkbox`,
  shipmentTable_bodyStatus: `${PREFIX}-shipmentTable_bodyStatus`,
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  [`& .${classes.shipmentTable_checkbox}`]: {
    zIndex: "100",
    backgroundColor: theme.palette.background.paper,
    position: "sticky",
    top: 0,
    left: 0,
  },
  [`& .${classes.shipmentTable_bodyStatus}`]: {
    [theme.breakpoints.up("sm")]: {
      zIndex: "100",
      backgroundColor: theme.palette.background.paper,
      position: "sticky",
      top: "0",
      right: "0",
    },
  },
}));
const StyledIcon = styled(Icon)(({ theme }) => ({
  color: theme.palette.success.main,
}))
export const ManifestShipmentsCells = ({ shipmentDetails, type }) => {
  const cellsByType = {
    PKM: (
      <>
        <TableCellColor
          className={classes.shipmentTable_shipmentStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone?.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.notes}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    RTS: (
      <>
        <CellLink
          component="th"
          scope="shipment"
          pathname={`/admin/warehouse/${shipmentDetails?.warehouseSection?.id}`}
        >
          {shipmentDetails?.warehouseSection?.name}
        </CellLink>
        <TableCellColor
          className={classes.shipmentTable_shipmentStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    RITS: (
      <>
        <WarehouseSectionTableCell shipmentDetails={shipmentDetails} />
        <TableCellColor
          className={classes.shipmentTable_bodyStatus}
          shipment={shipmentDetails} />

        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <FixedTableCell>
          {shipmentDetails?.hasProducts ? (
            <StyledIcon >
              check_circle_outline
            </StyledIcon>
          ) : (
            <Icon color="error">highlight_off</Icon>
          )}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />

        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    RCV: (
      <>
        <WarehouseSectionTableCell shipmentDetails={shipmentDetails} type={type} />
        <TableCellColor
          className={classes.shipmentTable_shipmentStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    PRP: (
      <>
        <TableCellColor
          className={classes.shipmentTable_bodyStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <FixedTableCell>
          {shipmentDetails?.hasProducts ? (
            <StyledIcon >
              check_circle_outline
            </StyledIcon>
          ) : (
            <Icon color="error">highlight_off</Icon>
          )}
        </FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />

        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    OTD: (
      <>
        <TableCellColor
          className={classes.shipmentTable_shipmentStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientPhone}</FixedTableCell>
        <FixedTableCell dir="ltr"> {shipmentDetails?.recipientMobile}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    STD: (
      <>
        <TableCellColor
          className={classes.shipmentTable_bodyStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientPhone}</FixedTableCell>
        <FixedTableCell dir="ltr"> {shipmentDetails?.recipientMobile}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    OTR: (
      <>
        <TableCellColor
          className={classes.shipmentTable_shipmentStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <CellLink
          pathname={`/admin/warehouse/${shipmentDetails?.warehouseSection?.id}`}
        >
          {shipmentDetails?.warehouseSection?.name}
        </CellLink>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    BMT: (
      <>
        <TableCellColor
          className={classes.shipmentTable_shipmentStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    BMR: (
      <>
        <WarehouseSectionTableCell shipmentDetails={shipmentDetails} />
        <TableCellColor
          className={classes.shipmentTable_shipmentStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    HTR: (
      <>
        <WarehouseSectionTableCell shipmentDetails={shipmentDetails} />
        <TableCellColor
          className={classes.shipmentTable_bodyStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
    RTRN: (
      <>
        <TableCellColor
          className={classes.shipmentTable_bodyStatus}
          shipment={shipmentDetails} />
        <FixedTableCell>{shipmentDetails?.type?.name}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.customer?.name}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.senderZone?.id}`}
          zone={shipmentDetails?.senderZone.name}
          subzone={shipmentDetails?.senderSubzone?.name}
        />
        <FixedTableCell dir="ltr">
          {shipmentDetails?.senderMobile ?? shipmentDetails?.senderPhone}
        </FixedTableCell>
        <FixedTableCell>{shipmentDetails?.recipientName}</FixedTableCell>
        <ShipmentRegion
          pathname={`/admin/zones/${shipmentDetails?.recipientZone?.id}`}
          zone={shipmentDetails?.recipientZone.name}
          subzone={shipmentDetails?.recipientSubzone?.name}
        />
        <FixedTableCell>{shipmentDetails?.recipientAddress}</FixedTableCell>
        <FixedTableCell>{shipmentDetails?.description}</FixedTableCell>
      </>
    ),
  };

  return cellsByType[type];
};

export function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount, type } = props;

  const { t } = useTranslation();
  const headCells = {
    PKM: [
      t("requestCode"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("notes"),
      t("description"),
    ],
    RTS: [
      t("requestCode"),
      t("wareHouse"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
    RITS: [
      t("requestCode"),
      t("wareHouse"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("hasProducts"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
    RCV: [
      t("requestCode"),
      t("wareHouse"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
    PRP: [
      t("requestCode"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("hasProducts"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
    OTD: [
      t("requestCode"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("recipientPhone"),
      t("recipientMobile"),
      t("description"),
    ],
    STD: [
      t("requestCode"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("recipientPhone"),
      t("recipientMobile"),
      t("description"),
    ],
    OTR: [
      t("requestCode"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("wareHouse"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
    BMT: [
      t("requestCode"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
    BMR: [
      t("requestCode"),
      t("wareHouse"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
    HTR: [
      t("requestCode"),
      t("wareHouse"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
    RTRN: [
      t("requestCode"),
      t("shipmentStatus"),
      t("shipmentType"),
      t("customer"),
      t("source"),
      t("mobile"),
      t("recipient"),
      t("destination"),
      t("recipientAddress"),
      t("description"),
    ],
  };

  return (
    <StyledTableHead>
      <TableRow>
        <FixedTableCell
          className={classes.shipmentTable_checkbox}
          padding="checkbox"
        >
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </FixedTableCell>
        {headCells[type].map((headCell, index) => (
          <FixedTableCell
            key={index}
          // align={headCell.numeric ? 'right' : 'left'}
          >
            {headCell}
          </FixedTableCell>
        ))}
      </TableRow>
    </StyledTableHead>
  );
}

export const filtersFields = {
  PKM: [
    "date",
    "zone",
    "subZone",
    "refNumber",
    "service",
    "packageType",
    "paymentType",
  ],
  RITS: ["date", "status", "customerType", "custm", "dlvAgent", "zone", "subZone", "refNumber", "service", "packageType", "paymentType"],
  RCV: ["date", "status", "customerType", "custm", "dlvAgent", "zone", "subZone", "refNumber", "service", "packageType", "paymentType"],
  PRP: ["date", "status", "customerType", "custm", "dlvAgent", "zone", "subZone", "refNumber", "service", "packageType", "paymentType"],
  OTD: ["date", "dlvryBefore", "status", "branch", "originBranch", "customerType", "custm", "zone", "subZone", "refNumber", "service", "packageType", "paymentType"],
  STD: ["date", "dlvryBefore", "status", "branch", "originBranch", "customerType", "custm", "zone", "subZone", "refNumber", "service", "packageType", "paymentType"],
  HTR: ["date", "status", "branch", "originBranch", "customerType", "custm", "zone", "subZone", "refNumber", "service", "packageType", "paymentType"],
  RTS: ["date", "status", "branch", "originBranch", "customerType", "custm", "zone", "subZone", "refNumber", "service", "packageType", "paymentType"],
  OTR: ["date", "branch", "originBranch", "zone", "subZone", "refNumber", "service", "returnType", "packageType", "paymentType"],
  RTRN: [
    "date",
    "status",
    "branch",
    "originBranch",
    "zone",
    "subZone",
    "refNumber",
    "service",
    "deliveryType",
    "returnType",
    "packageType",
    "paymentType",
  ],
  BMT: [
    "date",
    "status",
    "branch",
    "originBranch",
    "customerType",
    "custm",
    "zone",
    "subZone",
    "refNumber",
    "service",
    "deliveryType",
    "returnType",
    "packageType",
    "paymentType",
  ],
  BMR: [
    "date",
    "branch",
    "originBranch",
    "customerType",
    "custm",
    "zone",
    "subZone",
    "refNumber",
    "service",
    "deliveryType",
    "returnType",
    "packageType",
    "paymentType",
  ],
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};
