import { gql, useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

import * as gqlb from "gql-query-builder";
import { Fragment, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import CustomDialog from "../HOC/CustomComponents/CustomDialog";
import ButtonLoading from "../HOC/FunctionComponents/LoadingPages/ButtonLoading";
import { useSnackbar } from "notistack";
export const EXPOERT_VOUCHERS = gqlb.mutation({
  operation: "exportVouchers",
  fields: [],
  variables: {
    input: {
      type: "ExportVouchersInput",
      required: true,
    },
  },
});

export default function ExportVoucher(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { filters, openExport, setOpenExport } = props;

  const [VouchersFieldsState, setVouchersFieldsState] = useState([]);
  const { t } = useTranslation();

  const vouchersFields = [
    { name: "CODE", label: t("code") },
    { name: "DATE", label: t("date") },
    { name: "TYPE", label: t("type") },
    { name: "PAYEE_TYPE", label: t("payeeType") },
    { name: "GL_ACCOUNT", label: t("payee") },
    { name: "SAFE", label: t("safe") },
    {
      name: "BRANCH",
      label: t("branch"),
    },
    {
      name: "NOTES",
      label: t("notes"),
    },
    {
      name: "AMOUNT",
      label: t("amount"),
    },
    {
      name: "APPROVED",
      label: t("approved"),
    },
    {
      name: "GL_APPROVED",
      label: t("glApproved"),
    },
  ];

  useEffect(() => {
    if (!vouchersFields[0].id)
      vouchersFields.forEach((ele, index) => {
        ele["id"] = index + 1;
        ele["checked"] = true;
      });

    const localStorageExportVouchers = localStorage.getItem("export-vouchers");
    const parsedExportVoucher = localStorageExportVouchers
      ? JSON.parse(localStorageExportVouchers)
      : null;

    const notCheckedFields = vouchersFields.filter((i) => {
      if (
        !parsedExportVoucher?.includes(i.name) &&
        parsedExportVoucher !== null
      ) {
        i.checked = false;
        return true;
      }
      return false;
    });

    const checkedFields = vouchersFields.filter((i) => {
      if (parsedExportVoucher?.includes(i.name)) {
        i.checked = true;
        return true;
      }
      return false;
    });

    const sortedFields = checkedFields.sort((a, b) => {
      return parsedExportVoucher?.indexOf(a.name) >
        parsedExportVoucher?.indexOf(b.name)
        ? 1
        : -1;
    });

    setVouchersFieldsState(
      localStorageExportVouchers
        ? [...sortedFields, ...notCheckedFields]
        : vouchersFields
    );
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  // const [dialogState, setDialogState] = useState(false);

  const fieldCount = VouchersFieldsState.length;
  const fieldSelected = VouchersFieldsState.filter((i) => i.checked).length;
  const onSelectAllClick = (e) => {
    const fileds = [...VouchersFieldsState];
    fileds.forEach((i) => {
      if (e.target.checked) {
        i.checked = true;
      } else {
        i.checked = false;
      }
    });
    setVouchersFieldsState(fileds);
  };

  const onCloseDialog = () => {
    setOpenExport(false);
  };

  // const onOpenDialog = () => {
  //   setDialogState(true);
  // };

  // let getBackendUri = (imgPath) => {
  //   const domain = config.backend.domain
  //     ? config.backend.domain
  //     : window.location.hostname;

  //   return `${config.backend.protocol}://${domain}:${config.backend.port}/${imgPath}`;
  // };
  const [ExportVouchers, { loading }] = useMutation(
    gql`
      ${EXPOERT_VOUCHERS.query}
    `,
    {
      onCompleted: (data) => {
        onCloseDialog();
        if (data?.exportVouchers) {
          enqueueSnackbar(t("successExport"), {
            variant: "success",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        } else {
          enqueueSnackbar(t("errorExport"), {
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            TransitionComponent: Collapse,
          });
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(VouchersFieldsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    const isNotChecked = items[result.destination.index - 1]?.checked;
    if (isNotChecked === false) return;
    items.splice(result.destination.index, 0, reorderedItem);

    setVouchersFieldsState(items);
  }

  const handelExportVoucher = () => {
    const updatedFields = VouchersFieldsState.reduce((previous, current) => {
      if (current.checked) {
        previous.push(current.name);
      }
      return previous;
    }, []);
    localStorage.setItem("export-vouchers", JSON.stringify(updatedFields));
    const fields = VouchersFieldsState
      .filter((i) => i.checked)
      .map((i) => ({ label: i.label, name: i.name }));
    ExportVouchers({
      variables: {
        input: {
          filters,
          fields,
        },
      },
    });
  };

  return (
    <>
      {/* <Tooltip title={t("exportExcel")}>
        <Box component="span">
          <IconButton
            disabled={disabled}
            onClick={() => onOpenDialog()}
          >
            <Icon>sim_card_download</Icon>
          </IconButton>
        </Box>
      </Tooltip> */}
      <CustomDialog
        fullWidth
        maxWidth="xs"
        open={openExport}
        onClose={onCloseDialog}
        title={
          <Fragment>
            <Checkbox
              edge="start"
              indeterminate={fieldSelected > 0 && fieldSelected < fieldCount}
              checked={fieldCount > 0 && fieldSelected === fieldCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
            {t("exportExcel")}
          </Fragment>
        }
        actions={
          <>
            <Button color="primary" onClick={onCloseDialog}>
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              disabled={loading || fieldSelected === 0}
              onClick={handelExportVoucher}
            >
              {loading ? <ButtonLoading /> : t("confirm")}
            </Button>
          </>
        }
        content={
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  component="ul"
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {/* <ListItem dense button>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      indeterminate={
                        fieldSelected > 0 && fieldSelected < fieldCount
                      }
                      checked={fieldCount > 0 && fieldSelected === fieldCount}
                      onChange={onSelectAllClick}
                      inputProps={{ "aria-label": "select all desserts" }}
                    />
                  
                  </ListItemIcon>
                  <ListItemText primary={t("selectAll")} />
                </ListItem> */}

                  {VouchersFieldsState.map((value, index) => {
                    const labelId = `checkbox-list-label-${value.id}`;
                    return (
                      <Draggable
                        isDragDisabled={!value.checked}
                        key={value.id}
                        draggableId={value.name}
                        index={index}
                      >
                        {(provided) => (
                          <CustomeItemList
                            innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            itemDetails={value}
                            labelId={labelId}
                            state={VouchersFieldsState}
                            setState={setVouchersFieldsState}
                          />
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.label}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        }
      />
    </>
  );
}

const CustomeItemList = (props) => {
  const { itemDetails, labelId, state, setState, innerRef, ...restProps } =
    props;
  const { label, checked } = itemDetails;
  // const [checkboxState,setCheckboxState] = React.state(checked)
  const handleToggle = (e) => {
    const checked = e.target.checked;
    const update = [...state];
    const checkedItemLength = update.filter((i) => i.checked).length;
    const index = update.findIndex((i) => i.name === itemDetails.name);
    const [sortItem] = update.splice(index, 1);

    sortItem["checked"] = checked;
    update.splice(checkedItemLength, 0, sortItem);

    setState(update);
  };
  return (
    <ListItem {...restProps} ref={innerRef} dense button>
      <ListItemIcon>
        <Checkbox
          edge="start"
          checked={checked}
          tabIndex={-1}
          onChange={handleToggle}
          disableRipple
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={label} />
    </ListItem>
  );
};
