import { Dialog, DialogContent, IconButton, TableBody, useTheme, TableRow, TableHead, Table } from "@mui/material"
import { EnhancedTableToolbarForm } from "./EnhancedTableToolbar"
import TableFixedHeaderWraper from "../../HOC/CustomComponents/TableWithFixedHeader"
import { useState } from "react"
import MUITablePagination from "../../HOC/MUI/TablePagination/MUITablePagination"
import EmptyTableMessage from "../../HOC/FunctionComponents/EmptyTableMessage"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useTranslation } from "react-i18next"
import { FixedTableCell } from "../../HOC/CustomComponents/FixedTableCell"
import {
    Delete,
} from "@mui/icons-material";
import ControlMUItextField from "../../HOC/MUI/ControlMUItextField"
export const ProductTableForm = (props) => {
    const {
        classes,
        productsSelected,
        productIN,
        setChange,
        EmptyTextFields,
        setPickedProduct,
        CRDTShipment,
        productDrawerHandler,
        setChangeDes, unEditable, errors, control
    } = props
    const { t } = useTranslation()
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [page, setPage] = useState(0);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };
    return (
        <>
            <EnhancedTableToolbarForm
                {...props}
                classes={classes}
                title={t("shipmentsNumber")}
                addShipmentProduct={productDrawerHandler}

            />

            <TableFixedHeaderWraper>


                <Table
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                >
                    <TableHead>
                        <TableRow>
                            <FixedTableCell>#</FixedTableCell>
                            <FixedTableCell>{t("name")}</FixedTableCell>
                            <FixedTableCell>
                                {t("availableQuantity")}
                            </FixedTableCell>
                            <FixedTableCell>
                                {t("quantity")}
                            </FixedTableCell>
                            <FixedTableCell>{t("price")}</FixedTableCell>
                            <FixedTableCell>{t("weight")}</FixedTableCell>
                            <FixedTableCell>{t("delete")}</FixedTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productsSelected.map((product, index) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={index}
                                // className={classes.tableBodyRow}
                                >
                                    <FixedTableCell>
                                        {index + 1}
                                    </FixedTableCell>
                                    <FixedTableCell>
                                        {product.product.name}
                                    </FixedTableCell>
                                    <FixedTableCell>
                                        {product.product.availableQuantity}
                                    </FixedTableCell>
                                    <FixedTableCell>
                                        <ControlMUItextField
                                            className={classes.tableField}
                                            control={control}
                                            errors={errors}
                                            disabled={unEditable}
                                            variant="outlined"
                                            type={"number"}
                                            name={productIN ? `shipment.shipmentProducts${index}quantityIN` : `shipment.shipmentProducts${index}quantity`}
                                            label={t("quantity")}
                                            rules={{
                                                required: t("fieldIsRequired"),
                                                min: {
                                                    value: 1,
                                                    message: t("validation:min", {
                                                        field: t("quantity"),
                                                        number: "1",
                                                    }),
                                                },
                                                ...(!productIN && {max: {
                                                    value:
                                                        product.product
                                                            .availableQuantity,
                                                    message: t("validation:max", {
                                                        field: t("quantity"),
                                                        number:
                                                            product.product
                                                                .availableQuantity,
                                                    }),
                                                }}),
                                            }}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setPickedProduct((prev) => {
                                                    prev.products[index].quantity =
                                                        value;
                                                    return prev;
                                                });
                                                setChange((prev) => !prev);
                                                setChangeDes(true);
                                                // let totalQuantity = 0
                                                // let totalPrice = 0
                                                // productsSelected.forEach((product) => {
                                                //   totalPrice += Number(watch(`shipment.price${product.product.id}`)) * Number(watch(`shipment.quantity${product.product.id}`));
                                                //   totalQuantity += Number(watch(`shipment.quantity${product.product.id}`));
                                                // })
                                                // setValue('shipment.piecesCount', totalQuantity)
                                                // setValue('shipment.price', totalPrice)
                                                // setTotalprice(totalPrice)
                                                // setDesciption()
                                            }}
                                        />
                                    </FixedTableCell>


                                    {CRDTShipment ? (
                                        <FixedTableCell>0</FixedTableCell>
                                    ) : (
                                        <FixedTableCell>
                                            <ControlMUItextField
                                                control={control}
                                                errors={errors}
                                                readOnly={productIN}
                                                disabled={unEditable}
                                                variant="outlined"
                                                type={"number"}
                                                name={productIN ? `shipment.shipmentProducts${index}priceIN` : `shipment.shipmentProducts${index}price`}
                                                label={t("price")}
                                                className={classes.tableField}
                                                rules={{
                                                    required: t("fieldIsRequired"),
                                                    min: {
                                                        value: 0,
                                                        message: t("validation:min", {
                                                            field: t("price"),
                                                            number: "0",
                                                        }),
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setPickedProduct((prev) => {
                                                        prev.products[index].price =
                                                            value;
                                                        return prev;
                                                    });
                                                    setChange((prev) => !prev);
                                                    // let totalPrice = 0
                                                    // productsSelected.forEach((product) => {
                                                    //   totalPrice += Number(watch(`shipment.price${product.product.id}`)) * Number(watch(`shipment.quantity${product.product.id}`));
                                                    // })
                                                    // setValue('shipment.price', totalPrice)
                                                    // setTotalprice(totalPrice)
                                                }}
                                            />
                                        </FixedTableCell>
                                    )}
                                    <FixedTableCell>
                                        {product.product.weight}
                                    </FixedTableCell>
                                    <FixedTableCell
                                        component="th"
                                        scope="row"
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                const targetId = product.product.id;
                                                setPickedProduct((current) => {
                                                    const currentProducts =
                                                        current.products;
                                                    currentProducts.length === 1 &&
                                                        EmptyTextFields();
                                                    const filterProducts =
                                                        currentProducts.filter(
                                                            (ele) =>
                                                                ele.product.id !== targetId
                                                        );
                                                    return {
                                                        products: filterProducts,
                                                    };
                                                });
                                                setChange((prev) => !prev);
                                            }}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </FixedTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>


                {/* </Paper> */}
                {/* </Grid>
                    </Can>
                )}

                ) */}
            </TableFixedHeaderWraper>
            {productsSelected.length > 4 && (
                <MUITablePagination
                    classeName={classes.pagination}
                    count={productsSelected.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}

            {/* {(!watch("shipment.id") ) && (
                <Box className={classes.overlay}>
                    <Typography color="textPrimary" variant="body1">
                        {t("saveManifestFirst")}
                    </Typography>
                </Box>
            )} */}
            <Grid container justifyContent="center" alignItems="center">
                {productsSelected.length === 0 && (
                    <EmptyTableMessage
                        message={t("noResult")}
                    />
                )}
            </Grid>
        </>
    )
}



